import React, { Component } from 'react';
import VhhpButtonDonate from '../Elements/VhhpButtonDonate';
import WizardLayout from './WizardLayout';
import './Donation.css';

export default class Donation extends Component {
    
    
    handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<boolean> {
        //the AgendaView commits changes to DB as they happen
        //so no validation/commit to wait on when next is clicked
        return Promise.resolve(true);
    }
    
    componentDidMount()
    {

    }

    render() {
        return (<WizardLayout onNextClick={(e) => this.handleNextButtonClick(e)}
            WizardContentPaneChildren={
                <div className="rwcp-container">
                    <div className="rwcp-body">
                        <div className="rwcp-body__header">Make a Donation</div>
                        <div className="rwcp-body__text">This is a difficult time for hundreds of thousands of New Yorkers. If you can, please consider a gift to Habitat NYC  and Westchester.</div>
                        <div className="donation-button-wrapper">
                        <VhhpButtonDonate styleOptions="standard" text="Donate" donateUrl="https://habitatnycwc.org/donate-now/" />
                        </div>
                    </div>
                </div>
            }></WizardLayout>
        );
    }
}
