import React, { Component } from 'react';
import vhhpUserService, { getUserInitials, VhhpUser } from '../../VhhpUserService';
import Avatar, { AvatarSize } from '../Elements/Avatar';
import getAvatarCollectionForUser, { SelectedAvatar, UserAvatarInfo } from '../Elements/AvatarCollection';
import VhhpButton from '../Elements/VhhpButton';
import "./ProfileAvatar.scss";

import ReactCrop, { Crop, PercentCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import loadImage from "blueimp-load-image";

import AzureFileUploader from "../../components/FileUploader/fileupload"



interface ProfileAvatarProps {
    // useInitialsAvatar: boolean;
    // selectedAvatarUrl: string;
    //size: AvatarSize;
    closeModal?: () => void;
    saveAndCloseModal?: (selectedAvatar: SelectedAvatar) => void;
    firstName: string;
    lastName: string;
}



interface ProfileAvatarState {

    loading: boolean;
    errorMsg: string;
    avatarCollection: JSX.Element[];
    vhhpUserID: string;
    selectedAvatar: SelectedAvatar | undefined;

    fileUploadSrc: string | ArrayBuffer | null;
    croppedImageUrl: string;
    croppedImage: File | undefined;
    crop: Crop;
}

export default class ProfileAvatar extends Component<ProfileAvatarProps, ProfileAvatarState> {

    private imageRef: HTMLImageElement | undefined;
    private fileUrl: string = "";

    constructor(props: ProfileAvatarProps) {
        super(props);
        this.state = {
            loading: true,
            errorMsg: "",
            avatarCollection: [],
            vhhpUserID: "",
            selectedAvatar: undefined,
            fileUploadSrc: null,
            croppedImageUrl: "",
            croppedImage: undefined,
            crop: {
                unit: "%",
                width: 20,
                aspect: 1
            }
        };
        this.handleAvatarClick = this.handleAvatarClick.bind(this);
        this.submitAvatarForm = this.submitAvatarForm.bind(this);
        this.populateState();
    }
    
    async populateState() {
        const vhhpUser = await vhhpUserService.getVhhpUser();
        let selectedAvatar: SelectedAvatar | undefined = undefined;
        if (vhhpUser) {
            //the first time the user reaches here, they will not have a profile record yet unless using social login
            let userInitials = getUserInitials(this.props.firstName, this.props.lastName);

            if (vhhpUser.profile) {
                let avatarUrl = vhhpUser.profile.avatarUrl;
                let useInitials = vhhpUser.profile.useInitialsAvatar;                
                if (useInitials === undefined) {
                    if (avatarUrl) {
                        //we have an avatarUrl, use it
                        useInitials = false;
                    } else if (userInitials) {
                        //no avatarUrl, but we have the user's initials
                        useInitials = true;
                    } else {
                        //no avatarUrl, no initials
                        useInitials = false;
                    }
                }

                selectedAvatar = { isInitialsAvatar: useInitials, avatarUrl: vhhpUser.profile.avatarUrl };

            }
            const avatars = this.getAvatarCollectionForUser(vhhpUser, selectedAvatar);
            this.setState({ loading: false, avatarCollection: avatars, vhhpUserID: vhhpUser.id, selectedAvatar: selectedAvatar });
        } else {
            this.setState({ loading: false, errorMsg: "Unable to load avatar choices" });
        }
    }
    getAvatarCollectionForUser(vhhpUser: VhhpUser, selectedAvatar?: SelectedAvatar) {
        const uai: UserAvatarInfo = {
            firstName: vhhpUser.profile?.firstName || "",
            lastName: vhhpUser.profile?.lastName || "",
            avatars: vhhpUser.avatars,
            useInitialsAvatar: vhhpUser.profile?.useInitialsAvatar,
            avatarUrl: vhhpUser.profile?.avatarUrl

        };
        return getAvatarCollectionForUser(uai, "Large", "pp-avatar", "pp-avatar--selected", this.handleAvatarClick, selectedAvatar);
    }
    async submitAvatarForm() {
        const me = this;
        const oFormElement = document.forms.namedItem("pp-form");

        const actionUrl = "https://identity.habitatnycwc.org/api/Avatars";
        /*
        const formData = this.state.croppedImage
            ? this.getCroppedImageFormData()
            : oFormElement !== null
                ? new FormData(oFormElement)
                : undefined;
        */
        // use XMLHttpRequest as fetch and the api don't work well for file types
        /* if (formData) {
            var oReq = new XMLHttpRequest();
            oReq.onload = function (e) {
                //this.response contains the url
                me.setState({ fileUploadSrc: null, croppedImageUrl: "", croppedImage: undefined, selectedAvatar: { isInitialsAvatar: false, avatarUrl: this.response } });
                vhhpUserService.getVhhpUser(true).then(() => me.populateState());
            };
            oReq.open("post", actionUrl);
            oReq.send(formData);


        }*/
        
        let blobName= ""
        try{
            console.log("check file uploading")
            const vhhpUser = await vhhpUserService.getVhhpUser();
            console.log("get user", vhhpUser)
            if(vhhpUser?.profile)
            {
                console.log("try to upload blob", vhhpUser?.profile)
                blobName= await AzureFileUploader.uploadFile(this.state.croppedImage,vhhpUser?.profile?.userProfileID)
            }
            else{
                throw "can't grab user profile data"
            }
        }
        catch (error){
            console.log("ERROR WITH UPLOADING FILE TO BLOB STORAGE")
            console.log(error)
        }
        console.log(blobName)
        me.setState({ fileUploadSrc: null, croppedImageUrl: "", croppedImage: undefined, selectedAvatar: { isInitialsAvatar: false, avatarUrl: blobName } });
        //vhhpUserService.getVhhpUser(true).then(() => me.populateState());
  

    }
    handleCropCancelClick(e: React.MouseEvent) {
        this.setState({ fileUploadSrc: null, croppedImageUrl: "", croppedImage: undefined });
    }
    getCroppedImageFormData() {
        const formData = new FormData();
        if (this.state.vhhpUserID && this.state.croppedImage) {
            formData.append("VhhpUserID", this.state.vhhpUserID);
            formData.append("file", this.state.croppedImage);
        }
        return formData;
    }
    async handleAvatarClick(e: React.MouseEvent) {
        const avatar = (e.target as any);
        const isInitialsAvatar = avatar.innerHTML !== "";
        const avatarUrl = avatar.src;
        const selectedAvatar = { isInitialsAvatar: isInitialsAvatar, initials: avatar.innerHTML, avatarUrl: avatarUrl };
        const vhhpUser = await vhhpUserService.getVhhpUser();
        let avatarCollection: JSX.Element[];
        if (vhhpUser) {
            avatarCollection = await this.getAvatarCollectionForUser(vhhpUser, selectedAvatar);
            this.setState({ avatarCollection, selectedAvatar });
        } else {
            this.setState({ selectedAvatar });
        }
    }
    handleCancelClick(e: React.MouseEvent) {
        if (this.props.closeModal)
            this.props.closeModal();
    }
    handleSaveClick(e: React.MouseEvent) {
        if (this.props.saveAndCloseModal) {
            let selectedAvatar = this.state.selectedAvatar;
            if (!selectedAvatar) {
                selectedAvatar = {
                    avatarUrl: "",
                    isInitialsAvatar: false
                }
            }
            this.props.saveAndCloseModal(selectedAvatar);
        }
    }
    handleFileUploadChange(e: React.ChangeEvent<HTMLInputElement>) {
        //use loadImg to address exif orientation issues on some browsers
        //https://github.com/DominicTobias/react-image-crop/issues/181
        if (e.target.files && e.target.files.length > 0) {
            loadImage( e.target.files[0], (img) => { 
                var base64data = (img as HTMLCanvasElement).toDataURL('image/jpeg'); 
                this.setState({ fileUploadSrc: base64data }); 
            }, { orientation: true, canvas: true } );            

            // const reader = new FileReader();
            // reader.addEventListener('load', () =>
            //     this.setState({ fileUploadSrc: reader.result })
            // );
            // reader.readAsDataURL(e.target.files[0]);
        }
    }
    // If you setState the crop in here you should return false.
    onImageLoaded = (image: HTMLImageElement) => {
        this.imageRef = image;
    };

    onCropComplete = (crop: Crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop: Crop, percentCrop: PercentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };
    getCroppedImg(image: HTMLImageElement, crop: Crop, fileName: string): Promise<string> {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width || 0;
        canvas.height = crop.height || 0;
        const ctx = canvas.getContext('2d');


        ctx?.drawImage(
            image,
            //@ts-ignore
            crop.x * scaleX,
            //@ts-ignore
            crop.y * scaleY,
            //@ts-ignore
            crop.width  * scaleX,
            //@ts-ignore
            crop.height * scaleY,
            0,
            0,
            //@ts-ignore
            crop.width ,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }

                //store the cropped image in the file input element for easy upload later
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    this.setState({ croppedImage: this.dataURLtoFile((reader.result as string), 'cropped.jpg') });
                }

                (blob as any).name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    dataURLtoFile(dataUrl: string, filename: string) {
        
        let arr = dataUrl.split(','),
            //@ts-ignore
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    async makeClientCrop(crop: Crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    render() {
        const isLoading = this.state.loading;
        const errorMsg = this.state.errorMsg;
        const vhhpUserID = this.state.vhhpUserID;
        const avatars = this.state.avatarCollection;

        const isCropping = this.state.fileUploadSrc ? true : false;
        const headerText = isCropping
            ? "Crop Your Photo"
            : "Select Profile Photo";
        const headerSubText = isCropping
            ? "Please select the region of the photo to use as your profile picture."
            : "Please select or add a profile photo.";


        const selectedAvatar = <Avatar
            size="Large"
            source={this.state.selectedAvatar?.isInitialsAvatar ? "UserInitials" : this.state.selectedAvatar?.avatarUrl || ""}
            firstName={this.props.firstName}            
            lastName={this.props.lastName}
            wrapperClass="pp-avatar" />

        return (
            <div className="pp-container">
                <div className="pp-body">
                    <div className="pp-header">
                        <div className="pp-header-left">
                            <div className="pp-header__title h3 gray-1">{headerText}</div>
                            <div className="pp-header__text body3 gray-1">{headerSubText}</div>
                        </div>
                        {!isCropping && <div className="pp-header__avatar">{selectedAvatar}</div>}
                    </div>
                    {!isCropping && <div className="pp-upload">
                     <form encType="multipart/form-data" id="pp-form" name="pp-form" action="/api/Avatars">
                            <div className="pp-file-wrap">
                                <input onChange={(e) => this.handleFileUploadChange(e)} type="file" name="file" id="file" className="pp-file-input" accept=".jpg,.jpeg,.png" />
                                <label htmlFor="file" className="pp-file-label">Select a photo from your device</label>
                            </div>
                            <input type="hidden" name="VhhpUserID" value={vhhpUserID} />
                      </form>
                    </div>}
                    {isCropping &&
                        <div className="pp-crop">
                            <div className="pp-react-crop-container">
                                <ReactCrop
                                    src={(this.state.fileUploadSrc as string)}
                                    crop={this.state.crop}
                                    ruleOfThirds
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                    keepSelection
                                    circularCrop
                                    className="pp-react-crop"
                                    imageStyle={{maxHeight: 55 + "vh"}}
                                />
                            </div>
                            <div className="pp-crop-preview">
                                    {this.state.croppedImageUrl && (
                                        <div>
                                            <div className="pp-preview label--primary gray-1">Preview</div>
                                            <Avatar size="Large" source={this.state.croppedImageUrl} wrapperClass="pp-avatar pp-avatar-preview" />
                                        </div>
                                    )}
                                </div>
                            <div className="pp-crop-action-row">
                                <div className="pp-crop-action-cancel">
                                    <a onClick={(e) => this.handleCropCancelClick(e)} className="a gray-1 pp-footer__cancel-button pp-crop-action__cancel">Cancel</a>
                                </div>

                                

                                <div className="pp-crop-action-save">
                                    <VhhpButton styleOptions="standard" text="Save" onclick={(e) => this.submitAvatarForm()} className="pp-crop-action__save" />
                                </div>

                            </div>
                        </div>}

                    {!isCropping && <div className="pp-selection">
                        {avatars}
                    </div>}
                    {!isCropping && <div className="pp-footer">
                        <a onClick={(e) => this.handleCancelClick(e)} className="a gray-1 pp-footer__cancel-button">Cancel</a>
                        <VhhpButton onclick={(e) => this.handleSaveClick(e)} className="pp-footer__save-button" text="OK" styleOptions="standard" />
                    </div>}
                </div>
            </div>
        );
    }

}

