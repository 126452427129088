import './VhhpButtonDonate.css'
import React, { Component } from 'react'
import VhhpButton, { VhhpButtonStyle } from './VhhpButton'

//Prod URL "https://www.spotfund.com/widget/story/";
//QA URL "https://spotfund-responsive-qa.herokuapp.com/widget/story/";
// const SPOTFUND_URL = process.env.REACT_APP_SPOTFUND_URL;
const SPOTFUND_URL = 'https://www.spotfund.com/widget/story/'

// QA SPOTFUND_STORY_ID = "910756c8-f6ff-4037-993d-bf0c1f597a5a";
// Prod SPOTFUND_STORY_ID = "470e6324-968c-4b94-9030-1ca46ace7bb1";
// const SPOTFUND_STORY_ID = process.env.REACT_APP_SPOTFUND_STORY_ID;
const SPOTFUND_STORY_ID = '470e6324-968c-4b94-9030-1ca46ace7bb1'

// Adaptation of process found here: https://spotfund-widget.s3.amazonaws.com/demo/index.html?SFID=install#advanced
// Code no longer depends on global variables, self executing code, or external css/js
// changes were made because the spotfund button may not exist in DOM before SpotFund's external js runs

// the spotfund__wrapper div should only be placed on a page once, regardless of how many donate buttons are on a page

interface VhhpButtonDonateProps {
  text: string
  styleOptions: 'standard' | VhhpButtonStyle
  className?: string,
  donateUrl?: string
}

export default class VhhpButtonDonate extends Component<VhhpButtonDonateProps> {
  spotFundWrapper: Element
  constructor(props: VhhpButtonDonateProps) {
    super(props)

    this.spotFundWrapper = this.getSpotfundWrapper()

    this.removeSpotfundWrapperChildren = this.removeSpotfundWrapperChildren.bind(
      this
    )

    this.getStory = this.getStory.bind(this)
  }

  removeSpotfundWrapperChildren() {
    let spotfundWrapper = this.spotFundWrapper
    while (spotfundWrapper.firstChild) {
      spotfundWrapper.removeChild(spotfundWrapper.firstChild)
    }

    /** Important: remove wrapper CSS to make site usable again */
    //spotfundWrapper.classList = "";
    spotfundWrapper.className = ''
  }

  getSpotfundWrapper() {
    //ensures the SpotFund wrapper only exists in the DOM one time, regardless of how many donate buttons are added to the page
    //called by constructor

    let spotFundWrapperCollection = document.getElementsByClassName(
      'spotfund__wrapper'
    )

    if (spotFundWrapperCollection.length === 0) {
      let wrapper = document.createElement('div')
      wrapper.className = 'spotfund__wrapper'

      document.body.appendChild(wrapper)
    }
    return document.getElementsByClassName('spotfund__wrapper')[0]
  }

  getStory() {
    // const spotfundWrapper = this.spotFundWrapper;
    // /** Build the background overlay */
    // const backgroundModal = document.createElement('div');
    // backgroundModal.className = "sfBackgroundModal";
    // const iFrameWrapper = document.createElement('div');
    // iFrameWrapper.className = "sfIFrameWrapper";
    // /** Build the iFrame */
    // const iframe = document.createElement('iframe');
    // iframe.src = SPOTFUND_URL + SPOTFUND_STORY_ID;
    // iframe.className = "sfIFrame";
    // /** Build the Close button */
    // const button = document.createElement('button');
    // button.className = "sfCloseButton";
    // button.innerHTML = "X";
    // //spotfundWrapper.classList = "sfWrapper";
    // spotfundWrapper.className = "sfWrapper";
    // /** Add new JS elements to the DOM in the correct order */
    // spotfundWrapper.appendChild(button);
    // spotfundWrapper.appendChild(iFrameWrapper);
    // iFrameWrapper.appendChild(iframe);
    // spotfundWrapper.appendChild(backgroundModal);
    // /** Add event listener to close button to remove modal & iFrame */
    // button.addEventListener('click', this.removeSpotfundWrapperChildren);
    // backgroundModal.addEventListener('click', this.removeSpotfundWrapperChildren);
  }

  render() {
    return (
      <a href={this.props.donateUrl ? this.props.donateUrl:"https://habitatnycwc.org/donate-now/"} target="_blank">
        <VhhpButton
          onclick={this.getStory}
          text={this.props.text}
          styleOptions={this.props.styleOptions}
          className={this.props.className}
        />
      </a>
    )
  }
}
