import React from 'react';

const Timer = ({ currDay, currHour }) => {
  return (
    <div className='timer'>
      <h1>
        {currDay} {currHour}
      </h1>
    </div>
  );
};

export default Timer;
