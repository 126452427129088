import "./VhhpButton.scss";
import React, { Component } from 'react';

export interface VhhpButtonStyle {
    color: "white" | "gray" | "blue";
    background: "blue" | "dark-blue" | "gold" | "light-green" | "dark-green" | "gray" | "transparent";
    outline: boolean;
}

interface VhhpButtonProps {
    onclick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    url?: string;
    text: string;
    styleOptions: "standard" | VhhpButtonStyle;
    className?: string;
    disabled?: boolean;
    type? : "button" | "submit" | "reset";

}
export default class VhhpButton extends Component<VhhpButtonProps> {
    constructor(props: VhhpButtonProps) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        if (this.props.onclick !== undefined)
            this.props.onclick(event);
        if (this.props.url !== undefined)
        window.open(this.props.url)
            // window.location.href = this.props.url;
    }

    getVhhpButtonClasses(): string {
        let buttonClasses = "vhhp-button";
        let styleOptions: VhhpButtonStyle =
            this.props.styleOptions === "standard" ?
                {
                    color: "white",
                    background: "blue",
                    outline: false
                } : this.props.styleOptions;

        switch (styleOptions.color) {
            case "gray": buttonClasses += " vhhp-button--gray"; break;
            case "white": buttonClasses += " vhhp-button--white"; break;
            case "blue": buttonClasses += " vhhp-button--blue"; break;
        }
        if (styleOptions.outline) {
            switch (styleOptions.color) {
                case "gray": buttonClasses += " vhhp-button--outline-gray"; break;
                case "white": buttonClasses += " vhhp-button--outline-white"; break;
                case "blue": buttonClasses += " vhhp-button--outline-blue"; break;
            }
        }
        switch (styleOptions.background) {
            case "blue": buttonClasses += " vhhp-button--bg-blue"; break;
            case "dark-blue": buttonClasses += " vhhp-button--bg-dark-blue"; break;
            case "dark-green": buttonClasses += " vhhp-button--bg-dark-green"; break;
            case "gold": buttonClasses += " vhhp-button--bg-gold"; break;
            case "light-green": buttonClasses += " vhhp-button--bg-light-green"; break;
            case "gray": buttonClasses += " vhhp-button--bg-gray"; break;
            case "transparent": buttonClasses += " vhhp-button--bg-transparent"; break;

        }
        buttonClasses += this.props.className ? ` ${this.props.className}` : "";
        return buttonClasses;
    }

    render() {
        return (
            <button type={this.props.type ? this.props.type : "button"} disabled={this.props.disabled ? true : false } onClick={this.handleClick} className={this.getVhhpButtonClasses()}>{this.props.text}</button>
        );
    }
}