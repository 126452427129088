import * as t from '../types';

const initialState = {
  isRooms: false,
  view: '',
};

const interactions = (state = initialState, action) => {
  switch (action.type) {
    case t.SHOW_ROOMS:
      return {
        ...state,
        isRooms: action.payload,
      };
    case t.SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default interactions;
