import './AgendaSection.scss'
import React, { Component } from 'react'
import PublicAgendaView from '../../Agenda/PublicAgendaView'
import FeatureVideos from '../sections/featureVideos/FeatureVideos'
import { connect } from 'react-redux'
import DownloadLink from '../../Elements/DownloadLink'
import agendaImg from './img/agenda.png'

interface AgendaSectionProps {
  view?: string
}
interface AgendaViewState {
  headerSelection: "left-od" | "right-ls" | undefined;
}
class AgendaSection extends Component<AgendaSectionProps, AgendaViewState> {
  constructor(props: AgendaSectionProps)
  {
    super(props)
    this.state = {
      headerSelection: "left-od"

    }
    this.handleTwinHeaderClick = this.handleTwinHeaderClick.bind(this)
  }
  handleTwinHeaderClick(which: "left-od" | "right-ls") {
    if (this.state.headerSelection !== which)
        this.setState({ headerSelection: which });
  }
  render() {
    return (
      <>
        <div className="agenda-section">
          {/*<div className="feature-section" id="featured">
            <FeatureVideos />
            {{this.props.view !== 'public_ur1' && this.props.view !== 'public_ur2' ? <FeatureVideos /> : ''}}
          </div>*/}
          <div className="agenda-container container--pall">
            <div className="agenda-overlay"></div>
            <div className="agenda-content">

              <div>
              <div className="agenda-content_header label--primary">
                Agenda
              </div>
              <div className="agenda-content_title display">
              Explore and Attend Our Two Night Event
              </div>
              <div className="pav-header pav-header-twin">
                  <div onClick={(e) => this.handleTwinHeaderClick("left-od")} className={`pav-header_date-group pav-header_twin-left${this.state.headerSelection === "right-ls" ? " pav-header_twin-unselected" : ""}`}>
                      <div className="pav-header_day label--primary active">TUE</div>
                      <div className="pav-header_num-day h2">10.26</div>
                  </div>
                  <div onClick={(e) => this.handleTwinHeaderClick("right-ls")} className={`pav-header_date-group pav-header_twin-right${this.state.headerSelection === "left-od" ? " pav-header_twin-unselected" : ""}`}>
                      <div className="pav-header_day label--primary">WED</div>
                      <div className="pav-header_num-day h2">10.27</div>
                  </div>
              </div>
              </div>
              <div className="pav-bg-img">
                <img src={agendaImg} alt="house image" />
              </div>
              {/*<div className="agenda-content_cta white">
                <DownloadLink />
              </div>*/}
            </div>
            <div className="agenda-content_agenda">
                {/*<div className="agenda-content_agenda-container-left">
                  <div
                    className={`agenda-left  ${
                      this.props.view === 'public_r1' ||
                      this.props.view === 'public_ur1' ||
                      this.props.view === 'sponsor_r1'
                        ? 'agenda-active'
                        : 'agenda-inactive'
                    }`}
                  >
                    <PublicAgendaView view="mon" />
                  </div>
                  </div>*/}
                <div className="agenda-content_agenda-container-middle">
                  <PublicAgendaView view="both" headerSelection={this.state.headerSelection} />
                </div>
                {/*<div className="agenda-content_agenda-container-right">
                  <div
                    className={`agenda-right  ${
                      this.props.view === 'public_r2' ||
                      this.props.view === 'public_ur2' ||
                      this.props.view === 'sponsor_r2'
                        ? 'agenda-active'
                        : 'agenda-inactive'
                    }`}
                  >
                    <PublicAgendaView view="tue" />
                  </div>
                  </div>*/}
              </div>
            {/*<img
              className="gradient-overlay hide-mobile show-desktop"
              src={require('./img/agenda-bottom-bg.png')}
              alt=""
            />*/}
          </div>
        </div>
      </>
    )
    // Backup
    return (
      <>
        <div className="agenda-section">
          {/*<div className="feature-section" id="featured">
            <FeatureVideos />
            {{this.props.view !== 'public_ur1' && this.props.view !== 'public_ur2' ? <FeatureVideos /> : ''}}
          </div>*/}
          <div className="agenda-container container--pall">
            <div className="agenda-overlay"></div>
            <div className="agenda-content">
              <div className="agenda-content_header label--primary">
                Agenda
              </div>
              <div className="agenda-content_title display">
              Explore and Attend Our Two Night Event
              </div>
              {/*<div className="agenda-content_cta white">
                <DownloadLink />
              </div>*/}
              <div className="agenda-content_agenda">
                <div className="agenda-content_agenda-container-left">
                  <div
                    className={`agenda-left  ${
                      this.props.view === 'public_r1' ||
                      this.props.view === 'public_ur1' ||
                      this.props.view === 'sponsor_r1'
                        ? 'agenda-active'
                        : 'agenda-inactive'
                    }`}
                  >
                    <PublicAgendaView view="mon"  headerSelection={this.state.headerSelection}  />
                  </div>
                </div>
                <div className="agenda-content_agenda-container-middle">
                  <PublicAgendaView view="both"  headerSelection={this.state.headerSelection}  />
                </div>
                <div className="agenda-content_agenda-container-right">
                  <div
                    className={`agenda-right  ${
                      this.props.view === 'public_r2' ||
                      this.props.view === 'public_ur2' ||
                      this.props.view === 'sponsor_r2'
                        ? 'agenda-active'
                        : 'agenda-inactive'
                    }`}
                  >
                    <PublicAgendaView view="tue"  headerSelection={this.state.headerSelection}  />
                  </div>
                </div>
              </div>
            </div>
            <img
              className="gradient-overlay hide-mobile show-desktop"
              src={require('./img/agenda-bottom-bg.png')}
              alt=""
            />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  view: state.interactions.view,
})

export default connect(mapStateToProps)(AgendaSection)
