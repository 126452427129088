import React, { Fragment } from 'react';
import SubMenu from '../Menus/SubMenu';
import TopMenu from '../Menus/TopMenu';
import faqData from './Faq.json';
import FaqItem from './FaqItem';
import './Faq.scss';
import SponsorMenu from '../Menus/SponsorMenu';
import FooterMenu from '../Menus/FooterMenu';

const Faq = (props) => {
  const pathname =
    props.match.path === '/faq' ? 'Frequently Asked Questions' : '';
  return (
    <Fragment>
      <TopMenu theme='dark' />
      <div className='faq__breadcrumbs'>
        <SubMenu color='blue' pathname={pathname} />
      </div>
      <div className='faq__container container--px'>
        <div className='faq__header display blue-3'>
          Frequently Asked Questions
        </div>
      </div>

      <div className='faq__content container--px'>
        {faqData.map((item) => (
          <FaqItem item={item} />
        ))}
      </div>
      <SponsorMenu />
      <FooterMenu />
    </Fragment>
  );
};

export default Faq;
