import React, { Component } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import AuthorizeService from '../api-authorization/AuthorizeService';
import vhhpUserService from '../../VhhpUserService';

type LinkTo = LinkProps['to'];

interface AuthMenuState {
    isAuthenticated: boolean;
    userName: string | null;
    avatar: JSX.Element | undefined;
    isOpen: boolean;
}

interface AuthMenuProps {
    className: string;
    // location: Location;
}

export default class AuthMenu extends Component<AuthMenuProps, AuthMenuState> {

    constructor(props: AuthMenuProps) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            avatar: undefined,
            isOpen: false,
        }
    }


    handleClick = () => {
        this.setState({
            ...this.state,
            isOpen: !this.state.isOpen,
        })
    }
    authenticatedView(userName: string | null, profilePath: string, logoutPath: string, avatar: JSX.Element | undefined) {
        const useAvatar = avatar !== undefined;
        const content = useAvatar ? avatar : ` - Hello ${userName}`;
        return (
            <>
               {/* <Link className={`${this.props.className} ${useAvatar ? "a-auth-menu-avatar" : ""}`} to={profilePath}><span className="auth-menu-avatar__span">Profile</span>{content}</Link>
                <Link className={this.props.className} to={logoutPath}>Log Out</Link>*/}
                <a className={`${this.props.className} ${useAvatar ? "a-auth-menu-avatar" : ""}`} href={profilePath}><span className="auth-menu-avatar__span">Profile</span>{content}</a>
                <a className={this.props.className} href={logoutPath} onClick={async()=>{await AuthorizeService.signOut()}}>Log Out</a>
            </>
        );
    }

    anonymousView(registerPath: string, loginPath: string) {
        // console.log(`the login path is ${loginPath} and the register path is ${registerPath}`)
        // return <a className={this.props.className} onClick={()=>{AuthorizeService.redirectToLogin()}} >Login</a>
        return (
            <>
                {/*<Link className={this.props.className} to={registerPath}>Register</Link>
                <Link className={this.props.className} to={loginPath}>Log In</Link>*/}
                {/* <a className={this.props.className} onClick={()=>{AuthorizeService.redirectToRegister()}} >Register</a> */}
                <a className={this.props.className} onClick={()=>{AuthorizeService.redirectToLogin()}} >Login</a>

                {/* <div className={`${this.props.className} pointer`} onClick={this.handleClick}>Log In</div> */}

                {/* {this.state.isOpen && <ModalWrapper setOpen={this.handleClick} componentToPassDown={<LoginPopup />} />} */}
            </>
        );
    }

    async componentDidMount() {
        //in the menu, check for attempted login
        let isAuth=AuthorizeService.isAuthenticated()
        /*let isAuth= false
        if  (AuthorizeService.isAttemptLoggedIn())
        {
            isAuth=await AuthorizeService.checkAuthState()    
            AuthorizeService.setAttemptLoggedIn(false)
        }
        else{
            isAuth= AuthorizeService.isAuthenticated()
        }*/
        
        if(isAuth)
        {
            let userProfile =await vhhpUserService.getVhhpUser()
            this.setState({isAuthenticated:isAuth,userName: userProfile?.profile?.firstName || ''})
        }
        else{
            this.setState({isAuthenticated:isAuth})
        }


    }

    render() {
        const {isAuthenticated,userName, avatar } = this.state;
        

        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath =  `${ApplicationPaths.LogOut}`;
            return this.authenticatedView(userName, profilePath, logoutPath, avatar);
        }
    }
}