import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import './FormElementContainers.css';
import { StyledLabel, StyledError, StyledSelect } from './StyledFormElements';

const PrimaryTheme = {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    letterSpacing: "0.0025rem"
};


interface VhhpSelectProps {
    label: string;
    wrapperClassName?: string;
}

const VhhpSelect = (props: FieldHookConfig<string> & VhhpSelectProps) => {
    const [field, meta] = useField(props);
    return (
        <div className={`vhhp-input-container ${props.wrapperClassName}`}>
            <div className="vhhp-label-wrapper">
                <StyledLabel htmlFor={props.name}>{props.label}</StyledLabel>
                {
                        meta.touched && meta.error
                        ? <StyledError>{meta.error}</StyledError>
                        : null
                }                
            </div>
            <StyledSelect {...field}>{props.children}</StyledSelect>            
        </div>
    );
}

export default VhhpSelect;