import './Formbox.css';

import logoGoogle from './img/Logo_Google.png';
import logoLinkedIn from './img/Logo_LinkedIn.png';
import logoFacebook from './img/Logo_Facebook.png';
import logoTwitter from './img/Logo_Twitter.png';
import logoInstagram from './img/Logo_Instagram.png';

import React, { Component, PureComponent, useRef } from 'react';
import VhhpButton from '../Elements/VhhpButton';
import { FormikValues } from 'formik';

import authService, { AuthenticationResultStatus } from '../api-authorization/AuthorizeService';
import InternalSignupForm from './InternalSignupForm';


interface FormboxProps {
    action: "signup" | "signin";
}
interface FormboxState {
    message: string | null;
    isCallback: boolean;
}

export default class Formbox extends PureComponent<FormboxProps, FormboxState> {
    internalLoginForm = React.createRef<FormikValues>();

    constructor(props: FormboxProps) {
        super(props);
        const search = window.location.search;
        const urlParams = new URLSearchParams(search);
        const isCallback = urlParams.get("callback") === "true" ? true : false;

        this.state = {
            message: null,
            isCallback: isCallback
        };
    }

    async componentDidMount() {
        if (this.state.isCallback)
            await this.processIdentityCallback();
    }

    async processIdentityCallback() {
        const action = this.props.action;
        const identityCallbackError = this.getIdentityCallbackErrors();

        // first check to see if we got here because of an error from the Identity area
        if (identityCallbackError) {
            this.setState({ message: identityCallbackError });
            return;
        }
        // no error from Identity area, perform silent login        
        try {
            let result: any = await authService.signIn(null, true);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    // There should not be any redirects as the only time completeSignIn finishes
                    // is when we are doing a redirect sign in flow.
                    throw new Error('Should not redirect.');
                case AuthenticationResultStatus.Success:
                    //await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                    if (action === "signin")
                        window.location.replace("/");
                    else
                        window.location.replace("/register/termsconditions");
                    break;
                case AuthenticationResultStatus.Fail:

                    this.setState({ message: result.message.toString() });
                    break;
                default:
                    throw new Error(`Invalid authentication result status '${result.status}'.`);
            }
        } catch (e) {
            this.setState({ message: "An unknown error has occured" });
            console.log("An unknown error as occurred", e);
        }
    }
    getIdentityCallbackErrors() {
        const urlParams = new URLSearchParams(window.location.search);
        let error = urlParams.get("error");
        if (urlParams.has("error") && !error)
            error = "An unknown error occured.  Please try again.";
        return error;
    }
    getSponsorGuestCode(which: "Sponsor" | "Guest") {
        const urlParams = new URLSearchParams(window.location.search);
        let code = urlParams.get(which) || "";
        return code;
    }

    getExternalLoginFormActionUrl() {
        let actionUrl = "/Identity/Account/ExternalLogin";
        const isCallback = this.state.isCallback;
        switch (this.props.action) {
            case "signin":
                /*                    
                    using this: `?returnUrl=${encodeURI(`/authentication/login?ReturnUrl=${window.location.origin}/register/termsconditions`)}`
                        - completes login process, ignores returnUrl
                        - may have just been because of the capital R in ReturnUrl
                    using this: `?returnUrl=${this.getReturnUrlFromSearchParams()}`;
                        - error from /connect/authorize/callback?client_id=webiste

                    `?returnUrl=${encodeURI("/authentication/login")}`
                */
                actionUrl = isCallback
                    ? actionUrl += `?returnUrl=${encodeURI("/authentication/login")}`
                    : actionUrl += `?returnUrl=${encodeURI("/authentication/login")}`;
                break;
            case "signup":
                actionUrl = isCallback
                    ? actionUrl += `?returnUrl=${encodeURI(`/authentication/login?returnUrl=${window.location.origin}/register/termsconditions`)}`
                    : actionUrl += `?returnUrl=${encodeURI(`/authentication/login?returnUrl=${window.location.origin}/register/termsconditions`)}`;
                break;
        }
        return actionUrl;
    }

    getInternalLoginFormActionUrl() {
        let actionUrl: string;
        const isCallback = this.state.isCallback;
        switch (this.props.action) {
            case "signin":
                actionUrl = "/identity/account/login";
                actionUrl += isCallback
                    ? `?returnUrl=${encodeURI("/authentication/login")}`
                    : `?returnUrl=${encodeURI("/authentication/login")}`;
                break;
            case "signup":
                actionUrl = "/identity/account/register";
                actionUrl += isCallback
                    ? `?returnUrl=${encodeURI(`/authentication/login?returnUrl=${window.location.origin}/register/termsconditions`)}`
                    : `?returnUrl=${encodeURI(`/authentication/login?returnUrl=${window.location.origin}/register/termsconditions`)}`;//`?returnUrl=${encodeURI("/signup?callback=true")}`;
                break;
        }

        return actionUrl;
    }
    getReturnUrlFromSearchParams() {
        //using this as the return url for signup/register tends to break things
        //do not use for now
        const urlParams = new URLSearchParams(window.location.search);
        const returnUrl = urlParams.get("ReturnUrl") || ""; //is the search param case sensitive? -yes
        return returnUrl;
    }
    getTextForRender() {
        switch (this.props.action) {
            case "signin":
                return ["Sign in with:", "Submit"];
            case "signup":
                return ["Sign up with:", "Next"];
        }
    }

    render() {
        const action = this.props.action;
        const [textTop, nextButtonText] = this.getTextForRender();
        const sponsorCode = this.getSponsorGuestCode("Sponsor");
        const guestCode = this.getSponsorGuestCode("Guest");

        return (
            <div className="form-box">

                <div className="form-box-content">
                    <div className="form-box__title gray-1">Welcome</div>
                    {action === "signup" && <div className="form-box__text form-box__text-top gray-1">Upgrade to an All Access Pass <a className="a a-large" href="https://community.habitatnyc.org/thevirtualhabitathouseparty">here</a> or continue with free registration below.</div>}
                    <div className="form-box__text form-box__text-top gray-1">{textTop}</div>
                    <form id="external-account" method="post" action={this.getExternalLoginFormActionUrl()}>
                        <div className="form-box__social-login">                            
                            <button type="submit" name="provider" value="LinkedIn" title="Log in using your LinkedIn account" className="form-box__social-login__button"><img src={logoLinkedIn} alt="LinkedIn" /></button>
                            <button type="submit" name="provider" value="Google" title="Log in using your Google account" className="form-box__social-login__button"><img src={logoGoogle} alt="Google" /></button>
                            <button type="submit" name="provider" value="Facebook" title="Log in using your Facebook account" className="form-box__social-login__button"><img src={logoFacebook} alt="Facebook" /></button>                            
                            <button type="submit" name="provider" value="Twitter" title="Log in using your Twitter account" className="form-box__social-login__button"><img src={logoTwitter} alt="Twitter" /></button>                            
                            {/* <button type="submit" name="provider" value="Facebook" title="Log in using your Instagram account" className="form-box__social-login__button"><img src={logoInstagram} alt="Facebook" /></button> */}
                        </div>
                        <input type="hidden" name="SponsorCode" value={sponsorCode}/>
                        <input type="hidden" name="GuestCode" value={guestCode}/>
                    </form>
                    <div className="form-box__text form-box__text-btm gray-1">or enter your email below:</div>
                    <InternalSignupForm ref={this.internalLoginForm} action={action} formAction={this.getInternalLoginFormActionUrl()} sponsorCode={sponsorCode} guestCode={guestCode} />
                    
                    {this.state.message ? <div className="input-error red-error">{this.state.message}</div> : ""}
                    {action === "signup" ? <div className="form-box__password-requirements form-box__text gray-1">Passwords must be at least six characters long and contain at least one alphanumeric character, at least one digit, at least one lowercase letter, and at least one uppercase letter.</div>: ""}
                </div>

                <div className="form-box__next-button-wrapper">
                    <VhhpButton onclick={e => this.internalLoginForm?.current?.handleSubmit()} styleOptions="standard" text={nextButtonText} className="button__next-button--wide" />
                </div>

            </div>

        );
    }

}