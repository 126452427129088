import { FormikValues } from 'formik';
import React, { Component, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import vhhpUserService, { VhhpUser } from '../../VhhpUserService';
import VhhpButton from '../Elements/VhhpButton';
import FooterMenu from '../Menus/FooterMenu';
import TopMenu from '../Menus/TopMenu';
import ProfileForm from './ProfileForm';
import "./ProfileEdit.css";

interface ProfileEditProps {

}
interface ProfileEditState {
    userDataLoaded: boolean;
    vhhpUser: VhhpUser | undefined;
}

//not very DRY- largely a repeat of CreateProfile
export default class ProfileEdit extends PureComponent<ProfileEditProps, ProfileEditState> {
    profileForm = React.createRef<FormikValues>();
    constructor(props: ProfileEditProps) {
        super(props);

        this.state = { userDataLoaded: false, vhhpUser: undefined };
        this.handleSave = this.handleSave.bind(this);
        this.formikSubmitResults = this.formikSubmitResults.bind(this);
    }
    async componentDidMount() {
        try {
            const vhhpUser = await vhhpUserService.getVhhpUser();
            console.log(vhhpUser)
            if (vhhpUser)
                this.setState({ userDataLoaded: true, vhhpUser: vhhpUser });
            else
                this.setState({ userDataLoaded: true, vhhpUser: undefined });
        } catch (e) {

        }
    }
    formikSubmitResult: Promise<boolean> | null = null;
    formikSubmitResults(submissionResults: Promise<boolean>) {
        this.formikSubmitResult = submissionResults;
    }

    async handleSave() {
        console.log("handle save called")
        //await this.profileForm?.current?.setTouched();
        await this.profileForm?.current?.setFieldTouched("firstName", true);
        await this.profileForm?.current?.setFieldTouched("lastName", true);
        await this.profileForm?.current?.setFieldTouched("jobTitle", true);
        await this.profileForm?.current?.setFieldTouched("companyName", true);
        await this.profileForm?.current?.setFieldTouched("industry", true);
        await this.profileForm?.current?.setFieldTouched("location", true);

        // Aug 23, 2021 added more required fields
        await this.profileForm?.current?.setFieldTouched("userMailingAddress", true);
        await this.profileForm?.current?.setFieldTouched("userUnitNo", true);
        await this.profileForm?.current?.setFieldTouched("userCity", true);
        await this.profileForm?.current?.setFieldTouched("userState", true);
        await this.profileForm?.current?.setFieldTouched("userZipCode", true);

        await this.profileForm?.current?.validateForm();
        console.log(this.profileForm?.current)
        if (!this.profileForm?.current?.isValid)
            return false;
        await this.profileForm?.current?.handleSubmit(); //had a value of 10 for unknown reason

        //perhaps not the best way to know if formik has started its submission
        function timeout(ms: number) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        await timeout(500);
        if (this.formikSubmitResult !== null)
            return this.formikSubmitResult;
        await timeout(500);
        if (this.formikSubmitResult !== null)
            return this.formikSubmitResult;
        return true; // if it got here, let the user continue the wizard, the profile can be updated later
    }

    render() {
        const userDataLoaded = this.state.userDataLoaded;
        const vhhpUser = this.state.vhhpUser;
        let content: JSX.Element;
        if (userDataLoaded) {
            if (vhhpUser && vhhpUser.profile) {               
                //load ProfileForm with initial values   
                content = <ProfileForm submitResults={this.formikSubmitResults} ref={this.profileForm} initialValues={{
                    firstName: vhhpUser.profile.firstName,
                    lastName: vhhpUser.profile.lastName,
                    jobTitle: vhhpUser.profile.jobTitle,
                    companyName: vhhpUser.profile.companyName,
                    industry: vhhpUser.profile.industry,
                    location: vhhpUser.profile.location,
                    avatar: vhhpUser.profile.useInitialsAvatar ? "UserInitials" : vhhpUser.profile.avatarUrl,
                    // Aug 23, 2021 added more required fields
                    userMailingAddress: vhhpUser.profile.userMailingAddress,
                    userUnitNo: vhhpUser.profile.userUnitNo,
                    userCity: vhhpUser.profile.userCity,
                    userState: vhhpUser.profile.userState,
                    userZipCode: vhhpUser.profile.userZipCode,
                    // Aug 23, 2021 none required section
                    emailAddress: vhhpUser.email
                }}></ProfileForm>
            }
            else if (vhhpUser) {
                content = <ProfileForm submitResults={this.formikSubmitResults} ref={this.profileForm} initialValues={{}}></ProfileForm>;
            }
            else {
                content = <ProfileForm submitResults={this.formikSubmitResults} ref={this.profileForm} initialValues={{}}></ProfileForm>;
                //error
                //content = <div className="rwcp-body__text">An unknown error was encountered</div>
            }
        } else {
            //loading
            content = <div className="rwcp-body__text">Loading...</div>
        }
        return (
            <div className="pe-container">
                <TopMenu />
                <div className="pe-content">
                    <div className="pe-content__header gray-1">Edit Profile</div>

                    <div className="pe-content__text gray-1">Need to change your password? Please contact 312-927-8143.</div>
                    <div className="pe-content__profile">
                        {content}
                    </div>
                    <div className="pe-footer">
                        <Link to="/profile" className="a gray-1 pe-cancel-button">Cancel</Link>
                        <VhhpButton onclick={async (e) => {
                            let result=await this.handleSave();
                            if(result)
                            {
                                window.location.href = '/profile'
                            }
                        }} className="pe-save-button" text="Save" styleOptions="standard" />
                    </div>
                </div>
                <FooterMenu />
            </div>


        );

    }
}