import React, { useEffect, useState } from 'react';
import agendaService from '../../../../AgendaService';
import './InfoSection.scss';
import { connect } from 'react-redux';
import AgendaList from './AgendaList';
import { getAgendas } from '../../../../redux/actions/dashboard';
import moment from 'moment-timezone';

const AgendaInfo = ({ view, agendas, loading }) => {
  // var oct = moment('2014-10-01T12:00:00Z');

  // let t1 = '2020-10-22T14:28:11-04:00';
  // let t2 = '2020-10-27T20:00:00.000-04:00';

  // t2 = t2.replace('.000', '');

  // console.log({ t1, t2 });

  // console.log({ timeInEST });

  const mondayData =
    agendas &&
    agendas
      .filter((el) => el.day === 26)
      .sort((a, b) => a.sortOrder - b.sortOrder);

  const tuesdayData =
    agendas &&
    agendas
      .filter((el) => el.day === 27)
      .sort((a, b) => a.sortOrder - b.sortOrder);

  return loading && !agendas ? (
    'Loading ..'
  ) : (
    <div className='info-right'>
      <div className='info-right__my-agenda'>
        <div className='body2 h3 info-right__my-agenda__header'>My Agenda</div>

        <div className='info-right__agenda-list'>
          <ul className='scroll'>
            {view === 'sponsor_r1' ? (
              <AgendaList dayData={mondayData} />
            ) : (
              <AgendaList dayData={tuesdayData} />
            )}
          </ul>
        </div>
        <div className='manage-agenda'>
          <a href='/profile' className='a'>
            Manage my agenda
          </a>
          <svg
            width='14'
            height='9'
            viewBox='0 0 14 9'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M13.8807 6.83347L7.36173 0.145776C7.17093 -0.0480709 6.85292 -0.0480709 6.66212 0.145776L0.143101 6.83347C-0.0477002 7.02731 -0.0477002 7.35039 0.143101 7.54424L0.842703 8.25501C1.0335 8.44885 1.3515 8.44885 1.54231 8.25501L6.66212 2.98885C6.85292 2.79501 7.17093 2.79501 7.36173 2.98885L12.4815 8.2227C12.6723 8.41654 12.9903 8.41654 13.1811 8.2227L13.8807 7.51193C14.0398 7.31808 14.0398 7.02731 13.8807 6.83347Z'
              fill='#3668AE'
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  view: state.interactions.view,
  agendas: state.dashboard.agendas,
  loading: state.dashboard.loading,
});

export default connect(mapStateToProps, { getAgendas })(AgendaInfo);
