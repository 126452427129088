import React from 'react';
import './InfoSection.scss';
import { Link } from 'react-router-dom';
import VhhpButton from '../../../Elements/VhhpButton';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { showRooms } from '../../../../redux/actions/interactions';

const AgendaList = ({ dayData, agendaItems, showRooms, currHour, currDay }) => {
  let now = '2020-10-26T09:00:00.000-04:00';

  //to use for join now button
  // let testNow = dayData[4].startDateTime.split(':')[0];
  // console.log({ testNow });
  // console.log(moment().format()); // will just compare this to testNow for the join button to appear

  let userVhhpEventIds = agendaItems && agendaItems.map((el) => el.vhhpEventID);
  let idSet;
  if (agendaItems) {
    idSet = new Set([...userVhhpEventIds]);
  }

  //console.log({ idSet });

  let speakers = [];

  const handleClick = ({ hour }) => {
    showRooms(true);
  };

  return (
    <div>
      {dayData &&
        dayData.map((el) => (
          <li key={el.vhhpEventID}>
            <div className='agenda-info'>
              <div className='agenda-time label--primary'>
                {/* to stop the recurring time in day 27 */}
                {currDay === '27'
                  ? el.start !== null &&
                    (el.sortOrder === 1 || el.sortOrder === 2)
                    ? el.start + '-' + el.end
                    : ''
                  : el.start !== null
                  ? el.start + '-' + el.end
                  : ''}
              </div>
              <div className='agenda-title body2 h4'>{el.title}</div>
              <div className='agenda-speakers body3'>
                {(speakers = el.guestSpeakers.map((v) => v.name).join(', '))}
              </div>
            </div>
            <div className='agenda-interact'>
              {idSet !== undefined && idSet.has(el.vhhpEventID) ? (
                currHour ===
                moment(el.startDateTime).tz('America/New_York').format('HH') ? (
                  <>
                    <a
                      href='#rooms'
                      className='show-desktop hide-mobile'
                      onClick={handleClick}
                    >
                      <VhhpButton
                        text='Join Now'
                        styleOptions={{
                          color: 'white',
                          background: 'blue',
                          outline: false,
                        }}
                      ></VhhpButton>
                    </a>

                    <div className='agenda-join-label hide-desktop show-mobile'>
                      <div className='label--primary'>Join Now on Desktop</div>
                    </div>
                  </>
                ) : (
                  <img
                    src={require('../../../Agenda/img/button_check.png')}
                    className='av-button av-button__plus'
                    alt=''
                  ></img>
                )
              ) : (
                ''
              )}
            </div>
          </li>
        ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  agendaItems: state.user.agendaItems,
  currHour: state.dashboard.currHour,
  currDay: state.dashboard.currDay,
});
export default connect(mapStateToProps, { showRooms })(AgendaList);
