import './TermsConditions.css';
import React, { Component } from 'react';
import WizardLayout from './WizardLayout';

//todo determine registration flow once more user types are addable
interface TermsConditionsProps {
    viewMode?: boolean;
}
interface TermsConditionsState {
    validationErrorMsg: string | null;
    termsChecked: boolean;
}

export default class TermsConditions extends Component<TermsConditionsProps, TermsConditionsState> {
    constructor(props: any) {
        super(props);
        this.state = { validationErrorMsg: null, termsChecked: false };
        this.handleNextButtonClick = this.handleNextButtonClick.bind(this);
    }
    handleCheckboxChange(e: any) {
        this.setState({ termsChecked: e.target.checked });
    }
    handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<boolean> {
        let cbx = (document.getElementsByName("agree")[0] as HTMLInputElement);
        if (this.state.termsChecked) {
            return Promise.resolve(true);
        }
        this.setState({ validationErrorMsg: "Required" });
        return Promise.resolve(false);
    }
    getFormFields(errorMsg: string | null) {
        return (
            <>
                <div className="label--primary red-error">{errorMsg}&nbsp;</div>
                <div className="tc-footer rwcp-body__text">
                    <input type="checkbox" name="agree" className="tc-footer__checkbox" onChange={e => this.handleCheckboxChange(e)} />
                    <label htmlFor="agree">I have read and agreed to the above terms</label>
                </div>
                
            </>
        );
    }
    render() {
        let errorMsg = this.state.validationErrorMsg;
        let ViewMode = this.props.viewMode || false;

        return (
            <WizardLayout
                onNextClick={this.handleNextButtonClick}
                WizardContentPaneChildren={
                    <div className="rwcp-container">
                        <div className="rwcp-body">
                            <div className="rwcp-body__header">
                                Terms & Conditions
                        </div>
                            <div className="rwcp-body__text">
                                Please review the information below.
                            </div>
                            <div className="tc-overflow body3">
                                <p className="tc-title">Habitat NYC and Westchester Privacy Policy</p>
                                <p>This Privacy Policy describes the information practices of Habitat for Humanity New York City and Westchester County (“Habitat NYC and Westchester”, “We”, “Our”, or “Us”) through our digital properties (“Properties”), which include websites of Habitat NYC and Westchester (www.habitatnycwc.org) and micro-sites associated with Habitat NYC and Westchester events and mobile applications that link directly to this policy.</p>

                                <p className="tc-section-header">Photographic Release</p>
                                <p>I hereby grant and convey unto Habitat all right, title, and interest in any and all photographic images and video or audio recordings made by Habitat during my participation in the The Virtual Habitat House Party, virtual and/or in-person events, including, but not limited to any royalties, proceeds or other benefits derived from such photographs or recordings.</p>

                                <p className="tc-section-header">Information We Collect</p>
                                <p>When you visit our properties, we may collect the following types of information:</p>
                                <ul>
                                    <li>Information you voluntarily provide to us, such as your name, user name, address, phone number, email address and other contact information</li>
                                    <li>When you sign on to our Properties or make a donation with us, we may collect additional contact information and payment information, payment history, profile and preference information you may provide.</li>
                                    <li>We may also collect information including, but not limited to, browser type, pages visited, device type, and time and date of visit. We may use cookies or similar technology to collect such information. Information gathered by cookies or similar technologies may be used for our business purposes such as analytics, research, digital advertising, and operational purposes. You may set your browser to notify you when a cookie is sent or refuse cookies all together, but certain features of this site might not work without cookies.</li>
                                </ul>

                                <p className="tc-section-header">Information Use</p>
                                <p>Habitat NYC and Westchester may use the information that we have collected for purposes including, but not limited to: </p>
                                <ul>
                                    <li>Fulfilling donations or other requests made by visitors; </li>
                                    <li>Providing the benefits of supporting Habitat NYC and Westchester, including non-promotional communications; </li>
                                    <li>Improving and customizing the content of our Properties; </li>
                                    <li>Contacting supporters and those who visit our websites about upcoming events or volunteer opportunities; </li>
                                    <li>Responding to requests for support, services, and/or information; </li>
                                    <li>Notifying visitors about updates to our Properties; </li>
                                    <li>Contacting visitors for marketing purposes; </li>
                                    <li>For our research, recordkeeping, and reporting purposes; and </li>
                                    <li>Complying with law and maintaining the security of our Properties </li>
                                </ul>

                                <p className="tc-section-header">Information Sharing</p>
                                <p>Habitat NYC and Westchester may share information collected through our Properties with other parties, including: </p>
                                <ul>
                                    <li>Trading donor lists with other non-profit organizations that might be of interest to you; </li>
                                    <li>Service providers, agents, or contractors authorized to perform services on our behalf such as providing support for our internal operations or providing website analytics to improve our Properties; </li>
                                    <li>Pursuant to a court order, subpoena, or other legal or government processes or inquiry; </li>
                                    <li>If we believe in good faith that we are required to do so by law, or that disclosure is necessary to prevent a crime, or to protect personal property, the public, or our Properties. </li>
                                </ul>

                                <p className="tc-section-header">Your Choices</p>
                                <p>If you do not wish to receive information about Habitat NYC and Westchester programs and services, volunteer opportunities, or upcoming events, you may opt-out of receiving promotional Habitat NYC and Westchester communications by sending an email request to<a href="mailto:info@habitatnycwc.org">info@habitatnycwc.org</a>.</p>
                                <p>Please note that Habitat NYC members who opt-out of promotional Habitat NYC communications will continue to receive non-promotional Habitat communications via email (such as newsletters). If you would like to opt-out of individual publications, please contact us at 212-991-4000 or email <a href="mailto:info@habitatnycwc.org">info@habitatnycwc.org</a> If you do not wish to have your information shared as previously described, please send an email request to <a href="mailto:info@habitatnycwc.org" >info@habitatnycwc.org</a> indicating such. </p>
                                <p>
                                You may also contact us to indicate your preferences by mail or phone:
                                </p>
                                <p>
                                    Habitat for Humanity New York City and Westchester County <br />
                                    111 John Street, 23rd Floor<br />
                                    New York, NY 10038<br />
                                    212-991-4000 ext. 330<br />
                                    Registered users of our Properties may update their information by logging into their account and changing their information. <br />
                                </p>

                                <p className="tc-section-header">Security</p>
                                <p>We undertake reasonable administrative, technical, and physical measures to safeguard the confidentiality of your information from unauthorized access or disclosure.</p>

                                <p className="tc-section-header">Updates</p>
                                <p>From time to time, we may update this Privacy Policy. We encourage you to review this Privacy Policy regularly to stay informed.</p>

                                <p className="tc-section-header">Questions?</p>
                                <p>If you have questions about this Privacy Policy, please contact us at:</p>
                                <p>
                                    Attention: Privacy Policy<br />
                                    Habitat for Humanity New York City and Westchester County<br />
                                    111 John Street, 23rd Floor<br />
                                    New York, NY 10038<br />
                                    Or via email: <a href="mailto:info@habitatnycwc.org">info@habitatnycwc.org</a><br />
                                </p>
                            </div>
                            {!ViewMode ? this.getFormFields(this.state.termsChecked ? "" : errorMsg) : ""}
                        </div>
                    </div>
                }
            />
        );
    }
}