import React, { Component } from 'react';
import WizardLayout from './WizardLayout';
import AgendaView from '../Agenda/AgendaView';

interface SetUpAgendaProps {
    setUpOrReview: "Set Up" | "Review";
}
export default class SetUpAgenda extends Component<SetUpAgendaProps, any> {
constructor(props: SetUpAgendaProps) {
    super(props);
}

    handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<boolean> {
        //the AgendaView commits changes to DB as they happen
        //so no validation/commit to wait on when next is clicked
        return Promise.resolve(true);
    }


    render() {
        return (
            <WizardLayout onNextClick={(e) => this.handleNextButtonClick(e)}
                WizardContentPaneChildren={
                    <div className="rwcp-container">
                        <div className="rwcp-body">
                            <div className="rwcp-body__header">{this.props.setUpOrReview} Agenda</div>
                            <AgendaView fullHeight={true} />
                        </div>
                    </div>
                }>
            </WizardLayout>
        );
    }
}
