import aboutImg from './img/vhhp2021_about.png'
import aboutImgShrink from './img/about_mobile.png'
import aboutDivider from './img/about_divider_mobile.png'
import './AboutSection.scss'
import React, { Component } from 'react'

interface AboutSectionProps {}

export default class AboutSection extends Component<AboutSectionProps> {
  //"section-header label--primary gray-1"
  render() {
    return (
      <>
        <div className="about-container">
          <div className="tl-box">
            <div className="tl-box_content">
              <div className="tl-box_header label--primary gray-1">About</div>
              <div className="tl-box_title h1 orange-1">
              Housing for a Healthier New York
              </div>
            </div>
            <div className="tl-box_img-container">
              <img className="tl-box_img-container_img about-img-large" src={aboutImg} alt="" />
              <img className="tl-box_img-container_img about-img-shrink" src={aboutImgShrink} alt="" />
            </div>

          </div>
          <div className="br-box">
            <div className="br-box_content body1">
              <p>COVID may have knocked New York down, but by no means did it knock us out. At our 2021 Habitat House Party, we will celebrate the role Habitat NYC and Westchester played in our region’s comeback, building and preserving affordable housing for a healthier New York. </p>
              <p>This two-day event celebrates our organizational leaders and donors who have greatly impacted our affordable housing mission. Business and corporate leaders, philanthropists, rising young professionals and social media influencers will be in attendance on <strong>October 26th and October 27th</strong>.</p>
              
              {/*<p>
                Celebrate Habitat NYC’s role in building our city’s sustainable
                future! Join us for our first-ever Virtual Habitat House Party -
                you will be inspired to be part of New York's cleaner, greener
                and more equitable future.
              </p>*/}
            </div>
          </div>
          {<div className='shrink-display-section'>
              <img className="tl-box_img-container_img about-img-shrink" src={aboutDivider} alt="about devider" />
            </div>}
        </div>
      </>
    )
  }
}
