import React, { useEffect, useState } from 'react';
import FooterMenu from '../../Menus/FooterMenu';
import SponsorMenu from '../../Menus/SponsorMenu';
import SubMenu from '../../Menus/SubMenu';
import TopMenu from '../../Menus/TopMenu';
import FeatureVideosCard from '../../preevent/sections/featureVideos/featureVideosCard/FeatureVideosCard';
import './SingleVideos.scss';
import videoData from '../../preevent/sections/featureVideos/FeatureVideos.json';
import { withRouter, Link } from 'react-router-dom';
import SingleVideoSpeakers from './SingleVideoSpeakers';

const SingleVideos = (props) => {
  const [item, setItem] = useState({});
  const [vidId, setVidId] = useState(null);

  useEffect(() => {
    setVidId(parseInt(props.match.params.video_id));
  }, [props.match.params.video_id]);

  useEffect(() => {
    setItem(videoData[vidId - 1]);
  }, [vidId]);

  const pathname =
    props.match.path.split('/')[1] === 'ondemandvideos'
      ? 'Videos on Demand'
      : '';
  const routeParam = item && item.title;

  return (
    <>
      <TopMenu theme='dark' />
      <div className='single-video__container '>
        <div className='single-video__video wrapper-def'>
          <SubMenu color='white' pathname={pathname} routeParam={routeParam} />
          <div className='single-video__thumbnail'>
            {item && (
              <iframe
                src={`https://www.youtube.com/embed/${item.video_id}?autoplay=1&rel=0`}
                frameborder='0'
                b
                srcdoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,
                  span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;
                  text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}
                  </style><a href=https://www.youtube.com/embed/${item.video_id}?autoplay=1>
                  <img src='/images/thumbnails/${item.thumbnail}'
                  alt=''><span><svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M60 0H0V60H60V0ZM21.3658 41.8091L41.8203 29.9996L21.3658 18.1902L21.3658 41.8091Z" fill="white"/>
                  <path d="M41.8203 29.9996L21.3658 41.8091L21.3658 18.1902L41.8203 29.9996Z" fill="#3668AE"/>
                  </svg>
                  </span></a>`}
              ></iframe>
            )}
          </div>
        </div>

        {item && (
          <div className='single-video__content-block container-- wrapper-def'>
            <div className='single-video__content flex'>
              <div className='single-video__desc flex'>
                <div className='single-video__title white h1'>{item.title}</div>
                <div className='single-video__text body2 white p'>
                  {item.text}
                </div>
              </div>

              <div className='single-video__side-desc flex'>
                <div className='label--primary white'>Speaker</div>
                <div className='single-video__speakers'>
                  {item.speakers &&
                    item.speakers.map((speaker, i) => (
                      <SingleVideoSpeakers speaker={speaker} key={i} />
                    ))}
                </div>

                {/* 
                <div className='single-video__speaker flex'>
                  <div className='single-video__speaker-image'>
                    {item.image && (
                      <img
                        src={`/images/speaker-images/${item.image}.png`}
                        alt=''
                      />
                    )}
                  </div>
                  <div className='single-video__about-speaker '>
                    <div className='single-video__speaker-name h3 white'>
                      {item.name}
                    </div>
                    <div className='label--primary white'>{item.role}</div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        )}

        <div className='single-video__related-videos single-video__content-block container-- wrapper-def'>
          <div className='single-video__related-header'>
            <div className='single-video__related-title h2 white'>
              Related Videos
            </div>

            <div className='single-video__see-more white flex'>
              <Link
                to='/ondemandvideos'
                ignoreScrollBehavior={true}
                className='white'
              >
                <div className='body3'>See More</div>
              </Link>
              <img
                src={require('../../preevent/sections/img/angle-right.svg')}
                alt=''
              />
            </div>
          </div>

          <div className='single-video__related-cards'>
            <FeatureVideosCard item={videoData[17]} />
            <FeatureVideosCard item={videoData[12]} />
            <FeatureVideosCard item={videoData[11]} />
          </div>
        </div>
      </div>

      <div className='single-video__sponsor-menu'>
        <SponsorMenu />
      </div>
      <FooterMenu />
      <div className='help'>Need Help?</div>
    </>
  );
};

export default withRouter(SingleVideos);
