import './Signup.css';
import React, { Component } from 'react';
import Infobox from './Infobox';
import Formbox from './Formbox';

interface SignupState {

}

interface SignupProps {
    action?: "signup" | "signin";
}

export default class Signup extends Component<SignupProps, SignupState> {

    render() {
        let action = this.props.action || "signup";
        return (
            <div className="signup-container">
                <Infobox />

                <Formbox action={action} />
            </div>

        );
    }

}