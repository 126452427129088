import React, { Component } from 'react';
import vhhpUserService, { SponsorGuest } from '../../VhhpUserService';
import VhhpButton from '../Elements/VhhpButton';
import approveImg from './img/approve.png';
import removeImg from './img/remove.png';
import "./ManageGuests.scss";

//Not very DRY - code shared with InviteGuests 
const COPY_TO_CLIPBOARD_TEXT = `As we continue to heal from the COVID-19 crisis, New York’s future is more uncharted than ever. Will we return to a city of rising temperatures, racially disparate housing and soaring rents? Or will we come together and build a sustainable, equitable city for everyone?

Join me for the Women Build Summit, Habitat for Humanity New York City’s first ever all virtual conference, where we will celebrate New York’s sustainable future! This two-day event will feature inspiring speakers, amazing experiences and uplifting stories. You don’t want to miss out.

You are already on my guest list, so just follow this link to create your profile with the following guest code \"[guest_code]\" and attend the event! Come be part of New York’s bright future – one where everyone has a safe, sustainable, affordable place to live.`;
const GUEST_LINK = `https://identity.womenbuildsummit.org/identity/account/register?type=guest&code=`

interface ManageGuestsState {
    currentTab: "Approved" | "Waitlist";
    loading: boolean;
    loadingError: string;
    itemCopied: boolean;
    copyError: boolean;
    guestCode: string;
    guestLimit: number;
    approvedGuestCount: number;
    sponsorID: number;
    sponsorGuest: SponsorGuest[];

}
export default class ManageGuests extends Component<any, ManageGuestsState> {

    constructor(props: any) {
        super(props);

        this.state = { currentTab: "Approved", sponsorID: -1, loading: true, itemCopied: false, copyError: false, loadingError: "", guestCode: "", guestLimit: 0, approvedGuestCount: 0, sponsorGuest: [] };
        this.handleGuestClickAction = this.handleGuestClickAction.bind(this);        
    }
    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        // console.log("called")
        const vhhpUser = await vhhpUserService.getVhhpUser();
        console.log(vhhpUser)
        // if (vhhpUser?.sponsor?.guestCode && vhhpUser.sponsorID) {
        if(vhhpUser?.vhhpUserType === 2) {
            const sponsorGuest = await vhhpUserService.GetSponsorGuestsBySponsorID() || [];
            // console.log(sponsorGuest)

            const guestCode = sponsorGuest?.guestCode?.invitationCode
            console.log(guestCode)
            // const guestCode = sponsorGuest?.guestCode?.invitationCode
            const guestLimit = sponsorGuest?.guestCode?.quantity
            const approvedGuestCount = sponsorGuest?.guests.length
            const sponsorGuests = sponsorGuest?.guests

            // const approvedGuestCount = sponsorGuest?.filter(g => !g.onSponsorWaitList).length || 0;
            this.setState({ loading: false, sponsorID: vhhpUser?.invitedUser?.id, guestCode: guestCode, guestLimit: guestLimit, approvedGuestCount: approvedGuestCount, sponsorGuest: sponsorGuests || [] });
        }
        else
            this.setState({ loading: false, loadingError: "An error has occured, please try again later" });
    }
    handleCopyButtonClick(which: "invitation" | "link") {
        // console.log("handle copy clicked")
        const guestLink = GUEST_LINK + this.state.guestCode;
        // console.log(`guest link is ${guestLink}`)
        const content = which === "invitation"
            ? COPY_TO_CLIPBOARD_TEXT.replace('[guest_code]', this.state.guestCode) + "\n\n" + guestLink
            : guestLink;

        navigator.clipboard.writeText(content).then(
            () => {
                console.log("Copying to clipboard successful.");
                this.setState({ itemCopied: true });
            },
            () => {
                console.log("Unable to write to clipboard.");
                this.setState({ copyError: true });
            });
    }

    handleGuestClickAction(user: SponsorGuest, action: "Approve" | "Remove") {
        if(this.state.sponsorID > 0)
            vhhpUserService.ToggleGuestWaitingListStatus(this.state.sponsorID, user.vhhpUserID).then(result => this.populateState());
    }
    

    getGuestList(tab: "Approved" | "Waitlist") {
        //const onWaitList = tab === "Waitlist";
        const statusText = tab === "Waitlist" ? "Waiting Approval" : "Registered";
        const statusClass = tab === "Waitlist" ? "mg-red-tag" : "mg-green-tag";
        const imgSrc = tab === "Waitlist" ? approveImg : removeImg;
        const imgAlt = tab === "Waitlist" ? "Approve" : "Remove";
        
        const guestList = tab === "Approved"
                ? this.state.sponsorGuest
                : this.state.sponsorGuest
                // ? this.state.sponsorGuest.filter(s => !s.onSponsorWaitList)
                // : this.state.sponsorGuest.filter(s => s.onSponsorWaitList);
        
        // const actionAvailable = tab === "Approved" ? true : (this.state.guestLimit - this.state.approvedGuestCount) > 0;
        const actionAvailable = false
        // console.log(guestList)
        return guestList
            .map(guest =>
                {
                    const date = new Date(guest.registrationDate).toLocaleDateString()
                    // console.log(date)
                    return <div className="mg-guest-row mg-guest" key={guest.email}>
                        <div className="mg-guest-col1 body3">{date}</div>
                        <div className="mg-guest-col2 body3">{guest.email}</div>
                        <div className="mg-guest-col3"><span className={statusClass}>{statusText}</span></div>
                        <div className="mg-guest-col4">{actionAvailable && <img src={imgSrc} alt={imgAlt} title={imgAlt} className="mg-guest-col4__img" onClick={() => this.handleGuestClickAction(guest, imgAlt)} />}</div>
                    </div>
                }
            );
    }

    handleTabClick(targetTab: "Approved" | "Waitlist" ) {
        this.setState({currentTab: targetTab});
    }

    render() {
        const isLoading = this.state.loading;
        const guestLimit = this.state.guestLimit;
        const loadingError = this.state.loadingError;        
        const currentTab = this.state.currentTab;
        const approvedCount = this.state.sponsorGuest.filter(s => !s.onSponsorWaitList).length;
        const waitlistCount = this.state.sponsorGuest.length - approvedCount;
        const tabDescription = currentTab === "Approved"
            ? "Guests in this section are admitted to the exclusive virtual experiences."
            : "The following guests are waiting for approval.";
        const tabInstructions = currentTab === "Approved"
            ? ""
            : `To admit the following guests, please remove guests from the Approved Guest list. If you wish to invite more than ${guestLimit} guests, please contact us.`;
        return (
            <div className="mg-container">
                <div className="mg-header">Manage Guests</div>
                <div>You still can invite {guestLimit} guests using the following invitation link.</div>
                <div className="mg-copy-buttons">
                    <VhhpButton disabled={isLoading || loadingError !== ""} className="mg-copy-buttons__button" styleOptions={{ color: "blue", outline: true, background: "gray" }} onclick={e => this.handleCopyButtonClick("invitation")} text="Copy Invitation" />
                    <VhhpButton disabled={isLoading || loadingError !== ""} className="mg-copy-buttons__button" styleOptions={{ color: "blue", outline: true, background: "gray" }} onclick={e => this.handleCopyButtonClick("link")} text="Copy Invite Link" />
                </div>
                <div className="mg-user-tabs">
                    <div className={`mg-user-tab ${currentTab === "Approved" ? "mg-tab-active" : "mg-tab-inactive"}`} onClick={(e)=> this.handleTabClick("Approved")}>Guests<span className="mg-tab-bubble">{approvedCount}</span></div>
                    {/* <div className={`mg-user-tab ${currentTab === "Waitlist" ? "mg-tab-active" : "mg-tab-inactive"}`} onClick={(e)=> this.handleTabClick("Waitlist")}>Waitlist<span className="mg-tab-bubble" >{waitlistCount}</span></div> */}
                </div>
                <div className="mg-tab">
                    <div className="mg-tab-header">
                        <div className="mg-tab-description">{tabDescription}</div>
                        <div className="mg-tab-instructions">{tabInstructions}</div>
                    </div>
                    <div className="mg-tab-guests">
                        <div className="mg-guest-th mg-guest-row">
                            <div className="mg-guest-col1">Date</div>
                            <div className="mg-guest-col2">Guest Email</div>
                            <div className="mg-guest-col3">Status</div>
                            <div className="mg-guest-col4"></div>
                        </div>
                        {this.getGuestList(currentTab)}
                    </div>
                </div>
            </div>
        );
    }
}
