import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { useLocation } from 'react-router-dom'

import PreEvent from './components/preevent/PreEvent'

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute'
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes'
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants'
import AuthorizeService from './components/api-authorization/AuthorizeService'

import Signup from './components/Signup/Signup'
import TermsConditions from './components/registration/TermsConditions'
import CreateProfile from './components/registration/CreateProfile'
import SetUpAgenda from './components/registration/SetUpAgenda'
import Profile from './components/registration/Profile'
import ProfileEdit from './components/registration/ProfileEdit'
import Donation from './components/registration/Donation'
import InviteGuests from './components/registration/InviteGuests'
import DuringHome from './components/duringHome/DuringHome'
import OnDemandVideos from './components/onDemandVideos/OnDemandVideos'
import SingleVideos from './components/onDemandVideos/singleVideos/SingleVideos'
import Room from './components/Conference/Room'

import SpeakerBio from './components/duringHome/sections/extras/SpeakerBio'
import UseDesktop from './components/Elements/UseDesktop'
import ForgotPassword from './components/Signup/ForgotPassword'
import ResetPassword from './components/Signup/ResetPassword'


import Pusher from './Pusher'

import { getVhhpUser, getAuthStatus, getAllUsers } from './redux/actions/user'
import {
  getAgendas,
  getEvent,
  setDay,
  setDetails,
} from './redux/actions/dashboard'
import Faq from './components/FAQ/Faq'

import ScrollToTop from './components/Elements/ScrollToTop'
import Timer from './components/duringHome/sections/extras/Timer'

import UserCapture from './components/UserCapture'

import { Result, Button } from 'antd'

// console.log = () => {}

const App = ({
  user,
  isAuthenticated,
  getVhhpUser,
  getAgendas,
  getAuthStatus,
  getEvent,
  currHour,
  mondayData,
  setDay,
  setDetails,
  getAllUsers,
  currDay,
}) => {
  const displayName = App.name

  //const timeInEST = moment().tz('America/New_York').format();
  //const [currDT, setCurrDT] = useState(timeInEST);
  const [currDT, setCurrDT] = useState('2021-10-26T19:00:00-04:00')
  //the app level state to setDay in the store
  const [currentDay, setCurrentDay] = useState('')
  const [currentHour, setCurrentHour] = useState('')

  //related to auth function
  const [checkedAuth, setCheckedAuth] = useState(false)

    /************************************ */
   /*check login status after user logs in*/
   /************************************* */
   useEffect(() => {

    const checkAuth = async()=>{
      if(AuthorizeService.isAttemptLoggedIn()){ //only check when user has visited site first time, attempted to login, logout or register recently
        console.log("CHECKING AUTH ON MAIN PAGE")
        setCheckedAuth(false)
        await AuthorizeService.checkAuthState() //check state online  
        AuthorizeService.setAttemptLoggedIn(false) //has checked login
      }
      setCheckedAuth(true)
    }
    checkAuth();
  }, [isAuthenticated])
 
    /************************************ */
   /*end of auth service                  */
   /************************************* */

  const [isOpnnedAlready, setIsOpnnedAlready] = React.useState(false)

  const location = useLocation()
  React.useEffect(() => {
    console.log(`only one tab at a time on this path => ${location.pathname}`)
    if (location.pathname.includes('conference')) {
      localStorage.setItem('loggedIn70', true)
      localStorage.removeItem('loggedIn70')

      if (window !== undefined) {
        window.addEventListener('storage', (event) => {
          if (event.storageArea != localStorage) return
          if (event.key === 'loggedIn70') {
            console.log(event)
            // console.log(localStorage.getItem('loggedIn70'))
            setIsOpnnedAlready(true)
          }
        })
      }

      return () => {
        window.pusher && window.pusher.disconnect()
      }
    }
  }, [])

  function refreshPage() {
    window.location.reload()
  }

  /*for the event day */
  useEffect(() => {
    setCurrentDay(moment(currDT).tz('America/New_York').format('DD'))
    setCurrentHour(moment(currDT).tz('America/New_York').format('HH'))
    setDay(currentDay, currentHour, currDT)
  }, [currentHour, currDT])

  // useEffect(() => {
  //   getAuthStatus();
  //   getAgendas();
  //   getEvent();
  //   setInterval(getEvent, 5000);
  // }, []);

  

  useEffect(async () => {
    
    //after user has been redirected from login page, check authentication state
    getAuthStatus()
    if (isAuthenticated) {
      getVhhpUser()
      getAllUsers()
    }

  }, [isAuthenticated])

  useEffect(() => {
    getAgendas()
    if (isAuthenticated) {
      getEvent()
      setInterval(getEvent, 5000)
    }
  }, [isAuthenticated])

  useEffect(() => {
    const injectIntercom = (user) => {
      window.intercomSettings = {
        app_id: 'ppmg48r6',
        name: (user?.profile?.firstName+' '+user?.profile?.lastName).trim(), //`${user?.profile?.firstName} ${user?.profile?.lastName}`.trim(), // Full name
        email: user?.email, // Email address
        created_at: user?.registrationDate
          ? new Date(user.registrationDate).getTime()
          : Date.now, // Signup date as a Unix timestamp
      }
      ;(function () {
        var w = window
        var ic = w.Intercom
        if (typeof ic === 'function') {
          ic('reattach_activator')
          ic('update', w.intercomSettings)
        } else {
          var d = document
          var i = function () {
            i.c(arguments)
          }
          i.q = []
          i.c = function (args) {
            i.q.push(args)
          }
          w.Intercom = i
          var l = function () {
            var s = d.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = 'https://widget.intercom.io/widget/ppmg48r6'
            var x = d.getElementsByTagName('script')[0]
            x.parentNode.insertBefore(s, x)
          }
          if (w.attachEvent) {
            w.attachEvent('onload', l)
          } else {
            w.addEventListener('load', l, false)
          }
        }
      })()
    }

    injectIntercom(user?.userData)
    // if (isAuthenticated && user.userData) {
    //   // console.log("user is authenticated")
    //   injectIntercom(user.userData)
    // }

  }, [isAuthenticated, user])

  //set the details for the nowPlaying & upNext object depending on the current hour
  useEffect(() => {
    if (mondayData.length) {
      setDetails(mondayData, currHour)
    }
  }, [mondayData, currHour])

  return (
    <>
      {checkedAuth?
      isOpnnedAlready ?(
        <>
          <Result
            status="warning"
            title="Only one tab could be open at a time of this site."
            extra={
              <Button type="primary" key="Reload" onClick={refreshPage}>
                Reload
              </Button>
            }
          />
        </>
      ) : (
        <ScrollToTop>
          <Pusher />
          <div>
            
            {/*<Route exact path="/" render={() => <PreEvent />} />
            <Route exact path="/index.html" render={() => <PreEvent />} />*/}
            <Route exact path="/" render={() => <DuringHome />} />
            <Route exact path="/index.html" render={() => <DuringHome />} />

            <Route path="/signup" render={() => <Signup action="signup" />} />
            <Route path="/signin" render={() => <Signup action="signin" />} />

            <Route
              path="/terms"
              render={() => <TermsConditions viewMode={true} />}
            />
            <Route
              path="/timer"
              render={() => <Timer currDay={currDay} currHour={currHour} />}
            />
            <Route exact path="/ondemandvideos" component={OnDemandVideos} />
            <Route path="/ondemandvideos/:video_id" component={SingleVideos} />
            <Route path="/faq" component={Faq} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/resetpassword" component={ResetPassword} />
            <Route path="/redirect" component={UseDesktop} />
            <AuthorizeRoute exact path="/conference/:conference_name" component={Room} />
            <AuthorizeRoute path="/profile" component={Profile} />
            <AuthorizeRoute path="/profileedit" component={ProfileEdit} />
            <AuthorizeRoute exact 
              path="/register/createprofile"
              component={CreateProfile}
            />
            <AuthorizeRoute exact 
              path="/register/reviewagenda"
              render={() => <SetUpAgenda setUpOrReview="Review" />}
            />
            <AuthorizeRoute exact 
              path="/register/setupagenda"
              render={() => <SetUpAgenda setUpOrReview="Set Up" />}
            />
            <Route exact 
              path="/register/termsconditions"
              component={TermsConditions}
            />
            <AuthorizeRoute exact 
              path="/register/makeadonation"
              component={Donation}
            />
            <AuthorizeRoute exact 
              path="/register/inviteguests"
              component={InviteGuests}
            />
        {/*
            <Route
              path={ApplicationPaths.ApiAuthorizationPrefix}
              component={ApiAuthorizationRoutes}
            />
        */}
          </div>
        </ScrollToTop>
      )
    :<div></div>}
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  isAuthenticated: state.user.isAuthenticated,
  mondayData: state.dashboard.mondayData,
  currHour: state.dashboard.currHour,
  currDay: state.dashboard.currDay,
})

const mapDispatchToProps = {
  getVhhpUser,
  getAuthStatus,
  getAgendas,
  getEvent,
  setDetails,
  setDay,
  getAllUsers,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
