import './SupportSection.scss';
import supportImg from './img/wbs_support.jpg';
import VhhpButtonDonate from '../../Elements/VhhpButtonDonate';
import React, { Component } from 'react';
import VhhpButton from '../../Elements/VhhpButton';

export default class SupportSection extends Component {
    render() {
        return (
            <div className="support-container">
                <div className="support-up-left-arrow"></div>
                <div className="support-tl-box">
                    <div className="support-tl-box-content">
                        <div className="support-tl-box_header label--primary">Support</div>
                        <div className="support-tl-box_title h1 orange-text">Your Gift Builds Our City's Future</div>
                        <div className="support-tl-box_body body2">This is a difficult time for hundreds of thousands of New Yorkers. If you can, please consider a gift to Habitat NYC and Westchester.</div>
                        {/* <VhhpButtonDonate text="Donate" styleOptions={{ color: "gray", background: "light-green", outline: true }}></VhhpButtonDonate> */}
                        <VhhpButton text="Donate" url="https://habitatnycwc.org/donate-now/" styleOptions={{ color: "white", background: "blue", outline: false }} />
                    </div>
                </div>
                <div className="support-br-box">
                    <div className="support-br-box_img-container">
                        <img className="support-br-box_img-container_img" src={supportImg} alt="" />
                    </div>
                </div>
            </div>
        );
    }
}