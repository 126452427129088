import React from 'react';
import "./ProfileForm.css";
import {  Form, Formik } from 'formik';
import * as Yup from 'yup';
import VhhpInput from '../Elements/VhhpInput';
import VhhpSelect from '../Elements/VhhpSelect';
import vhhpUserService from '../../VhhpUserService';
import AvatarField, { AvatarSource } from '../Elements/AvatarField';

interface ProfileFormProps {
    submitResults: (submissionResult: Promise<boolean>) => void;
    initialValues: {
        firstName?: string;
        lastName?: string;
        jobTitle?: string;
        companyName?: string;
        industry?: string;
        location?: string;
        avatar?: AvatarSource;

        // Aug 23, 2021 added new columns
        userMailingAddress?: string;
        userUnitNo?: string;
        userCity?: string;
        userState?: string;
        userZipCode?: string;
        emailAddress?:string;
    }
}

export default React.forwardRef((props: ProfileFormProps, ref) => (
    <Formik
        innerRef={ref as any}
        initialValues={{
            firstName: props.initialValues.firstName || "",
            lastName: props.initialValues.lastName || "",
            jobTitle: props.initialValues.jobTitle || "",
            companyName: props.initialValues.companyName || "",
            industry: props.initialValues.industry || "",
            location: props.initialValues.location || "",
            avatar: props.initialValues.avatar || "",
            // Aug 23, 2021 added new columns
            userMailingAddress: props.initialValues.userMailingAddress || "",
            userUnitNo: props.initialValues.userUnitNo || "",
            userCity: props.initialValues.userCity || "",
            userState: props.initialValues.userState || "",
            userZipCode: props.initialValues.userZipCode || "",
            emailAddress: props.initialValues.emailAddress || ""
        }}
        /*
        "userProfileID": 0,
        "firstName": "string",
        "lastName": "string",
        "userMailingAddress": "string",
        "userUnitNo": "string",
        "userCity": "string",
        "userState": "string",
        "userZipCode": "string",
        "jobTitle": "string",
        "companyName": "string",
        "industry": "string",
        "companyAddress": "string",
        "sponsorCompanyEmployee": true,
        "useInitialsAvatar": true,
        "avatarUrl": "string"
        */
        validationSchema={Yup.object({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            jobTitle: Yup.string().required("Required"),
            companyName: Yup.string().required("Required"),
            industry: Yup.string().required("Required"),
            // location: Yup.string().required("Required"),
            avatar: Yup.string(),
            userMailingAddress: Yup.string().required("Required"),
            userUnitNo: Yup.string().required("Required"),
            userCity: Yup.string().required("Required"),
            userState: Yup.string().required("Required"),
            userZipCode: Yup.string().required("Required"),
            emailAddress: Yup.string()
        })}
        onSubmit={async (values, bag) => {
            // alert("submit")
            console.log("trying to submit")
            console.log(values)
            const useInitialsAvatar = values.avatar === "UserInitials";
            const avatarUrl = useInitialsAvatar ? "" : values.avatar;
            const data = { useInitialsAvatar: useInitialsAvatar, avatarUrl: avatarUrl, ...values };
            let result = vhhpUserService.saveUserProfile(data);
            props.submitResults(result);
            return await result;
        }}
    // onSubmit={(values, { setSubmitting }) => {
    //     // setTimeout(() => {
    //     //   alert(JSON.stringify(values, null, 2));
    //     //   setSubmitting(false);
    //     // }, 400);            
    // }}
    >
        {({
            setFieldValue,
            setFieldTouched,
            values,
            errors,
            touched,
        }) => (
                <Form className="">
                    <div className="pf-container">
                        <div className="pf-flex-outer-row">
                            <div className="pf-picture-container">
                                {/* <Field name="avatar" component={AvatarField} /> */}
                                <AvatarField name="avatar" firstName={values.firstName} lastName={values.lastName} />
                            </div>
                            <div className="pf-flex-col">
                                <div className="pf-flex-rows">
                                    <VhhpInput type="text" name="firstName" label="First Name" wrapperClassName="pf-vhhp-input" />
                                    <VhhpInput type="text" name="lastName" label="Last Name" wrapperClassName="pf-vhhp-input" />
                                    <VhhpInput type="text" name="emailAddress" label="Email Address (username can't be changed)" wrapperClassName="pf-vhhp-input-full" disabled={true} />
                                    <VhhpInput type="text" name="companyName" label="Company" wrapperClassName="pf-vhhp-input" />
                                    <VhhpSelect name="industry" label="Industry" wrapperClassName="pf-vhhp-input">
                                        <option value="">Select One</option>
                                        <option>Agriculture</option>
                                        <option>Apparel</option>
                                        <option>Banking</option>
                                        <option>Biotechnology</option>
                                        <option>Chemicals</option>
                                        <option>Communications</option>
                                        <option>Construction</option>
                                        <option>Consulting</option>
                                        <option>Education</option>
                                        <option>Electronics</option>
                                        <option>Energy</option>
                                        <option>Engineering</option>
                                        <option>Entertainment</option>
                                        <option>Environmental</option>
                                        <option>Finance</option>
                                        <option>Food &amp; Beverage</option>
                                        <option>Government</option>
                                        <option>Healthcare</option>
                                        <option>Hospitality</option>
                                        <option>Insurance</option>
                                        <option>Legal</option>
                                        <option>Machinery</option>
                                        <option>Manufacturing</option>
                                        <option>Media</option>
                                        <option>Not for Profit</option>
                                        <option>Real Estate</option>
                                        <option>Recreation</option>
                                        <option>Retail</option>
                                        <option>Shipping</option>
                                        <option>Technology</option>
                                        <option>Telecommunications</option>
                                        <option>Transportation</option>
                                        <option>Utilities</option>
                                        <option>Other</option>
                                    </VhhpSelect>
                                    <VhhpInput type="text" name="jobTitle" label="Job Title" wrapperClassName="pf-vhhp-input-full" />
                                    <VhhpInput type="text" name="userMailingAddress" label="Mailing Address" wrapperClassName="pf-vhhp-input-full" />
                                    <VhhpInput type="text" name="userUnitNo" label="UNIT/APT NO" wrapperClassName="pf-vhhp-input" />
                                    <VhhpInput type="text" name="userCity" label="City" wrapperClassName="pf-vhhp-input" />
                                    <VhhpSelect name="userState" label="State" wrapperClassName="pf-vhhp-input">
                                        <option value=""></option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </VhhpSelect>
                                    <VhhpInput type="text" name="userZipCode" label="Zip Code" wrapperClassName="pf-vhhp-input" />
                                </div>
                            </div>
                        </div>
                    </div >
                </Form>)}
    </Formik>
));