import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import VhhpUserService from '../../VhhpUserService'

import { presence } from '../../redux/actions/user';

const ChatSidecar = ({
    context,
    presence
}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (context) {
            VhhpUserService.getVhhpUser().then(user => {
                presence(context, user)
            })
        }
    }, [context])

    return <div></div>
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    presence
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatSidecar)
