import React from 'react';
import VhhpButton from '../../../../Elements/VhhpButton';
import './RoomsCards.scss';
import { Link } from 'react-router-dom';

const RoomsCards = ({ item }) => {
  return (
    <div className='rooms-card'>
      <div className='rooms-card__content flex'>
        <div className='rooms-card__image-text'>
          <img src={`/images/sponsorLogos/${item.logo}`} alt='' />
          <div className='rooms-card__info'>
            <div className='rooms-card__title label--primary'>
              {item && item.sponsorName}
            </div>
            <div className='rooms-card__room-name body1'>
              {item && item.subRoomName}
            </div>
            <div className='rooms-card__number body3'>
              {item && item.count === 0
                ? 'Join the Conversation'
                : item.count + ' people in the room'}
            </div>
            {item && item.count >= 30 ? (
              ''
            ) : (
              <div className='rooms-card__cta'>
                <Link to={'/conference/' + encodeURI(item.subRoomName)}>
                  <VhhpButton
                    text='Join now'
                    styleOptions={{
                      color: 'white',
                      outline: false,
                      background: 'blue',
                    }}
                  ></VhhpButton>
                </Link>
              </div>
            )}
          </div>
        </div>

        {item.count === 0 ? (
          ''
        ) : item.count === 30 ? (
          <div className='label--primary filled-room'>Session is Full</div>
        ) : (
          item.count && (
            <div className='rooms-card__pop label--primary'>
              {item.count && 30 - item.count} spots left
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default RoomsCards;
