import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './NewChat.scss'

import SearchChat from './SearchChat'
import ChatUI from './ChatUI'

const ENDPOINT = process.env.REACT_APP_CHAT || 'http://localhost:3000/'

let socket

export const DirectChat = ({ user, users, online, ...props }) => {
  const [messagesEnd, setMessagesEnd] = useState(null)
  const [query, setQuery] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [u, setU] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const context = props.context

  const handleSearch = (e) => {
    const query = e?.target?.value
    if (user) {
      const u = users.filter((user) =>
        `${user?.profile?.firstName} ${user?.profile?.lastName}`
          .toLowerCase()
          .includes(query.toLowerCase())
      )
      setU(u)
      setQuery(query)
    }
  }

  useEffect(() => {
    if (user) {
      setLoaded(true)
      const u = users
      setU(u)
    }
  }, [user, users])

  return (
    <>
      <div
        className="chatbox"
        ref={(el) => {
          setMessagesEnd(el)
        }}
      >
        {loaded && (
          <div className="users-chat-wrapper">
            {!selectedUser && (
              <div className="users">
                <div className="direct-msg-title">
                  <div className="body1">All Participants</div>
                </div>
                <SearchChat onChange={handleSearch} />
                {u.map((user) => {
                  const imageErrorSrc = '/avatar/default/default-05.png'
                  const img = user?.profile?.avatarUrl || imageErrorSrc
                  const name = user?.profile?.firstName
                    ? `${user?.profile?.firstName} ${user?.profile?.lastName}`
                    : user?.name
                  return (
                    <div
                      className="chat-message"
                      onClick={() => {
                        setSelectedUser(user)
                      }}
                    >
                      <div className="thumbnail">
                        <img
                          src={img}
                          alt=""
                          onError={(e) => (
                            (e.target.onerror = null),
                            (e.target.src = imageErrorSrc)
                          )}
                        ></img>
                      </div>
                      <div
                        className="content"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div className="name label--primary">{name}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            {selectedUser && (
              <div className="user-chat">
                <ChatUI
                  user={selectedUser}
                  back={() => {
                    setSelectedUser(null)
                  }}
                />
              </div>
            )}
          </div>
        )}

        {!loaded && (
          <div className="chat-filler label--primary">Connecting...</div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user?.userData,
  users: state.user?.users,
  online: state.user?.online,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DirectChat)
