import React, { PureComponent } from 'react';
import WizardLayout from './WizardLayout';
import ProfileForm from './ProfileForm';
import './CreateProfile.css';
import { FormikValues } from 'formik';
import vhhpUserService, { VhhpUser } from '../../VhhpUserService';

interface CreateProfileProps {

}
interface CreateProfileState {
    userDataLoaded: boolean;
    vhhpUser: VhhpUser | undefined;
}
export default class CreateProfile extends PureComponent<CreateProfileProps, CreateProfileState> {
    profileForm = React.createRef<FormikValues>();
    constructor(props: any) {
        super(props);

        this.state = { userDataLoaded: false, vhhpUser: undefined };
        this.formikSubmitResults = this.formikSubmitResults.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
    }

    async componentDidMount() {
        try {
            const vhhpUser = await vhhpUserService.getVhhpUser();
            if (vhhpUser)
            {
                console.log("VHHP user",vhhpUser)
                // check if the profile section are completed, if not, then redirect to the create profile section
                this.setState({ userDataLoaded: true, vhhpUser: vhhpUser });
            }
            else
                this.setState({ userDataLoaded: true, vhhpUser: undefined });
        } catch (e) {

        }


    }
    formikSubmitResult: Promise<boolean> | null = null;
    formikSubmitResults(submissionResults: Promise<boolean>) {
        console.log(submissionResults)
        this.formikSubmitResult = submissionResults;
    }

    async handleNextClick(e: React.MouseEvent) {
        // console.log("handle next submit")
        //await this.profileForm?.current?.setTouched();
        //return true
        //temporarily disable this part for demo
        await this.profileForm?.current?.setFieldTouched("firstName", true);
        await this.profileForm?.current?.setFieldTouched("lastName", true);
        await this.profileForm?.current?.setFieldTouched("jobTitle", true);
        await this.profileForm?.current?.setFieldTouched("companyName", true);
        await this.profileForm?.current?.setFieldTouched("industry", true);
        // await this.profileForm?.current?.setFieldTouched("location", true);

        // Aug 23, 2021 added more required fields
        await this.profileForm?.current?.setFieldTouched("userMailingAddress", true);
        await this.profileForm?.current?.setFieldTouched("userUnitNo", true);
        await this.profileForm?.current?.setFieldTouched("userCity", true);
        await this.profileForm?.current?.setFieldTouched("userState", true);
        await this.profileForm?.current?.setFieldTouched("userZipCode", true);
        // console.log("field all touched")
        await this.profileForm?.current?.validateForm();
        if (!this.profileForm?.current?.isValid)
        {
            console.log(this.profileForm?.current)
            return false;
        }
        await this.profileForm?.current?.handleSubmit(); //had a value of 10 for unknown reason
        //perhaps not the best way to know if formik has started its submission
        function timeout(ms: number) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        await timeout(500);
        if (this.formikSubmitResult !== null)
        {
            console.log(this.formikSubmitResult)
            return this.formikSubmitResult;
        }
        await timeout(500);
        if (this.formikSubmitResult !== null)
            return this.formikSubmitResult;
        console.log('just proceed')
        return true; // if it got here, let the user continue the wizard, the profile can be updated later

    }

    render() {
        // console.log(this.props)
        const userDataLoaded = this.state.userDataLoaded;
        const vhhpUser = this.state.vhhpUser;
        let content: JSX.Element;
        if (userDataLoaded) {
            if (vhhpUser && vhhpUser.profile) {
                //load ProfileForm with initial values                
                content = <ProfileForm submitResults={this.formikSubmitResults} ref={this.profileForm} initialValues={{
                    firstName: vhhpUser.profile.firstName,
                    lastName: vhhpUser.profile.lastName,
                    jobTitle: vhhpUser.profile.jobTitle,
                    companyName: vhhpUser.profile.companyName,
                    industry: vhhpUser.profile.industry,
                    // location: vhhpUser.profile.location,
                    avatar: vhhpUser.profile.useInitialsAvatar ? "UserInitials" : vhhpUser.profile.avatarUrl,
                    // Aug 23, 2021 added more required fields
                    userMailingAddress: vhhpUser.profile.userMailingAddress,
                    userUnitNo: vhhpUser.profile.userUnitNo,
                    userCity: vhhpUser.profile.userCity,
                    userState: vhhpUser.profile.userState,
                    userZipCode: vhhpUser.profile.userZipCode,
                    // Aug 23, 2021 none required section
                    emailAddress: vhhpUser.email
                }}></ProfileForm>
            }
            else if (vhhpUser) {
                content = <ProfileForm submitResults={this.formikSubmitResults} ref={this.profileForm} initialValues={{}}></ProfileForm>;
            }
            else {
                content = <ProfileForm submitResults={this.formikSubmitResults} ref={this.profileForm} initialValues={{}}></ProfileForm>;
                //error
                //content = <div className="rwcp-body__text">An unknown error was encountered</div>
            }
        } else {
            //loading
            content = <div className="rwcp-body__text">Loading...</div>
        }
        return (
            <WizardLayout
                onNextClick={this.handleNextClick}
                WizardContentPaneChildren={
                    <div className="rwcp-container">
                        <div className="rwcp-body">
                            <div className="rwcp-body__header">Create Your Profile</div>
                            {/*<div className="rwcp-body__text">Please enter your information below for an enhanced networking experience.</div>*/}
                            <div className="cp-profile-container">
                                {content}
                            </div>
                        </div>
                    </div>
                }></WizardLayout>
        );
    }
}