import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import VhhpInput from '../Elements/VhhpInput';
import { Link } from 'react-router-dom';


interface InternalSignupFormProps {
    formAction: string;
    action: "signup" | "signin";
    sponsorCode: string;
    guestCode: string;
}

export default React.forwardRef((props: InternalSignupFormProps, ref) => (
    <Formik
        innerRef={ref as any}
        // innerRef={(ref as any)}                        
        initialValues={{
            Email: "",
            Password: "",
            ConfirmPassword: ""
        }}
        validationSchema={
            Yup.object({
                Email: Yup.string()
                    .email("Invalid email address")
                    .required("Required"),
                Password: Yup.string()
                    .min(6)
                    .max(100)
                    .required("Required"),
                ConfirmPassword: Yup.string()
                    .oneOf([Yup.ref("Password"), ""], "Passwords must match")

            })
        }
        onSubmit={(values, { setSubmitting }) => {
            // setTimeout(() => {
            //   alert(JSON.stringify(values, null, 2));
            //   setSubmitting(false);
            // }, 400);
            (document.getElementById("signupForm") as HTMLFormElement).submit();
        }}
    >

        <Form action={props.formAction} id="signupForm" method="POST">
            <VhhpInput type="text" label="Email Address" name="Email" />
            <VhhpInput type="password" label="Password" name="Password" />
            <input type="hidden" name="SponsorCode" value={props.sponsorCode}/>
            <input type="hidden" name="GuestCode" value={props.guestCode}/>
            {props.action === "signup" && <VhhpInput type="password" label="Confirm Password" name="ConfirmPassword" /> }
            {props.action === "signin" && <Link to="/forgotpassword" className="a">Forgot Password?</Link>}
        </Form>
    </Formik>
));