import './SpeakersSection.scss'
import SpeakerImage from './SpeakerImage'
import imgClose from './img/white_close.png'
import SpeakerImagesConfig, { SpeakerImageConfig } from './SpeakerImagesConfig'
import React, { Component } from 'react'
import './SpeakerImageModal.css'
import ReactModal from 'react-modal'
import Slider from 'react-slick'

//installing slick-carousel brings with it a jquery dependency.
//May just want to grab the theme and ditch slick-carousel... but webpack's treeshaking should eliminate it too
import './slick-carousel/slick/slick.css'
import './slick-carousel/slick/slick-theme.css'


interface SpeakersSectionState {
  modalConfig?: SpeakerImageConfig
}

ReactModal.setAppElement('#root')
export default class SpeakersSection extends Component<
  any,
  SpeakersSectionState
> {
  constructor(props: any) {
    super(props)

    this.state = { modalConfig: undefined }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(config: SpeakerImageConfig) {
    if (config.bio) {
      // no popup if the speaker doesnt have a bio
      this.setState({ modalConfig: config })
    }
  }
  closeModal() {
    this.setState({ modalConfig: undefined })
  }
  getSlides(images: SpeakerImageConfig[]) {
    return images.map((sic) => (
      <SpeakerImage config={sic} openModal={this.openModal} key={sic.key} />
    ))
  }

  getModal(config?: SpeakerImageConfig) {
    if (!config) return null

    return (
      <ReactModal
        className="si-modal"
        overlayClassName="si-modal-overlay"
        contentLabel={config.title}
        onRequestClose={() => this.closeModal()}
        isOpen={this.state.modalConfig !== undefined}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      >
        <div className="si-modal-content">
          <button
            className="si-modal__close-button"
            onClick={() => this.closeModal()}
          >
            {/* <img src={imgClose} alt="close" height="24px" width="24px" /> */}
            X
          </button>
          {/*<div className="si-modal-banner">
          
    </div>*/}
          <div className="si-modal-body">
            {config.thumbSrc && (
              <div className="si-speaker-thumb">
                <img
                  className="si-speaker-thumb__img"
                  alt={config.name}
                  src={config.thumbSrc}
                />
              </div>
            )}
            <div className="si-speaker-head">
              <div className="si-speaker-name h2 gray-1">{config.name}</div>
              <div className="si-speaker-title body2 gray-1">
                {config.title}
              </div>
            </div>
            <div className="si-speaker-bio body3 gray-1">
              {config.bio && config.bio}
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }
  render() {
    const classes = 'slider variable-width'
    return (
      <>
        <div className="speakers-container">
          <div className="speakers-header label--primary gray-1">
            Guest Speakers and Honorees
          </div>
          <div className="speakers-carousel-container">
            <Slider
              className="slider variable-width"
              infinite={true}
              // centerMode={false}
              arrows={true}
              dots={true}
              draggable={false}
              focusOnSelect={true}
              variableWidth={true}
              adaptiveHeight={true}
              autoplay={true}
              speed={500}
              autoplaySpeed={3000}
              cssEase={'linear'}
              // initialSlide={0}
              slidesToShow={3}
              slidesToScroll={3}
              responsive={[
                {
                  breakpoint: 990,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '0',
                  },
                },
                {
                  breakpoint: 500,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '0',
                    className: classes, // + " speakers-slick-left-padding"
                  },
                },
                {
                  breakpoint: 350,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '0',
                    infinite: true,
                    className: classes + " speakers-slick-left-padding", // + " speakers-slick-left-padding"
                  },
                },
              ]}
            >
              {this.getSlides(SpeakerImagesConfig())}
            </Slider>
          </div>
          {this.getModal(this.state.modalConfig)}
        </div>
      </>
    )
  }
}
