import * as t from '../types';
import dummy from './dummy.js';

const initialState = {
  userData: null,
  agendaItems: [],
  isAuthenticated: false,
  vhhpUserType: null,
  company: '',
  primarySponsor: '',
  users: [],
  online: [],
  context: null,
};

//const initialState = dummy.user

const user = (state = initialState, action) => {
  switch (action.type) {
    case t.GET_VHHP_USER:
      return {
        ...state,
        userData: action.payload,
        agendaItems: action.payload?.agendaItems,
        vhhpUserType: action.payload?.vhhpUserType,
        company: action.payload?.profile?.company,
        primarySponsor: action.payload?.sponsor?.sponsorInviteEmail,
      };

    case 'SET_USER':
      return { ...state, userData: { ...state.userData, ...action.payload } };

    case t.GET_AUTH_STATUS:
      return {
        ...state,
        isAuthenticated: action.payload,
      };

    case t.GET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case t.ADD_ONLINE_USERS:
      console.log('payloa', action.payload);
      return {
        ...state,
        online: action.payload,
      };

    case t.ADD_ONLINE_USER:
      return {
        ...state,
        online: [...state.online, action.payload],
      };

    case t.REMOVE_ONLINE_USER:
      return {
        ...state,
        online: state.online.filter((user) => user.id !== action.payload),
      };

    case t.SET_CONTEXT:
      return {
        ...state,
        context: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default user;
