import React, { Fragment } from 'react';
import './UseDesktop.scss';
import VhhpButton from './VhhpButton';
import { Link } from 'react-router-dom';
import TopMenu from '../Menus/TopMenu';

const UseDesktop = () => {
  return (
    <Fragment>
      <TopMenu />
      <div className='use-desktop container--px hide-desktop show-mobile'>
        <div className='use-desktop__text h2 white'>
          For full experience, please use your laptop. This experience does not
          support mobile
        </div>

        <Link to='/' className='use-desktop__cta'>
          <VhhpButton
            text='Go back'
            styleOptions={{
              color: 'white',
              background: 'transparent',
              outline: 'white',
            }}
          ></VhhpButton>
        </Link>

        <div className='body3 white'>Need Help? Contact Us 800-123.4567</div>
      </div>
    </Fragment>
  );
};

export default UseDesktop;
