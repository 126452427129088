import React from "react";
import { getUserInitials, UserAvatarCollection, VhhpUser } from "../../VhhpUserService";
import Avatar, { AvatarSize } from "./Avatar";

const defaultAvatarBaseUrl = "/avatar/default/";
const defaultAvatarFiles = [
    "default-01.png",
    "default-02.png",
    "default-03.png",
    "default-04.png",
    "default-05.png",
    "default-06.png",
    "default-07.png",
    "default-08.png",
    "default-09.png",
    "default-10.png",
    "default-11.png",
    "default-12.png",
    "default-13.png",
    "default-14.png",
    "default-15.png",
    "default-16.png"
];

export type SelectedAvatar = {
    isInitialsAvatar: boolean;
    avatarUrl: string;
};


const getDefaultAvatars = (size: AvatarSize, wrapperClass: string, selectedAvatarUrl: string, selectedAvatarClass: string, clickHandler: (e: React.MouseEvent) => void) => {
    return defaultAvatarFiles.map((f, idx) => {
        const avatarUrl = defaultAvatarBaseUrl + f;
        const isSelected = selectedAvatarUrl && selectedAvatarUrl.endsWith(avatarUrl);
        return <Avatar key={"da" + idx.toString()} wrapperClass={`${wrapperClass} ${isSelected ? selectedAvatarClass : ""}`} size={size} source={defaultAvatarBaseUrl + f} onClickHandler={clickHandler} />;
    });
}

const getIntitialsAvatar = (size: AvatarSize, firstName: string, lastName: string, wrapperClass: string, isSelected: boolean, selectedAvatarClass: string, clickHandler: (e: React.MouseEvent) => void) => {
    
    return <Avatar key={"ia"} wrapperClass={`${wrapperClass} ${isSelected ? selectedAvatarClass : ""}`} size={size} source="UserInitials" firstName={firstName} lastName={lastName} onClickHandler={clickHandler} />;
}

const getUserAvatars = (avatarCollection: { avatarUrl: string }[], size: AvatarSize, wrapperClass: string, selectedAvatarUrl: string, selectedAvatarClass: string, clickHandler: (e: React.MouseEvent) => void) => {
    return avatarCollection.map((f, idx) => {
        const isSelected = selectedAvatarUrl && selectedAvatarUrl.endsWith(f.avatarUrl);
        return <Avatar key={"ua" + idx.toString()} wrapperClass={`${wrapperClass} ${isSelected ? selectedAvatarClass : ""}`} size={size}  source={f.avatarUrl} onClickHandler={clickHandler} />;
    })
}

export interface UserAvatarInfo {
    firstName: string; // comes from either the user's profile or from form as user registers
    lastName: string; // comes from either the user's profile or from form as user registers    
    avatars?: UserAvatarCollection; //a collection of urls for uploaded avatar images or from external logins
    useInitialsAvatar?: boolean; //comes form user profile, may not exist
    avatarUrl?: string; //comes form user profile, may not exist
}

export default function getAvatarCollectionForUser(userAvatarInfo: UserAvatarInfo, size: AvatarSize, wrapperClass: string, selectedAvatarClass: string, clickHandler: (e: React.MouseEvent) => void, selectedAvatar?: SelectedAvatar) {
    let selectedAvatarUrl = "";
    let isInitialsAvatarSelected: boolean;
    const userInitials = getUserInitials(userAvatarInfo.firstName, userAvatarInfo.lastName);

    if (selectedAvatar) {
        selectedAvatarUrl = selectedAvatar.avatarUrl;
        isInitialsAvatarSelected = selectedAvatar.isInitialsAvatar;
    } else {
        selectedAvatarUrl = userAvatarInfo?.avatarUrl || "";
        isInitialsAvatarSelected = selectedAvatarUrl === "" || userAvatarInfo?.useInitialsAvatar || false;
    }

    let avatars = [];
    avatars.push(getIntitialsAvatar(size, userAvatarInfo.firstName, userAvatarInfo.lastName, wrapperClass, isInitialsAvatarSelected, selectedAvatarClass, clickHandler));
    avatars = avatars.concat(getUserAvatars(userAvatarInfo.avatars || [], size, wrapperClass, selectedAvatarUrl, selectedAvatarClass, clickHandler)).concat(getDefaultAvatars(size, wrapperClass, selectedAvatarUrl, selectedAvatarClass, clickHandler));

    return avatars;
}