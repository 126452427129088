import authService from "./components/api-authorization/AuthorizeService";

export enum EventType {
    OnDemand = 0,
    LiveStream = 1,
    VirtualExperience = 2
}
export type EventDay = 26 | 27;
export interface VhhpEvent {
    day: EventDay;
    description: string;
    end: string; //ex: 8:00 PM
    endDateTime: string; // "2020-10-27T20:00:00.000-04:00"
    guestSpeakers: { name: string; title: string, imgUrl: string, vhhpEventSpeakerID?: number, thumbSrc?: string }[];
    title: string;
    type: EventType;
    vhhpEventID: number;
    startDateTime: string; // "2020-10-27T18:00:00.000-04:00"
    sortOrder: number;
    start: string; //ex: 6:00 PM 

    hideTime?: boolean;
    virtual?: boolean;
    inPerson?: boolean;
    extraList?: { title: string, description: string }[];
}

class AgendaService {
    _authService = authService;
    private _apiServerPrefix = process.env.REACT_APP_VHHP_API || 'https://identity.habitatnycwc.org/'
    async getEvents() {
        try {
            const response = await fetch(this._apiServerPrefix+"api/Events");
            const data = await response.json();
            console.log(data)
            return (data as VhhpEvent[]);
        } catch (e) {
            console.log("getEvents error:", e);
            throw (e);
        }
    }
}

let agendaService = new AgendaService();
export default agendaService;