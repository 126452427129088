import React, { Component } from "react";
import { SpeakerImageConfig } from "./SpeakerImagesConfig";
import './SpeakerImagesConfig.css';
import './SpeakerImage.css';



interface SpeakerImageProps {
    config: SpeakerImageConfig;
    openModal: (config: SpeakerImageConfig) => void;
}
interface SpeakerImageState {
    
}

export default class SpeakerImage extends Component<SpeakerImageProps, SpeakerImageState> {

    siWrapper = React.createRef<HTMLDivElement>();
    constructor(props: SpeakerImageProps) {
        super(props);
        
        this.state = {            
            scaleFactor: 1
        }
    }
    
    render(preventDragEventBubbling: boolean = true) {
        const handleOnDragStart = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();
        let p = this.props.config;
        return (<>
        <div className="si-container" onDragStart={preventDragEventBubbling ? handleOnDragStart : undefined}
        onClick={() => this.props.openModal(p)}
        >
        <div className={`si-speaker`}>
            <img src={p.headshotSrc} alt={p.name} />
        </div>
        <div className={`si-placard`} style={{ cursor: 'pointer' }} >
            <div className="si-placard-text-wrapper">
                <div className={`si-placard__name ${p.classNamePrefix}name`}>{p.name}</div>
                <div className={`si-placard__title ${p.classNamePrefix}title`}>{p.title}</div>
            </div>
        </div>
        </div>
        </>)
        return (
            <>
                <div className="si-container" onDragStart={preventDragEventBubbling ? handleOnDragStart : undefined}>
                    <div ref={this.siWrapper} className={`si-wrapper ${p.classNamePrefix}wrapper ${this.props.config.bio && "si-wrapper--has-modal"}`}  onClick={() => this.props.openModal(p)}>
                        <div className={`si-bg-vector`}>
                            {/*<img src={p.vectorSrc} alt="" />*/}
                            <div className={`si-speaker`}>
                                <img src={p.headshotSrc} alt={p.name} />
                            </div>
                            <div className={`si-placard ${p.classNamePrefix}placard`}>
                                <div className="si-placard-text-wrapper">
                                    <div className={`si-placard__name ${p.classNamePrefix}name`}>{p.name}</div>
                                    <div className={`si-placard__title ${p.classNamePrefix}title`}>{p.title}</div>
                                </div>
                            </div>
                            {/*<div className={`si-type`}>{p.type}</div>*/}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}