import './ModalWrapper.scss';
import { useEffect, useRef } from 'react';

import React from 'react';

const ModalWrapper = ({ setOpen, componentToPassDown, useClose }) => {
  const node = useRef();
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
    // eslint-disable-next-line
  }, []);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen();
  };

  return (
    <div className='modal'>
      <div className='modal__content' ref={node}>
        {componentToPassDown}
        {/* <div className='modal__close' onClick={(e) => setOpen()}>
          <img src={require('./img/cancel.svg')} alt='' />
        </div> */}
      </div>
    </div>
  );
};

export default ModalWrapper;
