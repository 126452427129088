import React, { useEffect, useState, useRef } from 'react'
import io from 'socket.io-client'
import { connect } from 'react-redux'
import axios from 'axios'
import ScrollToBottom from 'react-scroll-to-bottom'

import './RoomChat.scss'
import Guard from './Guard'

const ENDPOINT = process.env.REACT_APP_CHAT || 'http://localhost:3000/'

let socket

const RoomChat = ({ user, users, online, ...props }) => {
  const [name, setName] = useState('')
  const [room, setRoom] = useState('')
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [messagesEnd, setMessagesEnd] = useState(null)
  const _chatScroll = useRef(null)

  const scrollToBottom = () => {
    // console.log("scroll to bottom triggered")
    // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    // console.log(`message end`)
    // console.log(_chatScroll)
    // console.log(messagesEnd)
    // console.log(messagesEnd?.scrollTop)
    // console.log(messagesEnd?.scrollHeight)
    if (_chatScroll.current)
    {
      // console.log(`message end is defined`)
      // console.log(messagesEnd)
      // console.log(messagesEnd?.scrollTop)
      // console.log(messagesEnd?.scrollHeight)
      _chatScroll.current.scrollTop = _chatScroll.current?.scrollHeight
    }
  }

  useEffect(() => {
    // console.log("room chat loaded")
    if (!user) return
    // console.log(_chatScroll)
    // console.log(_chatScroll.current.scrollTop)
    // setMessagesEnd(_chatScroll.current)
    const name = `${user?.profile?.firstName} ${user?.profile?.lastName}`.trim()
    const room = props.context

    setRoom(room)
    setName(name)

    socket = io(ENDPOINT,{
      //transports: ['polling']
      transports: ['websocket']
    })
    console.log(socket)

    // Join a room for roomchat
    socket.emit('join', { name, room }, (error) => {
      if (error) {
        alert(error)
      }
    })

    // retrieve live messages
    socket.on('message', (message) => {
      //   console.log(message)
      setMessages((messages) => [...messages, message])
      scrollToBottom()
    })
    scrollToBottom()
    // close the connection when the component unmounts.
    return () => socket.disconnect()
  }, [user])

  useEffect(() => {
    // Retrieving room chat messages from mongoDB
    // v3weasffrwderwer_sheepshead bay
    console.log(`${ENDPOINT}roomChat/${props.context}`)
    console.log(props.context)
    axios
      .get(`${ENDPOINT}roomChat/${props.context}`)
      .then((res) => {
        console.log(props.context)
        console.log(res.data.response)
        setMessages((messages) => [...messages, ...res.data.response])
        scrollToBottom()
      })
      .catch((e) => {
        // console.log(e.response.data)
      })
  }, [])

  useEffect(() => {
    // console.log(user)
    // console.log(users)
    // console.log(online)
  }, [user, users, online])

  const sendMessage = (event) => {
    event.preventDefault()
    console.log(messages)
    const input = Guard(message)
    if (input) {
      Guard(message)

      const imageErrorSrc = '/avatar/default/default-05.png'
      const avatar = user?.profile?.avatarUrl || imageErrorSrc

      socket.emit(
        'sendMessage',
        {
          message: input,
          id: user.id,
          name: `${user?.profile?.firstName} ${user?.profile?.lastName}`.trim(),
          avatar: avatar,
          room: props.context,
        },
        () => setMessage('')
      )
    }
  }
  
  const getCurrentProfileOnline= (userID)=>{
    if(users && users.length > 0 ) //if users exist
    {
      let matchUsers=users.find(eachUser=> eachUser.vhhpUserID === userID)
      return matchUsers?.profile
    }
    else{
      return undefined
    }
  }


  const getAvatar = (imgLink,id) => {
    const imageErrorSrc = '/avatar/default/default-05.png'
  
    //try to grab current avatar from users list, not chat message
    let avatarURL=imgLink
    let userProfile=getCurrentProfileOnline(id)
    if(!userProfile)
    {
      avatarURL=imgLink //revert back to one in chat message
    }
    else{
      if(userProfile?.avatarUrl)
      {
        avatarURL=userProfile.avatarUrl
      }
      else
      {
        avatarURL=imgLink
      }
    }

    const avatar = (user && <img src={avatarURL} alt="" />) || (
      <img src={imageErrorSrc} alt="" />
    )
    return avatar
  }

  const getFullName = (nameString,id) => {
    //try to grab current avatar from users list, not chat message
    let fullName=nameString
    let userProfile=getCurrentProfileOnline(id)
    if(!userProfile)
    {
      fullName=nameString //revert back to one in chat message
    }
    else{
      if(userProfile?.firstName && userProfile?.lastName )
      {
        fullName=userProfile.firstName+" "+userProfile.lastName
      }
      else
      {
        fullName=nameString
      }
    }
    return fullName
  }

  const getTimeAMPMFormat = (date) => {
    const dateObj = new Date(date)
    if (dateObj) {
      let hours = dateObj.getHours()
      let minutes = dateObj.getMinutes()
      const ampm = hours >= 12 ? 'PM' : 'AM'
      hours = hours % 12
      hours = hours ? hours : 12 // the hour '0' should be '12'
      hours = hours < 10 ? '0' + hours : hours
      // appending zero in the start if hours less than 10
      minutes = minutes < 10 ? '0' + minutes : minutes
      return hours + ':' + minutes + ' ' + ampm
    }
    return ''
  }

  return (
    <>
      {user && (
        <>
          <div
            className="chatbox scroll"
            ref={_chatScroll}
          >
            {/* <div className="chat-filler label--primary body3">
          Welcome to live chat! Send a message to everyone.
        </div> */}
            {messages.length > 0 ? (
              <>
                <ScrollToBottom
                // ! Not working
                >
                  {messages.map((el, idx) => (
                    <div key={`chat-message-unit-${idx}`} className="chat-message">
                      <div className="thumbnail">{getAvatar(el.avatar,el.id)}</div>
                      <div className="content">
                        <div className="name label--primary">{getFullName(el.name,el.id)}</div>
                        <div className="message body2">{el.text}</div>
                      </div>
                      <div className="time label--secondary">
                        {getTimeAMPMFormat(el.date)}
                      </div>
                    </div>
                  ))}
                </ScrollToBottom>
              </>
            ) : (
              <div className="chat-filler label--primary body3">
                Welcome to live chat! Send a message to everyone.
              </div>
            )}
          </div>

          <div className="typer">
            <form
              className="messageForm"
              //  onSubmit={this.handleNewMessage}
            >
              <div className="input-container">
                <input
                  className="body2"
                  //   ref={(node) => (this.input = node)}
                  placeholder="Type a message..."
                  value={message}
                  onChange={({ target: { value } }) => setMessage(value)}
                  onKeyPress={(event) =>
                    event.key === 'Enter' ? sendMessage(event) : null
                  }
                />
              </div>
            </form>
          </div>
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user?.userData,
    users: state.user?.users,
    online: state.user?.online,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomChat)
