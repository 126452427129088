import './FooterMenu.scss'
import React, { Component } from 'react'
import VectorLogo from '../VectorLogo/VectorLogo'
import DownloadLink from '../Elements/DownloadLink'
import { Link } from 'react-router-dom'

export default class FooterMenu extends Component {
  render() {
    return (
      <>
        <div className="footer-container">
          <div className="footer-container_row-1">
            <div className="footer-container_row-1_col-logo">
              <a href="https://habitatnyc.org">
                <VectorLogo
                  withUrl={false}
                  logo="hhnyc-hz-white"
                  height="72px"
                />
              </a>
              <div className="body2 white" style={{ marginTop: '20px' }}>
                111 John St 23rd floor&nbsp;
                <br className="footer-adddress-break" />
                NY, NY 10038
              </div>
            </div>

            <div className="footer-container_row-1_col-customer">
              <div className="body2 white">
                <div className="footer-title label--secondary white">
                  Customer Service
                </div>
                <Link to="/faq" className="footer-link block external-link">
                  FAQ
                </Link>

                {/*<div className="customer-number">+1 (212) 991-4000 </div>*/}
                <a
                  className="footer-link website"
                  href="mailto:events@habitatnyc.org"
                >
                  events@habitatnyc.org
                </a>
                {/*<div className="footer_download">
                  <DownloadLink />
                </div>*/}
              </div>
            </div>

            <div className="footer-container_row-1_col-support">
              <div className="footer-title label--secondary white">Support</div>
              <a
                className="footer-link block"
                href="https://community.habitatnyc.org/donate"
              >
                Donate one time
              </a>
              <a
                className="footer-link block"
                href="https://community.habitatnyc.org/donate"
              >
                Donate monthly
              </a>
              <a
                className="footer-link block"
                href="https://habitatnyc.org/partnerships/"
              >
                Partnerships
              </a>
              <a
                className="footer-link block"
                href="https://habitatnyc.org/restore/restore-donate/"
              >
                Donate Goods
              </a>
            </div>

            <div className="footer-container_row-1_col-contact">
              <div className="footer-title label--secondary white">
                Contact Us
              </div>
              <a
                className="footer-link block"
                href="https://habitatnyc.org/news-media/"
              >
                Newsroom
              </a>
              <a
                className="footer-link block"
                href="https://habitatnyc.org/careers/"
              >
                Careers
              </a>
              <a
                className="footer-link block"
                href="https://community.habitatnyc.org/PrivacyPolicy"
              >
                Privacy Policy
              </a>
              <a className="footer-link block" href="/terms">
                Terms of service
              </a>
            </div>

            <div className="footer-container_row-1_col-social">
              <div className="footer-title label--secondary white">
                Social Media
              </div>
              <a
                className="footer-link block external-link"
                rel="noopener noreferrer"
                target="_blank"
                href="http://instagram.com/habitatnyc"
              >
                Instagram
              </a>
              <a
                className="footer-link block external-link"
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.facebook.com/Habitat.for.Humanity.NYC"
              >
                Facebook
              </a>
              <a
                className="footer-link block external-link"
                rel="noopener noreferrer"
                target="_blank"
                href="http://www.linkedin.com/company/133912"
              >
                Linkedin
              </a>
            </div>
          </div>

          <div className="footer-container_row-2">
            <div className="footer-container_row-2_col-empty"></div>
            <div className="footer-container_row-2_col-copyright">
              <div className="body3 white">
                Copyright © {new Date().getFullYear()} Habitat for Humanity. All rights reserved /{' '}
                <a
                  className="footer-link"
                  href="https://community.habitatnyc.org/PrivacyPolicy"
                >
                  Privacy Policy /
                </a>
                <a className="footer-link" href="/terms">
                  Terms of Service
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="help">Need Help?</div>
      </>
    )
  }
}
