import React, { Component } from 'react'
import { connect } from 'react-redux'

import s from '../duringHome/DuringHome.module.css'
import TopMenu from '../Menus/TopMenu'
import FooterMenu from '../Menus/FooterMenu'
import { Link } from 'react-router-dom'
import VideoInfo from '../Elements/VideoInfo'
import SubMenu from '../Menus/SubMenu'
import SponsorMenu from '../Menus/SponsorMenu'

import Jitsi from './Jitsi'
import Youtube from './Youtube'
import Image from './Image'

import Chat from '../ChatUI/Chat'

import audioImg from '../../common/img/conference/mic.svg'
import videoImg from '../../common/img/conference/video.svg'
import inviteImg from '../../common/img/conference/add.svg'
import leaveImg from '../../common/img/conference/leave.svg'
import roomInfo from '../duringHome/sections/roomsSection/roomInfo.json'

import './Room.scss'
import Invite from './Invite'
import ModalWrapper from '../Elements/ModalWrapper'

import { Modal } from 'antd'
import { Redirect } from 'react-router'
import { presence } from '../../redux/actions/user'

const CONTEXT_PREFIX = process.env.REACT_APP_CHAT_CONTEXT_PREFIX

class Room extends Component {
  state = {
    room: this.props.match.params.conference_name,
    prefix: CONTEXT_PREFIX,

    audio: true,
    audioIcon: true,
    video: false,
    videoIcon: true,

    event: {},
    isOpen: false,

    maxedOut: false,
    presence: false,
  }

  componentDidMount() {
    this.setSponsorName()

    // this.getEvent();
    // setInterval(() => {
    //   this.getEvent();
    // }, 10000);
  }

  // getEvent() {
  //   return new Promise((resolve, reject) => {
  //     fetch(
  //       'https://d.virtualhabitathouseparty.org/api/v1/admin/conferenceRoom'
  //     )
  //       .then((response) => response.json())
  //       .then((res) => {
  //         const rooms = res?.data?.room;
  //         const room = rooms.find((room) => room.name == this.state.room);
  //         switch (room?.event) {
  //           case 'pre':
  //           case 'post':
  //           case 'live':
  //             this.state.event?.event != room?.event &&
  //               this.setState({ audio: true, video: true });
  //         }
  //         this.setState({ event: room });
  //         resolve(room);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         reject(err);
  //       });
  //   });
  // }

  toggleAudio = () => {
    this.setState((state) => ({
      audio: !state.audio,
    }))
  }

  toggleVideo = () => {
    this.setState((state) => ({
      video: !state.video,
    }))
  }

  audioMuteStatusChanged = (muted) => {
    if (this.state.audioIcon !== muted.muted) {
      this.setState((state) => ({
        audioIcon: muted.muted,
      }))
    }
  }

  videoMuteStatusChanged = (muted) => {
    if (this.state.videoIcon !== muted.muted) {
      this.setState((state) => ({
        videoIcon: muted.muted,
      }))
    }
  }

  jitsiJoined = () => {
    this.setState({
      audio: true,
      video: true,
    })
  }

  setSponsorName = () => {
    let sponsor = ''

    roomInfo.map((item) => {
      if (item.subRoomName === this.state.room) {
        sponsor = item.sponsorName
        console.error({ sponsor })
        console.log(item)
        this.setState({
          sponsor: sponsor,
          sponsorID: item.sponsorID,
        })
      }
    })
  }

  handleInviteClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  handleLeave = () => {
    if (this.props.user && !this.state.presence) {
      this.setState({ presence: true })
      this.props.user && this.props.presence('AllRooms')
    }
  }

  render() {
    let jitsi = false

    // console.log(this.props)
    // console.log(this.state)
    //console.log('helo pathname', this.state.pathname);

    let view = <div>Please Wait</div>

    const context = this.state.prefix + this.state.room

    //console.log('hello contexy ' + context);

    const online = this.props.online.filter((user) => user.context == context)

    //console.log(this.props.online);

    // if () {
    //   this.setState({
    //     maxedOut:
    //   })
    // }

    if (online.length > 30) {
      Modal.warning({
        title: 'Failed to Join',
        content:
          'The room currently meets the capacity limit of 30. Please choose another room.',
      })
      return <Redirect to="/#rooms" />
    }

    const rooms = this.props.events
    const event = rooms.find((room) => room.name == this.state.room)

    switch (event && event?.event) {
      case 'pre':
      case 'post':
        jitsi = true
        view = this.props.user && (
          <Jitsi
            room={`${event.event}-${this.state.room}`}
            audio={this.state.audio}
            video={this.state.video}
            audioMuteStatusChanged={this.audioMuteStatusChanged}
            videoMuteStatusChanged={this.videoMuteStatusChanged}
            joined={this.jitsiJoined}
          />
        )
        break
      case 'live':
        view = (
          <div
            style={{
              position: 'relative',
              height: '100%',
              width: '100%',
            }}
          >
            {this.props.user && (
              <Jitsi
                room={`${event.event}-${this.state.room}`}
                sponsorID={this.state.sponsorID}
                audio={this.state.audio}
                video={this.state.video}
                audioMuteStatusChanged={this.audioMuteStatusChanged}
                videoMuteStatusChanged={this.videoMuteStatusChanged}
                joined={this.jitsiJoined}
                filmStripOnly={true}
              />
            )}
            <Youtube
              style={{
                position: 'absolute',
                top: 0,
                width: '100%',
              }}
              link={event?.data}
            />
          </div>
        )

        break
      case 'image':
      case 'text':
        view = (
          <div
            style={{
              //position: 'absolute',
              //top: '0',
              //left: '0',
              //height: '100%',
              //width: '100%',
              position: 'relative',
              height: '100%',
            }}
          >
            <Image
              style={{
                position: 'absolute',
                top: 0,
                //width: '100%',
              }}
            />
          </div>
        )
      case 'redirect':
        break
    }

    return (
      <div
        className={`${s.container} ${
          this.state.sponsor === 'Habitat NYC'
            ? 'habitat-bg'
            : 'video-section-wrapper'
        }`}
      >
        <TopMenu theme="dark"></TopMenu>

        {this.state.isOpen && (
          <ModalWrapper
            setOpen={this.handleInviteClick}
            componentToPassDown={<Invite setOpen={this.handleInviteClick} />}
          />
        )}

        {/* <Invite show={false}/> */}

        <div className="container">
          <div className="" id="breadcrumbs">
            <SubMenu
              color="white"
              fromRoomDotJs
              sponsor={this.state.sponsor}
              // routeParam={
              //   this.props?.nowPlaying?.title && this.props.nowPlaying.title
              // }
            />
          </div>

          <div id="title-bar">
            <div id="title">
              <div className="sponsor-logo">
                <img
                  src={`/images/sponsors_jitsi/${this.state.sponsor}.svg`}
                ></img>
              </div>
              <div className="room body1">
                {this.state.room} •{' '}
                <span className="body2">
                  {online.length} People in the Room
                </span>
              </div>
            </div>
            <div id="actions">
              {jitsi && (
                <div
                  style={{ backgroundImage: `url(${audioImg})` }}
                  // className={`audio ${this.state.audioIcon && 'bg-1'}`}
                  className={!this.state.audio ? `audio ${this.state.audioIcon && 'bg-1-active'}`:`audio ${this.state.audioIcon && 'bg-1'}`}

                  onClick={this.toggleAudio}
                ></div>
              )}
              {
                <div
                  style={{ backgroundImage: `url(${videoImg})` }}
                  className={this.state.video ? `video ${this.state.videoIcon && 'bg-2-active'}`:`video ${this.state.videoIcon && 'bg-2'}`}
                  onClick={this.toggleVideo}
                ></div>
              }
              <div
                style={{ backgroundImage: `url(${inviteImg})` }}
                className="invite"
                onClick={this.handleInviteClick}
              ></div>
              <Link to="/#rooms">
                <div
                  style={{ backgroundImage: `url(${leaveImg})` }}
                  className="leave"
                  id="leave"
                  onClick={this.handleLeave}
                >
                <span className="tooltiptext body3">Leave room</span>
                </div>
              </Link>
            </div>
          </div>

          <section className="video-section">
            <div className="video-section__view">{view}</div>
            <div className="video-section__chat">
              <Chat conference={true} context={context} />
            </div>
          </section>
        </div>

        {/* <VideoInfo
          showUpcoming={false}
          theme={this.state.sponsor === 'Habitat NYC' ? 'light' : 'dark'}
          background={true}
        /> */}

        <SponsorMenu />

        <div className={s['sections-container']}>
          <footer>
            <FooterMenu />
          </footer>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user.userData,
  context: state.user?.context,
  nowPlaying: state.dashboard.nowPlaying,
  online: state.user?.online,
  events: state.dashboard?.events,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, { presence })(Room)
