import styled from 'styled-components';

const PrimaryTheme = {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    letterSpacing: "0.0025rem"
};
// const SecondaryTheme = {
//     fontWeight: 500,
//     fontSize: "0.6875rem",
//     lineHeight: "1rem",
//     letterSpacing: "0.04375rem"
// };

export const StyledLabel = styled.label`
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: ${props => props.theme.fontWeight};
    font-size: ${props => props.theme.fontSize};
    line-height: ${props => props.theme.lineHeight};
    letter-spacing: ${props => props.theme.letterSpacing};
    text-transform: uppercase;    
`;
StyledLabel.defaultProps = {
    theme: PrimaryTheme
};

export const StyledError = styled.div`
    font-family: MaisonNeue;
    font-style: normal;
    font-weight: ${props => props.theme.fontWeight};
    font-size: ${props => props.theme.fontSize};
    line-height: ${props => props.theme.lineHeight};
    letter-spacing: ${props => props.theme.letterSpacing};
    color: #EF473E;
    text-align: right;
    position: absolute;
    right: 0;
    display: inline-block;
`;

StyledError.defaultProps = {
    theme: PrimaryTheme
};

export const StyledInput = styled.input`
    width: 100%;
    padding: .875rem 1rem;
    font-family: MaisonNeue;
    line-height: 1.25rem;
    font-size: 1rem;
    border: 1px solid #E3E3E3;
    outline: none;
    box-sizing: border-box; 
    margin-bottom: 1.25rem;
    &:focus {
        box-shadow: 0px 0px 5px var(--blue-active-highlight);
        border: 2px solid var(--brand-blue-2);
    }
`;

export const StyledSelect = styled.select`
    width: 100%;
    padding: .875rem 1rem;
    font-family: MaisonNeue;
    line-height: 1.25rem;
    font-size: 1rem;
    border: 1px solid #E3E3E3;
    outline: none;
    box-sizing: border-box; 
    margin-bottom: 1.25rem;
    &:focus {
        box-shadow: 0px 0px 5px var(--blue-active-highlight);
        border: 2px solid var(--brand-blue-2);
    }
`;