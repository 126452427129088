import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';

import './Invite.scss';

const Invite = ({ setOpen }) => {
  const [copySuccess, setCopySuccess] = useState('');
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setCopySuccess('Copied!');
  }

  const { host, pathname, href } = window.location;
  const link = href;

  return (
    <div id='invite'>
      <div className='prompt'>
        <div className='top'>
          <h4 className='title'>Here's the link to this room</h4>
          <div className='close'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              focusable='false'
              class='XAUpld'
              onClick={(e) => setOpen()}
            >
              <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'></path>
              <path d='M0 0h24v24H0z' fill='none'></path>
            </svg>
          </div>
        </div>
        <div className='description'>
          Copy this link and send it to people you want to meet with. Be sure to
          save it so you can use it later, too.
        </div>
        <div className='link-holder'>
          <span className='link'>{link}</span>
          <div className='copy' onClick={copyToClipboard}>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              focusable='false'
              class='Hdh4hc cIGbvc NMm5M'
            >
              <path d='M18 21H4V7H2v14c0 1.1.9 2 2 2h14v-2zm3-4V3c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2zm-2 0H8V3h11v14z'></path>
            </svg>
          </div>
        </div>
      </div>
      <form>
        <textarea ref={textAreaRef} value={link} />
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
