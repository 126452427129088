import React from "react";
import { string } from "yup";
import authService from "./components/api-authorization/AuthorizeService";
import Avatar, { AvatarSize } from "./components/Elements/Avatar";
import { AvatarSource } from "./components/Elements/AvatarField";
import {getVhhpUser} from './redux/actions/user'
/*
A wrapper for authService that provides CRUD for VhhpUser entity
*/
interface AuthServiceUser {
    // has lots more fields than this, but these are the ones we care about
    // both values uniquely identify the user
    // sid is the VhhpUser's Id field on the server
    // name is the VhhpUser's UserName field on the server

    access_token: string;
    profile: {
        sid: string;
        name: string;
    }
}
export enum VhhpUserType {
    GeneralPublic,
    InvitedGuest,
    Sponsor
}

export type UserAvatarCollection = {
    avatarUrl: string;
}[];

export interface VhhpUser {
    id: string;
    userName: string;
    email: string;
    onSponsorWaitingList: boolean;
    registrationDate: Date;
    vhhpUserType: VhhpUserType;
    getDisplayName: () => string;
    getAvatar: (size: AvatarSize, wrapperClass?: string) => JSX.Element;
    getAvatarUrlForWatchParty: () => string;
    sponsorID?: number;
    sponsor?: Sponsor;
    userProfileID?: number;
    profile?: UserProfile;
    agendaItems?: UserAgendaItem[];
    avatars?: UserAvatarCollection;
    invitedUser: {
        id: number;
        invitationCode: string;
        quantity: number;
        role: number;
        email: string;
    };
}

export interface Sponsor {
    name: string;
    guestCode: string;
    guestPassQuantity: number;

}

export interface UserProfile {
    userProfileID: number;
    firstName: string;
    lastName: string;
    jobTitle: string;
    companyName: string;
    industry: string;
    location: string;
    sponsorCompanyEmployee?: boolean;
    useInitialsAvatar?: boolean;
    avatarUrl: string
    userMailingAddress: string;
    userUnitNo: string;
    userCity: string;
    userState: string;
    userZipCode: string;
    emailAddress: string;
}
export interface SponsorGuest {
    vhhpUserID: string;
    email: string;
    registrationDate: Date;
    onSponsorWaitList: boolean;
}
export interface UserAgendaItem {
    id: string;
    vhhpEventID: number;
}

class VhhpUserService {
    private _authService = authService;
    private _vhhpUser: VhhpUser | undefined = undefined;
    private _apiServerPrefix = process.env.REACT_APP_VHHP_API || 'https://identity.habitatnycwc.org/'

    async GetSponsorGuestsBySponsorID() {
        // console.log("get guests called")
        try {
            if (this._authService.isAuthenticated()) {
                if (!this._vhhpUser) {
                    await this.getVhhpUser();
                }
                const token = await this._authService.getAccessToken();
                //in the new one, always use PUT
                const method = "GET";
                const url = this._apiServerPrefix+`api/UserProfiles/guestlist`
                // if (method === "PUT")
                    // userProfile.userProfileID = (this._vhhpUser as VhhpUser).userProfileID;

                const response = await fetch(url, {
                    credentials: 'include',
                    mode: 'cors',
                    headers: !token
                        ? { 'Content-Type': 'application/json' }
                        : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                    method: method,
                    // body: JSON.stringify({
                    //     code: code
                    // })
                })
                .then(r => r.json())
                .then(r => r)

                // console.log(response)
                return response
                // update user 
                // console.log('code successful, update user data in redux')
                // await this.getVhhpUser();
                // const data = await this.getVhhpUser()
                // console.log(data)
                // if(data?.vhhpUserType === VhhpUserType.InvitedGuest || data?.vhhpUserType === VhhpUserType.Sponsor)
                // {
                //     return true
                // }
                // return false
                // return response.ok;
            } else {
                // console.log('not authenticated')
                //not authenticated
                this._vhhpUser = undefined;
                return undefined;
            }
        } catch (e) {
            console.log("getVhhpUser error:", e);
            this._vhhpUser = undefined;
            return undefined;
        }
        /**
        try {
            if (this._authService.isAuthenticated()) {
                console.log('is authenticated')

                const token = await this._authService.getAccessToken();
                // const response = await fetch(this._apiServerPrefix+`api/Sponsors/Users/${sponsorID}`, {
                //     credentials: 'include',
                //     mode: 'cors',
                //     headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                // });
                const response = await fetch(this._apiServerPrefix+`api/UserProfiles/guestlist`, {
                    credentials: 'include',
                    mode: 'cors',
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });
                console.log(response)
                const data: SponsorGuest[] = await response.json();
                data.forEach(guest => guest.registrationDate = new Date(guest.registrationDate));
                return data;
            } else {
                console.log('not authenticated')
                //not authenticated
                this._vhhpUser = undefined;
                return undefined;
            }
        } catch (e) {
            console.log("getVhhpUser error:", e);
            this._vhhpUser = undefined;
            return undefined;
        }
        */

    }
    async ToggleGuestWaitingListStatus(sponsorID: number, vhhpUserID: string) {
        try {
            if (this._authService.isAuthenticated()) {

                const token = await this._authService.getAccessToken();
                const response = await fetch(this._apiServerPrefix+`api/Sponsors/Users/${sponsorID}/${vhhpUserID}`, {
                    credentials: 'include',
                    mode: 'cors',
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                    method: "DELETE"
                });
                return response.ok;
            } else {
                //not authenticated
                this._vhhpUser = undefined;
                return undefined;
            }
        } catch (e) {
            console.log("getVhhpUser error:", e);
            this._vhhpUser = undefined;
            return undefined;
        }
    }
    async getVhhpUser(forceRefresh?: boolean) {
        try {
            //return  {"userProfileID":null,"sponsorID":null,"vhhpUserType":0,"registrationDate":"2021-04-03T10:16:28.9368913","invitedUser":null,"profile":null,"agendaItems":[],"avatars":[],"id":"063ff346-190d-41d7-9a6a-17700ead1f87","userName":"spanote@gmail.com","normalizedUserName":"SPANOTE@GMAIL.COM","email":"spanote@gmail.com","normalizedEmail":"SPANOTE@GMAIL.COM","emailConfirmed":false,"passwordHash":"AQAAAAEAACcQAAAAEL5VIA9y/KnCwxlzAhzpYkQ9U73udgOnZuKQl9BVV/JOKaEqk05erD9K950lap9THg==","securityStamp":"BDGCIGJ7M5OTWMFNL56NZ5QHV6PSIZUO","concurrencyStamp":"fcc635a5-3066-4ec6-816b-2327fc1a792e","phoneNumber":null,"phoneNumberConfirmed":false,"twoFactorEnabled":false,"lockoutEnd":null,"lockoutEnabled":true,"accessFailedCount":0}

            if (this._authService.isAuthenticated()) {
                if (this._vhhpUser && !forceRefresh)
                    return this._vhhpUser;

                const token = await this._authService.getAccessToken();
                const response = await fetch(this._apiServerPrefix+"api/MyUserData",
                { 
                    credentials: 'include',
                    mode: 'cors',
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });
                if (response.ok) {
                    const data: VhhpUser = await response.json();
                    this.addFunctionsToVhhpUser(data);

                    this._vhhpUser = data;
                    return data;
                } else {
                    //error retrieving vhhpUser
                    this._vhhpUser = undefined;
                    console.log("Unable to retrieve VhhpUser. Server Response: " + response.type);
                    return undefined;
                }
            } else {
                //not authenticated
                this._vhhpUser = undefined;
                return undefined;
            }
        } catch (e) {
            console.log("getVhhpUser error:", e);
            this._vhhpUser = undefined;
            return undefined;
        }
    }
    addFunctionsToVhhpUser(user: VhhpUser) {
        const thisVhhpUserService = this;
        user.getDisplayName = function () {
            if (this.profile && (this.profile.firstName || this.profile.lastName)) {
                return `${this.profile.firstName} ${this.profile.lastName}`.trim();
            } else {
                return this.email.toLowerCase();
            }
        };
        user.getAvatar = function (size: AvatarSize, wrapperClass?: string) {
            return thisVhhpUserService.getAvatarForUser(this, size, wrapperClass);
        }
        user.getAvatarUrlForWatchParty = function () {
            //an empty string will result in jitsi/twilio equivalent of an initials avatar
            if (!this.profile) {
                return "";
            } else if (this.profile.useInitialsAvatar) {
                return "";
            } else if (this.profile.avatarUrl) {
                if (this.profile.avatarUrl.startsWith("/")) {
                    return window.location.origin + this.profile.avatarUrl;
                }
                return this.profile.avatarUrl;
            }
            return "";
        }
    }
    async getAvatarForCurrentUser(size: AvatarSize, wrapperClass?: string) {
        // todo: reconcile with ProfileAvatar logic for firsttime users
        const user = await this.getVhhpUser();
        if (user) {
            return this.getAvatarForUser(user, size, wrapperClass);
        }
    }
    getAvatarForUser(user: VhhpUser, size: AvatarSize, wrapperClass?: string) {
        const source: AvatarSource = user.profile?.useInitialsAvatar === true
            ? "UserInitials"
            : user.profile?.avatarUrl
                ? user.profile.avatarUrl
                : "";

        return this.getAvatar(size, source, user.profile?.firstName, user.profile?.lastName, wrapperClass);
    }
    getAvatar(size: AvatarSize, source: AvatarSource, firstName?: string, lastName?: string, wrapperClass?: string) {
        return <Avatar wrapperClass={wrapperClass} size={size} source={source} firstName={firstName} lastName={lastName} />
    }
    async saveUserProfile(userProfile: Partial<UserProfile>) {
        try {
            if (this._authService.isAuthenticated()) {
                if (!this._vhhpUser) {
                    await this.getVhhpUser();
                }
                const token = await this._authService.getAccessToken();
                //in the new one, always use PUT
                const method = "PUT";
                const url = this._apiServerPrefix+"api/UserProfiles"
                if (method === "PUT")
                {
                    // console.log(userProfile)
                    userProfile.userProfileID = (this._vhhpUser as VhhpUser).userProfileID;
                }
                console.log("user profile ",userProfile)
                const response = await fetch(url, {
                    credentials: 'include',
                    mode: 'cors',
                    headers: !token
                        ? { 'Content-Type': 'application/json' }
                        : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                    method: method,
                    body: JSON.stringify(userProfile)
                });
                // update user 
                console.log(response)
                await this.getVhhpUser();
                return response.ok;
            } else {
                //not authenticated
                this._vhhpUser = undefined;
                return false;
            }
        } catch (e) {
            console.log("getVhhpUser error:", e);
            this._vhhpUser = undefined;
            return false;
        }
    }

    async updateUserInvitationCode(code: string) {
        try {
            if (this._authService.isAuthenticated()) {
                if (!this._vhhpUser) {
                    await this.getVhhpUser();
                }
                const token = await this._authService.getAccessToken();
                //in the new one, always use PUT
                const method = "PUT";
                const url = this._apiServerPrefix+`api/UserProfiles/updaterole?code=${code}`
                // if (method === "PUT")
                    // userProfile.userProfileID = (this._vhhpUser as VhhpUser).userProfileID;

                const response = await fetch(url, {
                    credentials: 'include',
                    mode: 'cors',
                    headers: !token
                        ? { 'Content-Type': 'application/json' }
                        : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                    method: method,
                    // body: JSON.stringify({
                    //     code: code
                    // })
                });
                // console.log(response)
                // update user 
                // console.log('code successful, update user data in redux')
                // await this.getVhhpUser();
                const data = await this.getVhhpUser()
                // console.log(data)
                // if(data?.vhhpUserType === VhhpUserType.InvitedGuest || data?.vhhpUserType === VhhpUserType.Sponsor)
                // {
                //     return true
                // }
                // return false
                return response.ok;
            } else {
                //not authenticated
                this._vhhpUser = undefined;
                return false;
            }
        } catch (e) {
            // console.log("update invitation code error:", e);
            this._vhhpUser = undefined;
            return false;
        }
    }

    async removeUserAgendaItem(userAgendaItem: UserAgendaItem) {
        try {
            if (this._authService.isAuthenticated()) {
                if (!this._vhhpUser) {
                    await this.getVhhpUser();
                }
                const token = await this._authService.getAccessToken();
                const method = "DELETE";
                const url = this._apiServerPrefix+`api/UserAgendaItems/${userAgendaItem.id}/${userAgendaItem.vhhpEventID}`;

                const response = await fetch(url, {
                    credentials: 'include',
                    mode: 'cors',
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                    method: method
                });
                if (response.ok) {
                    //remove the agenda item locally
                    (this._vhhpUser as VhhpUser).agendaItems = this._vhhpUser?.agendaItems?.filter(i => i.vhhpEventID !== userAgendaItem.vhhpEventID);
                }
                return response.ok;
            } else {
                //not authenticated
                this._vhhpUser = undefined;
                return false;
            }
        } catch (e) {
            console.log("getVhhpUser error:", e);
            this._vhhpUser = undefined;
            return false;
        }
    }
    async addUserAgendaItem(userAgendaItem: Pick<UserAgendaItem, "id" | "vhhpEventID">) {
        try {
            if (this._authService.isAuthenticated()) {
                if (!this._vhhpUser) {
                    await this.getVhhpUser();
                }
                const token = await this._authService.getAccessToken();
                const method = "POST";
                const url = this._apiServerPrefix+"api/UserAgendaItems";
                const response = await fetch(url, {
                    credentials: 'include',
                    mode: 'cors',
                    headers: !token
                        ? { 'Content-Type': 'application/json' }
                        : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                    method: method,
                    body: JSON.stringify(userAgendaItem)
                });
                // console.log(response)
                if (response.ok) {
                    // console.log(`the event is added`)
                    const data: UserAgendaItem = await response.json();
                    if(data.id && data.vhhpEventID === 0)
                    {
                        const dto = { id: data.id, vhhpEventID: data.vhhpEventID };
                        if (this._vhhpUser) {
                            //should definitely be true thanks to previous check
                            if (!this._vhhpUser.agendaItems) {
                                this._vhhpUser.agendaItems = [];
                            }
                            this._vhhpUser.agendaItems.push(dto);
                            return dto;
                        }
                    }
                    if (data.id && data.vhhpEventID) {
                        const dto = { id: data.id, vhhpEventID: data.vhhpEventID };
                        if (this._vhhpUser) {
                            //should definitely be true thanks to previous check
                            if (!this._vhhpUser.agendaItems) {
                                this._vhhpUser.agendaItems = [];
                            }
                            this._vhhpUser.agendaItems.push(dto);
                            return dto;
                        }
                    }
                }
                return undefined;

            }
            else {
                //not authenticated
                this._vhhpUser = undefined;
                return undefined;
            }
        } catch (e) {
            console.log("getVhhpUser error:", e);
            this._vhhpUser = undefined;
            return false;
        }
    }
}

const vhhpUserService = new VhhpUserService();
export default vhhpUserService;

export const getUserInitials = (firstName?: string, lastName?: string) => {
    return ((firstName || "") + " " + (lastName || "")).trim();
}
