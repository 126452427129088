import React from 'react';

const SingleVideoSpeakers = ({ speaker }) => {
  return (
    <div className='single-video__speaker'>
      <div className='single-video__speaker-image'>
        <img src={`/images/headshots/${speaker.image}.png`} alt='' />
      </div>

      <div className='single-video__about-speaker'>
        <div className='single-video__speaker-name h3 white'>
          {speaker.name}
        </div>
        <div className='label--primary white'>{speaker.role}</div>
      </div>
    </div>
  );
};

export default SingleVideoSpeakers;
