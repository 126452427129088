import './common/common.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
// import { unregister as unRegsiterServiceWorker } from './registerServiceWorker';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';

// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || undefined;
const baseUrl = "/";

const rootElement = document.getElementById('root');

// Sentry.init({
//   dsn: "https://51b5c9bfe5bb41719c7e164eadcd1d02@o465742.ingest.sentry.io/5495342",
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
rootElement);


//Service Worker was used to fetch updated resources in the background, but not much point with file version hashes
//may work better after asp.net core is configured to not cache index.html. will need to test.
//registerServiceWorker();

// unRegsiterServiceWorker();
