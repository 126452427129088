import "./PublicAgendaView.css";
import React, { Component } from 'react';
import agendaService, { EventDay, EventType, VhhpEvent } from "../../AgendaService";
import { sortAndDeduplicateDiagnostics } from "typescript";
import agendaJson from './vhhpEvents.json';



// type AgendaItemType = "od" | "ls";
// interface AgendaItem {
//     start: Date;
//     end: Date | undefined;
//     type: AgendaItemType;
//     title: string;
//     dsc: string;
//     order: number;
//     group: number;
// }

/*
The PublicAgendaView makes a few simplifing assumptions about the data
group 1 is mon 10/26 and is all on demand video
group 2 is tue 10/27 and is all livestream video from 6-8pm
*/

interface PublicAgendaViewProps {
    view: "mon" | "tue" | "both";
    headerSelection: "left-od" | "right-ls" | undefined;
}

interface PublicAgendaViewState {
    loading: boolean;
    data: VhhpEvent[];
    headerSelection: "left-od" | "right-ls" | undefined;
}


export default class PublicAgendaView extends Component<PublicAgendaViewProps, PublicAgendaViewState> {

    constructor(props: PublicAgendaViewProps) {
        super(props);

        this.state = { loading: false, 
                        data: ((agendaJson as unknown) as VhhpEvent[]), 
                        headerSelection: props.view === "both" ? "left-od" : undefined 
                    };

        this.handleTwinHeaderClick = this.handleTwinHeaderClick.bind(this);
        //this.loadData();
    }

    // async loadData() {
    //     const data = await agendaService.getEvents();          /  
    //     this.setState({ loading: false, data: data });
    // }

    singleHeader(day: string, numDay: string) {
        return (
            <div className="pav-header">
                <div className="pav-header_date-group">
                    <div className="pav-header_day label--primary">{day}</div>
                    <div className="pav-header_num-day h2">{numDay}</div>
                </div>
                {/* <div className="pav-header_type-tag label--primary">{typeTag}</div> */}
            </div>
        );
    }

    handleTwinHeaderClick(which: "left-od" | "right-ls") {
        if (this.state.headerSelection !== which)
            this.setState({ headerSelection: which });
    }

    twinHeader(day1: string, numDay1: string, day2: string, numDay2: string) {
        return (
            <div className="pav-header pav-header-twin">
                <div onClick={(e) => this.handleTwinHeaderClick("left-od")} className={`pav-header_date-group pav-header_twin-left${this.props.headerSelection === "right-ls" ? " pav-header_twin-unselected" : ""}`}>
                    <div className="pav-header_day label--primary active">{day1}</div>
                    <div className="pav-header_num-day h2">{numDay1}</div>
                </div>
                <div onClick={(e) => this.handleTwinHeaderClick("right-ls")} className={`pav-header_date-group pav-header_twin-right${this.props.headerSelection === "left-od" ? " pav-header_twin-unselected" : ""}`}>
                    <div className="pav-header_day label--primary">{day2}</div>
                    <div className="pav-header_num-day h2">{numDay2}</div>
                </div>
            </div>
        );
    }
    
    getDisplayModifier(col: "left" | "right") {
        //controls which agenda items are visible when view is both/twinview        
        if (col === "left") {
            return this.props.headerSelection === "right-ls" ? " pav-agenda-item--hidden" : "";
        } else if (col === "right") {
            return this.props.headerSelection === "left-od" ? " pav-agenda-item--hidden" : "";
        }
        return "";
    }
    agendaItemNoTime(event: VhhpEvent, col: "left" | "right") {
        let displayModifier = this.getDisplayModifier(col);
        return (
            <div key={`${event.vhhpEventID}-${col}`} className={`pav-agenda-item pav-od-item${displayModifier}`}>
                <div className="pav-agenda-item_title-dsc-group">
                    <div className="pav-agenda-item_title h3 blue-3">{event.title}</div>
                    <div className="pav-agenda-item_dsc body2">{event.description}</div>
                </div>
            </div>
        );
    }
    agendaItemWithTime(event: VhhpEvent, col: "left" | "right") {
        //title: string, dsc: string, key: string | number, time?: string

        let displayModifier = this.getDisplayModifier(col);
        let time = <>{event.start}<br />|<br />{event.end}<br />EST</>;
        let extraList: JSX.Element[] = [];
        if (event.type === EventType.LiveStream && event.sortOrder > 2) {
            time = <></>;
        }
        if(event.hideTime)
        {
            time = <></>;
        }
        if(event.extraList)
        {
            event.extraList.forEach((l, idx) => {
                extraList.push(
                    <li key={`new-extra-list-${idx}`} className='padded-list' style={{ marginTop: '10px' }}>
                    <div className="pav-agenda-item_title h3 blue-3">{l.title}</div>
                    <div className="pav-agenda-item_dsc body2">{l.description}</div>
                    </li>)
            })
        }
        // console.log(`should hide time ${event.hideTime}`)
        // console.log(`this event is ${event.virtual ? 'virtual': 'not virtual'}`)
        return (
            <div key={`${event.vhhpEventID}-${col}`} className={`pav-agenda-item pav-ls-item${displayModifier}`}>
                <div className="pav-agenda-item_time label--secondary gray-1">{time}</div>
                <div className="pav-agenda-item_title-dsc-group">
                    <div className="pav-agenda-item_title h3 blue-3">{event.title}</div>
                    <div className="pav-agenda-item_dsc body2">{event.description}</div>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '20px'
                    }}>
                    {event?.virtual ? <div className="pav-agenda-item_tpye virtual">VIRTUAL</div>:null}
                    {event?.inPerson ? <div className="pav-agenda-item_tpye in-person">IN-PERSON</div>:null}
                    </div>
                    <ul>{extraList}</ul>                
                    </div>
            </div>
        );
    }
    agendaEventTypeHeader(eventType: EventType, col: "left" | "right") {
        let displayModifier = this.getDisplayModifier(col);
        let tag = "";
        switch (eventType) {
            // case EventType.LiveStream: tag = "Free Livestream"; break;
            // case EventType.OnDemand: tag = "Free On Demand Videos"; break;
            case EventType.VirtualExperience: tag = "All Access Pass Required"; break;
        }
        return <div></div>
        // return <div key={eventType.toString() + col} className={`pav-header_type-tag label--primary${displayModifier}`}>{tag}</div>
    }

    getMonAgendaItems() {
        let agendaItems: JSX.Element[] = [];
        agendaItems.push(this.agendaEventTypeHeader(EventType.VirtualExperience, "left"));
        agendaItems = agendaItems.concat(this.state.data
            .filter(e => e.day === 26 && e.type === EventType.VirtualExperience)
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((event, idx) => this.agendaItemWithTime(event, "left")));
        // agendaItems.push(this.agendaEventTypeHeader(EventType.OnDemand, "left"));
        agendaItems = agendaItems.concat(this.state.data
            .filter(e => e.day === 26 && e.type === EventType.OnDemand)
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((event, idx) => this.agendaItemNoTime(event, "left")));
        return agendaItems;
    }
    getTueAgendaItems() {
        let agendaItems: JSX.Element[] = [];
        agendaItems.push(this.agendaEventTypeHeader(EventType.VirtualExperience, "right"));
        agendaItems = agendaItems.concat(this.state.data
            .filter(e => e.day == 27 && e.type === EventType.VirtualExperience)
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((event, idx) => this.agendaItemWithTime(event, "right")));
        // agendaItems.push(this.agendaEventTypeHeader(EventType.LiveStream, "right"));
        agendaItems = agendaItems.concat(this.state.data
            .filter(e => e.day === 27 && e.type === EventType.LiveStream)
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((event, idx) => this.agendaItemWithTime(event, "right")));
        // agendaItems.push(this.agendaEventTypeHeader(EventType.OnDemand, "right"));
        agendaItems = agendaItems.concat(this.state.data
            .filter(e => e.day === 27 && e.type === EventType.OnDemand) //yes, repeat the on demand videos from the 26
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((event, idx) => this.agendaItemNoTime(event, "right")));
        return agendaItems;
    }
    agendaItems(day: EventDay | "both") {
        let agendaItems: JSX.Element[] = [];
        switch (day) {
            case 26:
                agendaItems = this.getMonAgendaItems();
                break;
            case 27:
                agendaItems = this.getTueAgendaItems();
                break;
            case "both":
                agendaItems = this.getMonAgendaItems().concat(this.getTueAgendaItems());
                break;
        }
        let className = this.props.view === "both" ? "pav-agenda-items-container-twin" : "pav-agenda-items-container-single";
        return <div className={className}>{agendaItems}</div>;



        //items: AgendaItem[], group: 1 | 2 | 3
        // let agendaItems: JSX.Element[] = [];
        // agendaItems = items
        //     .filter(a => group === 3 ? true : a.group === group)
        //     .sort((a, b) => a.order - b.order)
        //     .map((x, i) => x.group === 1
        //         ? this.onDemandAgendaItem(x.title, x.dsc, i)
        //         : this.liveStreamAgendaItem(x.title, x.dsc, i, x.order === 1 && x.group === 2 ? "6:00 PM - 8:00 PM EST" : ""));

        // let className = this.props.view === "both" ? "pav-agenda-items-container-twin" : "pav-agenda-items-container-single";
        // return <div className={className}>{agendaItems}</div>;
    }
    render() {
        let header: JSX.Element;
        let agendaItems: JSX.Element;
        const loading = this.state.loading;
        // console.log(this.props.view)
        switch (this.props.view) {
            case "mon":
                header = this.singleHeader("Tue", "5.25");
                agendaItems = this.agendaItems(26);
                break;
            case "tue":
                header = this.singleHeader("Wed", "5.26");
                agendaItems = this.agendaItems(27);
                break;
            case "both":
                header = this.twinHeader("Tue", "5.25", "Wed", "5.26");
                agendaItems = this.agendaItems("both");
                break;
        }

        return (
            <div className="pav-container">
                {/*header*/}
                {agendaItems}
            </div>
        );
    }
}