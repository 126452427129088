import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';

export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);


    }

    render() {
        let authenticated= authService.isAuthenticated()
        var link = document.createElement("a");
        link.href = window.location.pathname || this.props.path;
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`;

        const { component: Component, ...rest } = this.props;
        const route = <Route {...rest}
            render={(props) => {
                if (authenticated) {
                    if(rest.render) {
                        return rest.render();
                    }
                    return <Component {...props} />
                } else {
                    authService.redirectToLogin()
                    // return <Redirect to={redirectUrl} />
                }
            }} />;
        return route;
 
    }
}
