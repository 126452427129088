import s from './PreEvent.module.css';
import React, { Component } from 'react';
import TopMenu from '../Menus/TopMenu';
import AboutSection from './sections/AboutSection';
import HomeSection from './sections/HomeSection';
// import HomeSection from './sections/homeSection/HomeSection';
import SpeakersSection from './sections/SpeakersSection';
import SponsorsSection from './sections/SponsorsSection';
// import SponsorsSection from './sections/sponsorsSection/SponsorsSection'
import AgendaSection from './sections/AgendaSection';
import SupportSection from './sections/SupportSection';
import RegisterSection from './sections/RegisterSection';
import FooterMenu from '../Menus/FooterMenu';
import SilentAuctionSection from './sections/SilentAuctionSection';
import OurSponsorsSection from './sections/OurSponsorsSection';
import SponsorMenu from '../Menus/SponsorMenu';
interface PreEventProps {

}

interface PreEventState {

}


export default class PreEvent extends Component<PreEventProps, PreEventState> {
    render() {
        let navTargetClass = s["pre-event-nav-target"];
        return (
            <div className={s.container}>
                <TopMenu></TopMenu>
                <div className={s["sections-container"]}>
                    <div className={`${s.section} ${s["home"]}`}>
                        <span id="home" className={navTargetClass}></span>
                        <HomeSection />
                    </div>
                    <div className={`${s["section"]} ${s["about"]} `}>
                        <span id="about" className={navTargetClass}></span>
                        <AboutSection />
                    </div>
                    <div className={`${s["section"]} ${s["agenda"]} `}>
                        <span id="agenda" className={navTargetClass}></span>
                        <AgendaSection />
                    </div>
                    <div className={`${s["section"]} ${s["speakers"]} `}>
                        <span id="speakers" className={navTargetClass}></span>
                        <SpeakersSection />
                    </div>
                    <div className={`${s["section"]} ${s["sponsors"]} `}>
                        <span id="sponsors" className={navTargetClass}></span>
                        <SponsorsSection />
                    </div>
                    <div className={`${s["section"]} ${s["oursponsors"]} `}>
                        <span id="oursponsors" className={navTargetClass}></span>
                        <SponsorMenu />
                    </div>

                    {/*<div className={`${s["section"]} ${s["silentauction"]} `}>
                        <span id="silentauction" className={navTargetClass}></span>
                        <SilentAuctionSection />
                    </div>*/}
                    {/*<div className={`${s["section"]} ${s["support"]} `}>
                        <span id="support" className={navTargetClass}></span>
                        <SupportSection />
                    </div>*/}
                    {/*<div className={`${s["section"]} ${s["register"]} `}>
                        <span id="support" className={navTargetClass}></span>
                        <RegisterSection />
                    </div>*/}
                    <footer>
                        <FooterMenu />
                    </footer>
                </div>
            </div>
        );
    }
}