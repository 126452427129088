import React from 'react';
import { useField, FieldHookConfig } from 'formik';

import './FormElementContainers.css';
import { StyledError, StyledInput, StyledLabel } from './StyledFormElements';



// interface VhhpInputTextProps {
//     theme?: "Primary" | "Secondary";
//     name: string;
//     label: string;
//     type: "text" | "password";
//     className?: string;
//     onChange?: (e:any) => void;
//     errorMsg?: string | null;
//     value?: string|null;
// }

interface VhhpInputProps {
    label: string;
    wrapperClassName?: string;
}

const VhhpInput = (props: FieldHookConfig<string> & VhhpInputProps) => {
    const [field, meta] = useField(props);
    return (
        <div className={`vhhp-input-container ${props.wrapperClassName}`}>
            <div className="vhhp-label-wrapper">
                <StyledLabel htmlFor={props.name}>{props.label}</StyledLabel>
                {
                        meta.touched && meta.error
                        ? <StyledError>{meta.error}</StyledError>
                        : null
                }                
            </div>
            <StyledInput {...field} type={props.type} disabled={props.disabled}></StyledInput>            
        </div>
    );
}

export default VhhpInput;