import React, { useState, useEffect } from 'react';
import AllRooms from './allRooms/AllRooms';
import RoomsCards from './roomsCards/RoomsCards';
import './RoomsSection.scss';
import { connect } from 'react-redux';
import { showRooms } from '../../../../redux/actions/interactions';
import { presence } from '../../../../redux/actions/user';
import roomInfoR from './roomInfo.json';

const RoomsSection = ({
  showRooms,
  company,
  primarySponsor,
  online,
  user,
  presence,
  location,
  setIsRooms,
}) => {
  const [filteredRoomsR, setfilteredRoomsR] = useState(null);

  const [filteredRoomsEnterR, setFilteredRoomsEnterR] = useState(null);

  const [presenceCalled, setPresenceCalled] = useState(false);

  const [flag, setFlag] = useState(false);

  const [loading, setLoading] = useState(true);

  //console.log('USER: ', user);

  // useEffect(() => {
  //   sortRooms();
  //   //presence();
  //   setFlag((currFlag) => true);
  // }, [online]);

  if (user && !presenceCalled) {
    setPresenceCalled((value) => true);
    user && presence('RoomSelection');
  }

  useEffect(() => {
    //user && filterData(user);
    user && sortRooms(user);
  }, [online]);

  // const filterData = (u) => {
  //   let count = 0;
  //   const filterData = filteredRoomsR.filter((item, i) => {
  //     // console.log('item.sponsorid', item.sponsorID);

  //     //console.log('USER::', item, u);
  //     if (item.sponsorID == u.sponsorID) {
  //       ++count;
  //       return item;
  //     }
  //   });
  //   // console.log({ filterData })
  //   setfilteredRoomsR(filterData);
  // };

  const sortRooms = (u) => {
    let reducedPeople = '';
    let newRooms = [...roomInfoR];
    // console.log(newRooms)
    console.log(u)
    let rooms = newRooms.filter((item, i) => {
      // console.log("invitedUserId" in item)
      // console.log("invitedUserId" in u)
      if("invitedUserId" in item && "invitedUser" in u)
      {
      console.log(u.invitedUser?.id)

        // if (item.invitedUserId == u.invitedUserId) {
        if(item.invitedUserId.includes(u?.invitedUser?.id)) {
          return item;
        }
      }
      /*
      if (item.sponsorID == u.sponsorID) {
        return item;
      }*/
    });

    if (rooms.length) {
      setLoading(false);
    }

    console.log('rooms', rooms);

    rooms.map((item, index) => {
      let people = online.filter(
        (value) =>
          value.context.includes('_') &&
          value.context.split('_')[1] === item.subRoomName
      );

      if (people.length) {
        reducedPeople = people.reduce((total, person) => {
          return total + ' ' + person?.name;
        }, '');
      }

      //console.log('ONLINE PEOPLE: ', { reducedPeople });
      if (people.length) {
        rooms[index] = {
          ...rooms[index],
          count: people.length,
          people: reducedPeople,
        };
      } else {
        rooms[index] = { ...rooms[index], count: 0, people: '' };
      }
    });

    let sortedRooms = rooms.sort((a, b) => b.count - a.count);
    setfilteredRoomsR(sortedRooms);
  };

  return (
    <div className='rooms-section hide-mobile show-desktop'>
      {/* {!isRooms && (
        <div
          className='rooms-service__join-tab body1 gray-1'
          onClick={handleClick}
        >
          Join now to Enter Video Networking Rooms
        </div>
      )} */}

      <div className='rooms-service__container'>
        <div className='rooms-service__header'>
          <div className='rooms-service__title flex'>
            <h3>Video Networking Rooms</h3>
            <div
              className='rooms-service__close-rooms body3 gray-1 pointer'
              onClick={(e) => {
                setIsRooms(false);
              }}
            >
              Close Rooms
              <svg
                width='14'
                height='9'
                viewBox='0 0 14 9'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M13.8807 6.83347L7.36173 0.145776C7.17093 -0.0480709 6.85292 -0.0480709 6.66212 0.145776L0.143101 6.83347C-0.0477002 7.02731 -0.0477002 7.35039 0.143101 7.54424L0.842703 8.25501C1.0335 8.44885 1.3515 8.44885 1.54231 8.25501L6.66212 2.98885C6.85292 2.79501 7.17093 2.79501 7.36173 2.98885L12.4815 8.2227C12.6723 8.41654 12.9903 8.41654 13.1811 8.2227L13.8807 7.51193C14.0398 7.31808 14.0398 7.02731 13.8807 6.83347Z'
                  fill='#3b3b3b'
                />
              </svg>
            </div>
          </div>

          <div className='rooms-service__info body2'>
            Get your network on with other Virtual Habitat House Party guests!
            Just enter one of the watch party rooms below. Your recommended
            rooms will connect you with your host and their attendees, or you
            can use the filter function if you're looking for a specific person
            or sponsor. Please note: the user must be active in the Virtual
            Habitat House Party in order for you to find them.
          </div>
        </div>

        <div className='rooms-service__recom-title body1'>
          Recommended Rooms For You ({filteredRoomsR && filteredRoomsR.length})
        </div>

        <div className='rooms-service__cards'>
          {!loading &&
            filteredRoomsR &&
            filteredRoomsR.map((item, idx) => <RoomsCards key={`rooms-cards-${idx}`} item={item} />)}
        </div>

        <div className='rooms-service__all-rooms'>
          <AllRooms roomInfo={roomInfoR} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isRooms: state.interactions.isRooms,
  company: state.user.company,
  primarySponsor: state.user.primarySponsor,
  online: state.user.online,
  user: state.user.userData && state.user.userData,
});

export default connect(mapStateToProps, { showRooms, presence })(RoomsSection);
