import React, { Component, useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import { Modal } from 'antd';
import { Form, Input, Button, Checkbox } from 'antd';

import 'antd/dist/antd.css'

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

const UserCapture = ({
    user
}) => {

    const [visible, setVisible] = useState(false);

    const [sponsorID, setSponsorID] = useState(null)
    const dispatch = useDispatch();

    useEffect(() => {
        setVisible(user.id == '')
    }, [user])

    // const { Option } = Select;

    // function handleChange(value) {
    //     console.log(`selected ${value}`);
    //     setSponsorID(value);
    // }

    const onFinish = (data) => {

        // data

        console.log(data)
        
        let uuid = uuidv4();

        dispatch({
            type: 'SET_USER',
            payload: {
                id: uuid,
                email: uuid,
                // sponsorID: data.sponsorID,
                profile: {
                    firstName: data.firstName,
                    lastName: data.lastName
                }
            }
        })

    }

    return (
        <div>

            <Modal
                title="Please Provide Your Information"
                visible={visible}
                closable={false}
                footer={false}
            >

                <Form
                    name="normal_login"
                    className="login-form"
                    onFinish={onFinish}
                >

                    <Form.Item
                        name="firstName"
                        rules={[{ required: true, message: 'Please enter your first name!' }]}
                    >
                        <Input placeholder="First Name" />
                    </Form.Item>

                    <Form.Item
                        name="lastName"
                        rules={[{ required: true, message: 'Please enter your last name!' }]}
                    >
                        <Input placeholder="Last Name" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Submit
                        </Button>
                    </Form.Item>

                </Form>

            </Modal>

        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.userData
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(UserCapture)
