import './Signup.css';
import React, { Component } from 'react';
import Infobox from './Infobox';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import VhhpInput from '../Elements/VhhpInput';
import VhhpButton from '../Elements/VhhpButton';
import { Link } from 'react-router-dom';

interface ForgotPasswordState {
    emailSent: boolean;
}
export default class ForgotPassword extends Component<any, ForgotPasswordState> {
    constructor(props: any) {
        super(props);
        this.state = { emailSent: false };
    }
    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        let emailSent = urlParams.get("emailsent");
        if (emailSent === "true")
            this.setState({ emailSent: true });
    }

    getForm() {
        return <Formik
            initialValues={{ Email: "" }}
            validationSchema={
                Yup.object({
                    Email: Yup.string()
                        .email("Invalid email address")
                        .required()
                })
            }
            onSubmit={(values, { setSubmitting }) => {
                // setTimeout(() => {
                //   alert(JSON.stringify(values, null, 2));
                //   setSubmitting(false);
                // }, 400);
                (document.getElementById("forgotPasswordForm") as HTMLFormElement).submit();
            }}
        >
            {({ isSubmitting }) => (
                <Form id="forgotPasswordForm" method="POST" action="/Identity/Account/ForgotPassword">
                    <VhhpInput type="text" label="Email Address" name="Email" />
                    <VhhpButton disabled={isSubmitting} type="submit" styleOptions="standard" text="Submit" className="button__next-button--wide" />
                </Form>
            )}
        </Formik>;
    }
    getEmailSent() {
        return <div>

            <div className="body1 green-success"><p>Please check your email to reset your password.</p></div>
            <VhhpButton styleOptions="standard" text="Return to Sign In Page" className="button__next-button--wide" url="/signin" />
        </div>

    }
    render() {

        return (
            <div className="signup-container">
                <Infobox />

                <div className="form-box">

                    <div className="form-box-content">
                        <div className="form-box__title gray-1">Forgot Your Password?</div>
                        <div className="form-box__text form-box__text-top gray-1">Please enter your email below, or click <Link to="/signin" className="a-inline">here</Link> to go back.</div>

                        {this.state.emailSent && this.getEmailSent()}
                        {!this.state.emailSent && this.getForm()}

                    </div>
                </div>
            </div>


        );
    }
}