import React, { useState } from 'react';

function flatMap(array, fn) {
  var result = [];
  for (var i = 0; i < array.length; i++) {
    var mapping = fn(array[i]);
    result = result.concat(mapping);
  }
  return result;
}

const FaqItem = ({ item }) => {
  const [isOpen, setOpen] = useState(false);

  let question = null
  question = item.answer
  if(item.answer.includes('[here]'))
  {
    question = flatMap(item.answer.split("[here]"), (part) => [part, <a href="https://habitatnycwc.org/donate-now/" target="_blank">here</a>])
    question.pop()
  }

  return (
    <div className='faqitem'>
      <div
        className={`faqitem__arrow ${isOpen ? 'open' : 'close'}`}
        onClick={(e) => setOpen((isOpen) => !isOpen)}
      >
        <svg
          width='14'
          height='9'
          viewBox='0 0 14 9'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='transition'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.8807 6.83347L7.36173 0.145776C7.17093 -0.0480709 6.85292 -0.0480709 6.66212 0.145776L0.143101 6.83347C-0.0477002 7.02731 -0.0477002 7.35039 0.143101 7.54424L0.842703 8.25501C1.0335 8.44885 1.3515 8.44885 1.54231 8.25501L6.66212 2.98885C6.85292 2.79501 7.17093 2.79501 7.36173 2.98885L12.4815 8.2227C12.6723 8.41654 12.9903 8.41654 13.1811 8.2227L13.8807 7.51193C14.0398 7.31808 14.0398 7.02731 13.8807 6.83347Z'
            fill='#00AFD7'
          />
        </svg>
      </div>
      <div className='faqitem__content'>
        <div
          className={`h3 blue-3 question ${isOpen ? 'open' : 'close'}`}
          onClick={(e) => setOpen((isOpen) => !isOpen)}
        >
          {item.question}
        </div>
        {isOpen && <div className='body3 answer'>{question}</div>}
      </div>
    </div>
  );
};

export default FaqItem;
