import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';

//patchwork Authorize Service
//as you can't clear cookie from another page right now, we store the logut flag in local storage. when this is set to true, user is assumed to have 
//logged out and the client will ignore avaliable cookies

//isAuthenticated storage is set to store the temp state of authentication. This is checked using checkAuthState everytime users access 
//the main page which will adjust it accordingly.

class AuthorizeService {

    _apiServerPrefix = process.env.REACT_APP_VHHP_API || 'https://identity.habitatnycwc.org/'

    redirectToLogin(){
        this.setAttemptLoggedIn(true)
        window.location.href=ApplicationPaths.Login
    }

    redirectToRegister(){
        this.setAttemptLoggedIn(true)
        // console.log(`redirect to ${ApplicationPaths.Register}`)
        window.location.href=ApplicationPaths.Register
    }

    setAttemptLoggedIn(state){
        localStorage.setItem('triedLogin', state);
    }

    isAttemptLoggedIn(){
        let attemptedState = localStorage.getItem('triedLogin');
        let firstTime = sessionStorage.getItem('firstCheck')
        if (attemptedState ==="true" || firstTime===null)
        {
            return true
        }
        else 
        {
            return false
        }
    }
    
    //do a server ping to check to see if user has the right authentications, usually called after a 401
    async checkAuthState(){
        try {
            // alert(this._apiServerPrefix+"api/MyUserData")
            const response = await fetch(this._apiServerPrefix+"api/MyUserData",
                {
                    credentials: 'include',
                    mode: 'cors'
                });
            sessionStorage.setItem('firstCheck',true)
            if(response.status==200 && response.ok)
            {
                const data = await response.json();
                if(data){
                    localStorage.setItem('isAuthenticated', true);
                    return true
                }
                else{
                    localStorage.setItem('isAuthenticated', false);
                    return false
                }
            }
            else{
                localStorage.setItem('isAuthenticated', false);
                return true
            }
        }
        catch {
            localStorage.setItem('isAuthenticated', false);
            return false
        }
    }


    isAuthenticated() {
        let authstate = localStorage.getItem('isAuthenticated');
        if (authstate ==="true")
        {
            return true
        }
        else 
        {
            return false
        }
    }

    async getUser() {
       return "USER"
    }

    async getAccessToken() {
        return null
    }

    async signIn(state, attemptSilentOnly){
        return this._isAuthenticatedState
    }

    //not tries to fetch logout page, currently not used
    async signOut() {
        //set isAuth as false, note: a seperate link to this._apiServerPrefix+"identity/account/logout is called to destroy the cookie at the remote site
        localStorage.setItem('isAuthenticated', false);
        this.setAttemptLoggedIn(true)
        /*
        try {
            const response = await fetch(this._apiServerPrefix+"identity/account/logout",
                { 
                    credentials: 'include',
                    mode: 'cors'
                });
            if(response.status==200 && response.ok)
            {
                return true
            }
            else{
                return false
            }
        }
        catch {
            return false
        }
        */
    }

    subscribe(callback) {
       return true
    }

    unsubscribe(subscriptionId) {
        return true
    }

}
const authService = new AuthorizeService();
export default authService;

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};
