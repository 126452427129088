import './WizardInfoPane.scss';
import React, { Component } from 'react';
import VectorLogo from '../VectorLogo/VectorLogo';
import * as rFlow from './RegistrationFlow';
import { RegistrationStep } from './RegistrationFlow';

interface WizardInfoPaneProps {
    registrationFlow: rFlow.RegistrationFlow | undefined;
}

export default class WizardInfoPane extends Component<WizardInfoPaneProps> {
    getAllRegistrationFlowElements() {
        const step = this.props.registrationFlow?.currentStep;
        const flow = this.props.registrationFlow?.flow;

        if (step === "termsConditions" || !flow || step === undefined )
            return <></>;
        else {
            let steps = flow.map((s, idx) => this.getRegistrationFlowElementsForStep(idx, s, idx === step, idx < step));

            return (
                <>
                    <div className="rwip-steps-container">
                        {steps}
                    </div>
                    {/*<div className="rwip-steps-img"><img src={flow[step].img} className="rwip-steps-img__img" /></div>*/}
                </>
            )

        }
    }
    getRegistrationFlowElementsForStep(key: number, step: RegistrationStep, active: boolean, complete: boolean) {
        let cssClass = "";
        if (!active && complete)
            cssClass = "rwip-step-title--complete";
        if (!active && !complete)
            cssClass = "rwip-step-title--incomplete";
        return (
            <div
                key={key}
                className={`rwip-step-title ${cssClass}`}>
                {step.label}
            </div>
        )
    }

    render() {
        return (
            <div className="rwip-container">
                <VectorLogo withUrl={true} logo="hhnyc-hz-white" className="rwip-habitat-logo"></VectorLogo>

                {this.getAllRegistrationFlowElements()}

                <div className="rwip-need-help">Need Help? Contact us at <br /><a className="footer-link" href="mailto:events@habitatnycwc.org">events@habitatnycwc.org</a></div>
            </div>
        );
    }
}