export const ApplicationName = 'Website';

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out'
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register'
};

const prefix = '/authentication';


export const ApplicationPaths = {
  DefaultLoginRedirectPath: '/',
  ApiAuthorizationClientConfigurationUrl: `/_configuration/${ApplicationName}`,
  ApiAuthorizationPrefix: prefix,
  Login:  process.env.REACT_APP_VHHP_API+'identity/account/login' ||  "https://identity.habitatnycwc.org/identity/account/login",
  LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
  LoginCallback: `${prefix}/${LoginActions.LoginCallback}`, //"/signup?callback=true"
  Register:  process.env.REACT_APP_VHHP_API+'identity/account/register' ||  "https://identity.habitatnycwc.org/identity/account/register",
  Profile: "/profile",
  LogOut: process.env.REACT_APP_VHHP_API+'identity/account/logout' ||  "https://identity.habitatnycwc.org/identity/account/logout",
  LoggedOut: "/",
  LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
  IdentityRegisterPath: '/signup',
  IdentityManagePath: '/profile'
};
