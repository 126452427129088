import React, { Component } from 'react';
import agendaService, { EventDay, EventType, VhhpEvent } from '../../AgendaService';
import imgCalendar from './img/button_calendar.png';
import imgCheck from './img/button_check.png';
import imgPlus from './img/button_plus.png';
import imgClose from '../../common/img/button_close.png';
import moment from 'moment-timezone';

import vhhpUserService, { Sponsor, UserProfile, VhhpUserType } from '../../VhhpUserService';
import "./AgendaView.scss";
import ReactModal from 'react-modal';
import VhhpButton from '../Elements/VhhpButton';
import { ICalendar as ICal } from "datebook";
import agendaJson from './vhhpEvents.json';
import SpeakerImagesConfig, {SpeakerImageConfig} from '../preevent/sections/SpeakerImagesConfig'

const SpeakerImages = SpeakerImagesConfig()


interface AgendaViewProps {
    fullHeight?: boolean;

}
interface AgendaViewState {
    loading: boolean;
    events: VhhpEvent[];
    selectedDay: EventDay;
    selectedVhhpEventIDs: number[];
    modalEvent?: VhhpEvent;
    userProfile: UserProfile | null;
    usersSponsor: Sponsor | null;
    guestType: VhhpUserType | null;
}

ReactModal.setAppElement('#root');

export default class AgendaView extends Component<AgendaViewProps, AgendaViewState> {
    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            events: [],
            selectedDay: 26,
            selectedVhhpEventIDs: [],
            modalEvent: undefined,
            userProfile: null,
            usersSponsor: null,
            guestType: null
        }
    }
    async componentDidMount() {
        // console.log(this.props)
        this.populateEvents();
        const vhhpUser = await vhhpUserService.getVhhpUser()
        if (vhhpUser?.profile)
            this.setState({
                guestType: vhhpUser.vhhpUserType,
                userProfile: vhhpUser.profile,
                usersSponsor: vhhpUser.sponsor || null
            })
    }
    async populateEvents() {
        //TODO: adjust the getEvents() to filter on roletype
        const eventsAll = await agendaService.getEvents();
        const vhhpUser = await vhhpUserService.getVhhpUser();
        let filteredEvents: VhhpEvent[];
        filteredEvents = eventsAll;

        eventsAll.forEach((e, idx) => {
            agendaJson.forEach((a, i) => {
                if(a.vhhpEventID === e.vhhpEventID)
                {
                    filteredEvents[idx] = {
                        day: e.day,
                        description: e.description,
                        end: e.end,
                        endDateTime: e.endDateTime, // "2020-10-27T20:00:00.000-04:00"
                        guestSpeakers: a.guestSpeakers,
                        title: e.title,
                        type: e.type,
                        vhhpEventID: e.vhhpEventID,
                        startDateTime: e.startDateTime,
                        sortOrder: e.sortOrder,
                        start: e.start,
                    
                        hideTime: a.hideTime,
                        virtual: a.virtual,
                        inPerson: a.inPerson,
                        extraList: a.extraList
                    }
                }
            })
        })
        // console.log(filteredEvents)
        // if (vhhpUser?.vhhpUserType === VhhpUserType.GeneralPublic) {
        //     filteredEvents = eventsAll.filter(e => e.type !== EventType.VirtualExperience);
        // }
        // else {
        //     filteredEvents = eventsAll.filter(e => e.type !== EventType.OnDemand);
        // }
        // console.log("filtered ",filteredEvents)
        let agendaItems: number[] = vhhpUser?.agendaItems?.map((i) => i.vhhpEventID) ?? [];
        this.setState({ events: filteredEvents, loading: false, selectedVhhpEventIDs: agendaItems });
    }
    async handlePlusClick(event: React.MouseEvent, vhhpEventID: number) {
        // console.log('button clicked ', vhhpEventID)
        let selectedEvents = this.state.selectedVhhpEventIDs;
        // console.log(selectedEvents)
        const eventSelected = selectedEvents.includes(vhhpEventID);
        // console.log(`event is selected ${eventSelected}`)
        const vhhpUser = await vhhpUserService.getVhhpUser();
        if (vhhpUser) {
            if (eventSelected) {
                //remove the agenda item
                const agendaItem = vhhpUser.agendaItems?.find(i => i.vhhpEventID === vhhpEventID);
                if (agendaItem) {
                    let success = await vhhpUserService.removeUserAgendaItem(agendaItem);
                    if (success)
                        selectedEvents = selectedEvents.filter(i => i !== vhhpEventID);
                    //if it fails, don't remove it locally
                }
            } else {
                // console.log(`the event is not selected`)
                //add the event
                const agendaItem = { id: vhhpUser.id, vhhpEventID: vhhpEventID };
                let success = await vhhpUserService.addUserAgendaItem(agendaItem);
                // console.log(success)
                if (success)
                    selectedEvents.push(vhhpEventID);
                //if it fails, don't remove it locally
            }
            this.setState({ selectedVhhpEventIDs: selectedEvents });
        }
    }
    calButton(event: VhhpEvent) {
        return <img className="av-button av-button__calendar"
            alt="Add to Calendar"
            title="Add to Calendar"
            src={imgCalendar}
            onClick={() => this.onCalendarClick(event)}
        />;

    }
    plusButton(event: VhhpEvent) {
        const eventSelected = this.state.selectedVhhpEventIDs.includes(event.vhhpEventID);
        return <img className="av-button av-button__plus"
            alt={eventSelected ? "Remove from Agenda" : "Add to Agenda"}
            title={eventSelected ? "Remove from Agenda" : "Add to Agenda"}
            src={eventSelected ? imgCheck : imgPlus}
            onClick={(e) => this.handlePlusClick(e, event.vhhpEventID)}

        />;

    }
    bottomCalButton(event: VhhpEvent) {
        return <VhhpButton
            className="av-modal-bottom-calendar-button"
            text="Add to Calendar"
            styleOptions="standard"
            onclick={() => this.onCalendarClick(event)}
        />;
    }
    private onCalendarClick(event: VhhpEvent) {
        // console.log("clicked")
        // console.log(event);
        //hardcoded logic assumes that only the first of the livestream events (by sort order) will have a calendar CTA and that the endtime is 1 hour longer

        var tz = 'America/New_York'; // example value, you can use moment.tz.guess()

        if (event.type === EventType.LiveStream) {
            let start = new Date(event.startDateTime)
            let end = new Date(event.endDateTime)
            let fullDescription = ''
            fullDescription += this.state.events
            .filter(e => e.type === EventType.LiveStream && e.start === event.start)
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((e) => `${e.title}\n${e.description}\n\n`).join('\n')
            if(event.extraList && event.extraList.length > 0)
            {
                try
                {
                    event.extraList.forEach((l) => {
                        fullDescription+=`${l.title}\n${l.description}\n\n`
                    })

                }
                catch(e)
                {

                }
            }
            let newCAL = new ICal({
                title: `Hybrid Habitat House Party: ${event.title}`,
                description: fullDescription,
                start: start,
                end: end,
                location: "Guastavino’s: 409 E 59th St, New York, NY 10022"
            })
            newCAL.setMeta("X-WR-TIMEZONE", tz)
            newCAL.properties.push('BEGIN:VTIMEZONE')
            newCAL.properties.push('TZID:America/New_York')
            newCAL.properties.push('END:VTIMEZONE')
            // console.log(newCAL)
            newCAL.download()
            // new ICal({
            //     title: `Hybrid Habitat House Party: ${event.title}`,
            //     description: this.state.events
            //         .filter(e => e.type === EventType.LiveStream && e.start === event.start)
            //         .sort((a, b) => a.sortOrder - b.sortOrder)
            //         .map((e) => `${e.title}\n${e.description}\n`).join('\n'),
            //     start: formatStartDate,
            //     end: formatEndDate,
            //     location: "Guastavino’s: 409 E 59th St, New York, NY 10022"
            // }).download();
        } else {
            let start: Date, end: Date;
            if (event.type === EventType.OnDemand) {
                start = new Date(2021, 10, event.day);
                end = new Date(2021, 10, event.day + 1);
            } else {

                start = new Date(event.startDateTime)
                end = new Date(event.endDateTime)
            }
            let newCAL = new ICal({
                title: event.title,
                description: event.description,
                start: start,
                end: end,
                location: "https://events.habitatnycwc.org"
            })
            newCAL.setMeta("X-WR-TIMEZONE", tz)
            newCAL.properties.push('BEGIN:VTIMEZONE')
            newCAL.properties.push('TZID:America/New_York')
            // newCAL.properties.push('LAST-MODIFIED:20050809T050000Z')
            // newCAL.properties.push('BEGIN:STANDARD')
            // newCAL.properties.push('DTSTART:20071104T020000')
            // newCAL.properties.push('TZOFFSETFROM:-0400')
            // newCAL.properties.push('TZOFFSETTO:-0500')
            // newCAL.properties.push('TZID:America/New_York')
            // newCAL.properties.push('LAST-MODIFIED:20050809T050000Z')
            // newCAL.properties.push('TZNAME:EST')
            // newCAL.properties.push('END:STANDARD')
            // newCAL.properties.push('BEGIN:DAYLIGHT')
            // newCAL.properties.push('DTSTART:20070311T020000')
            // newCAL.properties.push('TZOFFSETFROM:-0500')
            // newCAL.properties.push('TZOFFSETTO:-0400')
            // newCAL.properties.push('TZNAME:EDT')
            // newCAL.properties.push('END:DAYLIGHT')
            newCAL.properties.push('END:VTIMEZONE')

            newCAL.download()
        }
    }

    agendaItems(selectedDay: EventDay) {
        // console.log(`the selected day is ${selectedDay}`)
        // console.log("passed events ", this.state.events)
        const items = this.state.events
            .filter(e => e.day === selectedDay)
            //.filter(e => e.type === EventType.LiveStream || e.type === EventType.OnDemand)            
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((e) => {
                console.log(e)
                return this.agendaItem(e)
            });
        return <>{items}</>;
    }
    agendaItem(event: VhhpEvent) {
        // console.log(event)
        // console.log(event.sortOrder)
        // const showButtons = (event.type === EventType.VirtualExperience) ||
        //     (event.type === EventType.LiveStream && event.sortOrder <= 2)
        // const showTime = (event.type === EventType.VirtualExperience) ||
        //     (event.type === EventType.LiveStream && (event.sortOrder === 1 || event.sortOrder === 2))
        const showButtons = true
        const showTime = !event.hideTime
        let start = event.start, end = event.end;

        const guestType = this.state.guestType
        // console.log(`the guest type is ${guestType}`)
        // if (event.type === EventType.LiveStream) {
        //     if (event.sortOrder === 1) {
        //         start = "6:00 PM";
        //         end = "7:00 PM";
        //     }
        //     if (event.sortOrder === 2) {
        //         start = "7:00 PM";
        //         end = "8:00 PM";
        //     }
        // }
        let extraList: JSX.Element[] = [];

        if(event.extraList)
        {
            event.extraList.forEach((l, idx) => {
                extraList.push(
                    <li key={`new-extra-list-${idx}`} className='padded-list' style={{ marginTop: '10px' }}>
                    <div className="pav-agenda-item_title h3 blue-3">{l.title}</div>
                    <div className="pav-agenda-item_dsc body2">{l.description}</div>
                    </li>)
            })
        }

        return (
            <div key={event.vhhpEventID} className="av-agenda-item">
                <div className={"av-item-time"} style={{ visibility: showTime ? "visible" : "hidden" }}>
                    <p style={{ textAlign: "center", marginBlockStart: "0" }}>{start}<br />|<br />{end}<br />EDT</p>
                </div>
                <div className="av-item-info">
                    <div className="av-item-info__title" onClick={() => this.openModal(event)}>{event.title}</div>
                    <div className="av-item-info__desc">{event.description}</div>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '20px'
                    }}>
                    {event?.virtual ? <div className="pav-agenda-item_tpye virtual">VIRTUAL</div>:null}
                    {event?.inPerson ? <div className="pav-agenda-item_tpye in-person">IN-PERSON</div>:null}
                    </div>
                    <ul>{extraList}</ul>
                </div>
                {guestType === VhhpUserType.Sponsor || guestType === VhhpUserType.InvitedGuest ? <div className="av-item-buttons" style={{ visibility: showButtons ? "visible" : "hidden" }}>
                    {this.calButton(event)}
                    {this.plusButton(event)}
                </div>:null}
            </div>
        );
    }
    eventModal(event?: VhhpEvent) {
        if (!event) return null;
        let room: string;
        let guestSpeakersArr: JSX.Element[]
        switch (event.type) {
            case EventType.LiveStream: room = "Live Streaming"; break;
            case EventType.OnDemand: room = "On Demand"; break;
            case EventType.VirtualExperience: room = "Virtual Experience"; break;
        }
        event.guestSpeakers.forEach((s, idx) => {
            SpeakerImages.forEach((i, id) => {
                if(s.vhhpEventSpeakerID === i.key)
                {
                    event.guestSpeakers[idx]['thumbSrc'] = i.thumbSrc
                }
            })
        })

        return (
            <ReactModal
                className="av-modal"
                overlayClassName="av-modal-overlay"
                isOpen={this.state.modalEvent !== undefined}
                contentLabel={this.state.modalEvent?.title}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => this.closeModal()}
            >
                <div className="av-modal-content">
                    <button className="av-modal-close-button" onClick={() => this.closeModal()}>
                        <img src={imgClose} alt="close" height="16px" width="16px" />
                    </button>
                    {event.type !== EventType.OnDemand &&
                        <div className="label--primary av-modal-time">{event.start} - {event.end}</div>
                    }
                    {event.type === EventType.LiveStream && <div style={{ height: "12px" }} />}
                    <div style={{ height: "12px" }} />
                    <div className="av-modal-header">
                        <div className="h2 av-modal-title">{event.title}</div>
                        <div className="av-modal-button">
                            {this.calButton(event)}
                            {this.plusButton(event)}
                        </div>
                    </div>
                    {/*<div style={{ height: "12px" }} />
                    <div className="body2 av-modal-text">{room} Room</div>*/}
                    {event?.virtual ? <div className="pav-agenda-item_tpye virtual">VIRTUAL</div>:null}
                    {event?.inPerson ? <div className="pav-agenda-item_tpye in-person">IN-PERSON</div>:null}
                    <div style={{ height: "24px" }} />
                    <hr className="av-modal-sep" />
                    <div style={{ height: "30px" }} />
                    <div className="label--primary av-modal-text">description</div>
                    <div style={{ height: "12px" }} />
                    <div className="body3 av-modal-text">{event.description}</div>
                    <div style={{ height: "40px" }} />
                    <hr className="av-modal-sep" />
                    <div style={{ height: "30px" }} />
                    {event.guestSpeakers.length > 0 && <div className="label--primary av-modal-text">speaker</div>}
                    {event.guestSpeakers.length > 0 && <div style={{ height: "18px" }} />}
                    <div className="av-modal-speaker-grid">
                        {event.guestSpeakers.map((it) => {
                            return (
                                <div className="av-modal-speaker">
                                    <div>
                                        <img
                                        className="si-speaker-thumb__img"
                                        alt={"speaker"}
                                        src={it.thumbSrc}
                                        style={{
                                            width: '88px',
                                            height: "88px"
                                        }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            width: 'calc(100% - 108px)'
                                        }}
                                    >
                                        <div className="h4 av-modal-speaker-name">{it.name}</div>
                                        <div className="body3 av-modal-speaker-title">{it.title}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {event.type !== EventType.OnDemand && <div className="av-modal-bottom-buttons">{this.bottomCalButton(event)}</div>}
            </ReactModal>

        );
    }
    closeModal() {
        this.setState({ modalEvent: undefined });
    }
    openModal(event: VhhpEvent) {
        this.setState({ modalEvent: event });
    }

    handleDayClick(clickedDay: EventDay) {
        if (this.state.selectedDay !== clickedDay)
            this.setState({ selectedDay: clickedDay });
    }

    render() {
        const loading = this.state.loading;
        const day = this.state.selectedDay;
        // console.log(`the selected rendering day ${day}`)
        let agendaItems: JSX.Element;
        if (loading) {
            agendaItems = <div className="av-agenda-loading">Loading...</div>
        } else {
            agendaItems = this.agendaItems(day);
        }
        return (
            <div className="av-container">
                <div className="av-date-tabs">
                    <div className={`av-date-tab ${day === 26 ? "av-date-tab--active" : "av-date-tab--inactive"}`} onClick={() => this.handleDayClick(26)}>
                        <div className={`av-date-tab-left ${day === 26 ? "tab-font-active":"tab-font-inactive"}`}>Tue</div>
                        <div className={`av-date-tab-right ${day === 26 ? "tab-font-active":"tab-font-inactive"}`}>10.26</div>
                    </div>
                    <div className={`av-date-tab ${day === 27 ? "av-date-tab--active" : "av-date-tab--inactive"}`} onClick={() => this.handleDayClick(27)}>
                        <div className={`av-date-tab-left ${day === 27 ? "tab-font-active":"tab-font-inactive"}`}>Wed</div>
                        <div className={`av-date-tab-right ${day === 27 ? "tab-font-active":"tab-font-inactive"}`}>10.27</div>
                    </div>
                </div>
                <div className={`av-agenda-container ${!this.props.fullHeight ? "av-agenda-container--limitedHeight" : ""}`}>
                    {agendaItems}
                </div>
                {this.eventModal(this.state.modalEvent)}
            </div>
        );
    }
}