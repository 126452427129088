import React from 'react';
import './NewChat.scss';

const SearchChat = (props) => {
    return (
        <div className="search-chat">
            <input
                className="search-chat__input body2"
                placeholder="Search"
                onChange={props.onChange}/>
            <img src='/images/newChat.svg' alt="profile"></img>
        </div>
    )
}

export default SearchChat
