import './TopMenu.css'
import React, { Component } from 'react'
import VectorLogo from '../VectorLogo/VectorLogo'
import VhhpButtonDonate from '../Elements/VhhpButtonDonate'
import AuthMenu from './AuthMenu'
import authService from '../api-authorization/AuthorizeService'
import vhhpUserService, { VhhpUserType } from '../../VhhpUserService'
import ModalWrapper from '../Elements/ModalWrapper'
import { HashLink as Link } from 'react-router-hash-link';

const MOBILE_BREAK_WIDTH_PX = 1390 //match to CSS - menu only

interface TopMenuProps {
  theme?: string
}

interface TopMenuState {
  hideAgenda: boolean
  responsive: boolean
}

export default class TopMenu extends Component<TopMenuProps, TopMenuState> {
  constructor(props: TopMenuProps) {
    super(props)

    this.state = { hideAgenda: false, responsive: false }
  }
  componentDidMount() {
    //Code to detect scroll position, append the value to HTML and style the menu's habitat logo based on scroll position
    const debounce = (fn: Function) => {
      // This holds the requestAnimationFrame reference, so we can cancel it if we wish
      let frame: number

      // The debounce function returns a new function that can receive a variable number of arguments
      return (...params: any) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
          cancelAnimationFrame(frame)
        }

        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
          // Call our function and pass any params we received
          fn(...params)
        })
      }
    }

    // Reads out the scroll position and stores it in the data attribute
    // so we can use it in our stylesheets
    const storeScroll = () => {
      document.documentElement.dataset.scroll = window.scrollY.toString()
    }

    // Listen for new scroll events, here we debounce our `storeScroll` function
    document.addEventListener('scroll', debounce(storeScroll))

    // Update scroll position for first time
    storeScroll()

    // vhhpUserService.getVhhpUser().then((user) => {
    //   if (user && user.vhhpUserType !== VhhpUserType.GeneralPublic ) {
    //     this.setState({ hideAgenda: true })
    //   }
    // })
  }

  menuClick = () => {
    // console.log("clicked")
    //only toggle if we are at the break point
    if (window.innerWidth < MOBILE_BREAK_WIDTH_PX) {
      let x = document.getElementById('topMenu')
      if (x !== null) {
        if (x.classList.contains('responsive')) {
          x.classList.remove('responsive')
        } else {
          x.classList.add('responsive')
        }
        if (x.className === 'top-menu') {
          x.className += ' responsive'
        } else {
          x.className = 'top-menu'
        }
      }
    }
    this.setState({
      responsive: !this.state.responsive,
    })
  }

  render() {
    // console.log(`hide agenda ${this.state.hideAgenda}`)
    return (
      <>
        <div className="habitat-nyc">
          <div className="body3">Event Hosted by</div>
          <div>
            <a href="https://habitatnyc.org/">
              <VectorLogo
                withUrl={false}
                logo="hhnyc-sgl-black"
                height="32px"
              />
            </a>
          </div>
        </div>

        <div
          className={`top-menu ${this.props.theme} ${
            this.state.responsive ? 'responsive' : ''
          }`}
          id="topMenu"
        >
          <div className="top-menu-left">
            <a className="a" href="#home">
              <VectorLogo
                withUrl={false}
                logo="virtual-party-logo"
                className="logo"
                alt="Habitat Virtual House Party Home"
              />
            </a>
            <Link to="/#home" className="a text-menu-item" onClick={this.menuClick}> 
              Home
            </Link>
            {/*<a className="a text-menu-item" href="#" onClick={this.menuClick}>
              Home
            </a>
           */}
            <Link to="/#about" className="a text-menu-item" onClick={this.menuClick}> 
            About
            </Link>

           {/* <a
              className="a text-menu-item"
              href="#about"
              onClick={this.menuClick}
            >
              About
           </a>*/}
            {/* <a className={`a text-menu-item ${this.state.hideAgenda ? "hide" : ""}`} href="#agenda" onClick={this.menuClick}>Agenda</a> */}

            <Link to="/#agenda" className={`a text-menu-item `} 
              onClick={this.menuClick}> 
             Agenda
            </Link>
            
            {/*<a
              className={`a text-menu-item ${
                this.state.hideAgenda ? '' : 'hide'
              }`}
              href="#agenda"
              onClick={this.menuClick}
            >
              Agenda
            </a>*/}


            {/*<Link to="#featured" className="a text-menu-item" onClick={this.menuClick}> 
              Video On Demand
          </Link>*/}
             {/*
            <a
              className="a text-menu-item"
              href="#featured"
              onClick={this.menuClick}
            >
              Video On Demand
            </a>
             */}
            <Link to="/#speakers" className="a text-menu-item" onClick={this.menuClick}> 
              Guest Speakers
            </Link>
            {/*
            <a
              className="a text-menu-item"
              href="#speakers"
              onClick={this.menuClick}
            >
              Guest Speakers & Honorees
            </a>
            */}
            <Link to="/#sponsors" className="a text-menu-item" onClick={this.menuClick}> 
            Sponsors
            </Link>
            {/*
            <a
              className="a text-menu-item"
              href="#sponsors"
              onClick={this.menuClick}
            >
              Sponsors
            </a>
            */}
            {/*<Link to="#auction" className="a text-menu-item" onClick={this.menuClick}> 
            Silent Auction
          </Link>*/}
            {/*
            <a
              className="a text-menu-item"
              href="#auction"
              onClick={this.menuClick}
            >
              Silent Auction
            </a>
            */}
            {<AuthMenu className="a text-menu-item mobile-only" />}
            {/* <a className="a text-menu-item mobile-only" href="#" onClick={
                            (e: React.MouseEvent) => {
                                window.alert("Thank you for your interest in the Virtual Habitat House Party! Registration opens soon.");
                                menuClick();
                            }
                        }>Register</a> */}
          </div>
          <div className="top-menu-right">
            {/*<AuthMenu className="a text-menu-item desktop-only" />*/}
            {/* <a className="a text-menu-item desktop-only" href="#"
                            onClick={
                                (e: React.MouseEvent) => {
                                    window.alert("Thank you for your interest in the Virtual Habitat House Party! Registration opens soon.");
                                    menuClick();
                                }
                            } >Register</a> */}
            <div className="donate-button-container">
              <VhhpButtonDonate
                text="Donate"
                styleOptions="standard"
                className="topmenu-donate-button"
                donateUrl="https://habitatnycwc.org/donate-now/"
              ></VhhpButtonDonate>
            </div>
            <button
              className={`a menu-icon ${
                this.props.theme ? this.props.theme : 'light'
              }`}
              onClick={this.menuClick}
            >
              <i
                className={`menu-icon-image ${
                  this.props.theme ? this.props.theme : 'light'
                }`}
              ></i>
            </button>
          </div>
        </div>
      </>
    )
  }
}
