import imgReg1 from './img/Graphic-Assets3.jpg';
import imgReg2 from './img/Graphic-Assets4.jpg';
import imgReg3 from './img/Graphic-Assets2.jpg';
import './Infobox.css';

import React, { Component } from 'react';
import VectorLogo, { Logos } from '../VectorLogo/VectorLogo';

const CycleInTime = 6000;
const CycleOutTime = 1000;

interface InfoboxProps {

}

interface InfoBoxConfigItem {
    title: string;
    textColorClassName: string;
    logo: Logos;
    description: string;
    imgSrc: string;
}
const InfoBoxConfig: InfoBoxConfigItem[] = [
    {
        title: "Building New York's Sustainable Future", textColorClassName: "gray-1", logo: "twin-gray", description: "One where everyone has a safe, healthy, affordable place to live.", imgSrc: imgReg1
    },
    {
        title: "The Virtual Habitat House Party", textColorClassName: "gray-1", logo: "twin-gray", description: "Two days of inspiring speakers, amazing experiences and uplifting stories.", imgSrc: imgReg2
    },
    {
        title: "Be Part of our City's Future", textColorClassName: "white", logo: "twin-white", description: "Fight rising tides and soaring evictions and build an equitable city for everyone.", imgSrc: imgReg3
    }
]

interface InfoboxState {
    InfoCycle: number;
    InfoCycleState: "in" | "out";
}

export default class Infobox extends Component<InfoboxProps, InfoboxState> {
    timerID: any;
    constructor(props: Readonly<InfoboxProps>) {
        super(props);
        this.state = { InfoCycle: 0, InfoCycleState: "in" };
    }
    preloadImages() {
        InfoBoxConfig.forEach(i => {
            let link = document.createElement("link");
            link.rel = "preload";
            link.href = i.imgSrc;
            link.as = "image";
            document.head.appendChild(link);
        });
    }

    infoCycle() {
        let cycle = this.state.InfoCycle;
        let cycleState = this.state.InfoCycleState;
        let newCycleState: "in" | "out" = cycleState === "in" ? "out" : "in";
        let newState: InfoboxState;
        if (newCycleState === "out") {
            newState = {
                InfoCycle: cycle,
                InfoCycleState: newCycleState
            }
        } else {
            if (cycle === InfoBoxConfig.length - 1)
                newState = {
                    InfoCycle: 0,
                    InfoCycleState: newCycleState
                };
            else
                newState = {
                    InfoCycle: ++cycle,
                    InfoCycleState: newCycleState
                };
        }

        clearInterval(this.timerID);
        let timeout = newState.InfoCycleState === "in" ? CycleInTime : CycleOutTime;
        this.timerID = setInterval(
            () => this.infoCycle(),
            timeout);
        this.setState(newState);
    }

    componentDidMount() {
        this.preloadImages();

        this.timerID = setInterval(
            () => this.infoCycle(),
            CycleInTime);
    }
    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    //
    //<img src={configItem.imgSrc} className={`info-box-lower-img-small info-box-lower-img-small-${infoCycle}`} />
    //<img src={configItem.imgSrc} className={`info-box-upper-img info-box-upper-img-${infoCycle}`} />

    render() {
        let cycle = this.state.InfoCycle;
        let configItem = InfoBoxConfig[cycle];
        let animationClass = this.state.InfoCycleState === "in" ? " info-box--fade-in" : " info-box--fade-out";
        return (
            <div className={`info-box info-box-${cycle}`}>
                <div className={`info-box__content`}>
                    <VectorLogo withUrl={true} logo={configItem.logo} height="48px" />
                    <div>
                        <div className={`info-box__title gray-1 ${configItem.textColorClassName} ${animationClass}`}>{configItem.title}</div>
                        <div className={`info-box__short gray-1 ${configItem.textColorClassName} ${animationClass}`}>{configItem.description}</div>
                        <div className={`info-box__date  gray-1 ${configItem.textColorClassName} ${animationClass}`}>Oct 26th & 27th, 2020</div>
                    </div>
                </div>

                <img src={configItem.imgSrc} alt="" className={`info-box__img-lower info-box__img-lower-${cycle} ${animationClass}`} />

                <img src={configItem.imgSrc} alt="" className={`info-box__img-upper info-box__img-upper-${cycle} ${animationClass}`} />
            </div>
        );
    }
}