import './RegisterSection.scss';
import registerImg from './img/register.jpg';
import React, { Component } from 'react';
import VhhpButton from '../../Elements/VhhpButton';

export default class RegisterSection extends Component {
    render()
    {
    console.log("register section added")

        return (
        <div className="register-section-container">
            <div className="silent-auction-content">
                <div className="silent-auction-content_title display white">Get Your Tickets</div>
                <div className="silent-auction-content_body body2 white">Tickets are available for the in-person portion of the Habitat House Party and After Party on Wednesday, October 27th at Guastavino's.</div>
                <VhhpButton url="https://habitatnycwc.org/donate-now/" text="Get Tickets" styleOptions={ {color: "white", outline: true, background: "transparent"} }></VhhpButton>
            </div>
        </div>
        )
    }
    // render() {
    //     return (
    //         <div className="register-container">
    //             <div className="register-tl-box">
    //                 <div className="register-tl-box-content">
    //                     <div className="register-tl-box_title h1 white">Register for Free Today</div>
    //                     <div className="register-tl-box_body body2 white">Join thousands of New Yorkers for our first-ever free gala on October 26th and 27th, and learn how you can build a sustainable future through affordable housing.</div>
    //                     <VhhpButton url="/signup" text="Register" styleOptions={ {color: "white", outline: true, background: "dark-green"} }></VhhpButton>
    //                 </div>
    //             </div>
    //             <div className="register-br-box">
    //                 <div className="register-br-box_img-container">
    //                     <img className="register-br-box_img-container_img" src={registerImg} alt=""/>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }
}