import hhnycHzBlack from './img/wb_logo-background_v2.png';
import hhnycHzWhite from './img/NewYorkCityandWestchesterCounty_Hz_White.png';
import hhnycSglBlack from './img/NewYorkCityandWestchesterCounty_Sgl_Black.png';
import hhnycSglWhite from './img/hhync-sgl-white.svg';
import vhhpVerticalBlue from './img/wb_logo-background_v2.png';
import vhhpVerticalGray from './img/WB_logo_white.svg';
import vhhpVerticalWhite from './img/WB_logo_white.svg';
import virtualPartyLogo from './img/Changed_logo.png'
import './VectorLogo.css';
import React, { Component } from 'react';


type SingleLogos = "hhnyc-hz-black" |
    "hhnyc-hz-white" |
    "hhnyc-sgl-black" |
    "hhnyc-sgl-white" |
    "vhhp-vertical-blue" |
    "vhhp-vertical-gray" |
    "vhhp-vertical-white" |
    "vhhp-horizontal-gray" |
    "virtual-party-logo";

export type Logos = SingleLogos | "twin-white" | "twin-gray";



interface LogoProps {
    logo: Logos;
    height?: string;
    alt?: string;
    className?: string;
    withUrl: boolean;
}

interface LogoState {

}

export default class VectorLogo extends Component<LogoProps, LogoState> {

    getSrcAltText(logo: SingleLogos, altOverride?: string): { src: string, alt: string, url: string } {
        switch (logo) {
            case "hhnyc-hz-black": return { src: hhnycHzBlack, alt: altOverride || "Habitat For Humanity New York City", url: "https://habitatnyc.org/" };
            case "hhnyc-hz-white": return { src: hhnycHzWhite, alt: altOverride || "Habitat For Humanity New York City", url: "https://habitatnyc.org/" };
            case "hhnyc-sgl-black": return { src: hhnycSglBlack, alt: altOverride || "Habitat For Humanity New York City", url: "https://habitatnyc.org/" };
            case "hhnyc-sgl-white": return { src: hhnycSglWhite, alt: altOverride || "Habitat For Humanity New York City", url: "https://habitatnyc.org/" };
            case "vhhp-vertical-blue": return { src: vhhpVerticalBlue, alt: altOverride || "Virtual Habitat House Party", url: "/" };
            case "vhhp-vertical-gray": return { src: vhhpVerticalGray, alt: altOverride || "Virtual Habitat House Party", url: "/" };
            case "vhhp-vertical-white": return { src: vhhpVerticalWhite, alt: altOverride || "Virtual Habitat House Party", url: "/" };
            case "vhhp-horizontal-gray": return { src: vhhpVerticalGray, alt: altOverride || "Virtual Habitat House Party", url: "/" };
            default: return { src: virtualPartyLogo, alt: altOverride || "Virtual Habitat House Party", url: "/"  }
        }
    }

    getLogoElement(logo: SingleLogos, className?: string, altOverride?: string) {
        // maybe this should be a separate class
        let srcAlt = this.getSrcAltText(logo, altOverride);
        let img = <img src={srcAlt.src} alt={srcAlt.alt} height={this.props.height} className={className} />;
        if (this.props.withUrl)
            return <a href={srcAlt.url}>{img}</a>
        return img;
    }

    isSingleLogo(logo: Logos): logo is SingleLogos {
        if (logo === "twin-gray" || logo === "twin-white")
            return false;
        return true;
    }

    render() {
        if (this.isSingleLogo(this.props.logo)) {
            return this.getLogoElement(this.props.logo, this.props.className, this.props.alt);
        } else {
            let height = this.props.height || "48px";
            let logoLeft: SingleLogos, logoRight: SingleLogos, dividerClassName: string = "divider ";
            if (this.props.logo === "twin-gray") {
                logoLeft = "hhnyc-hz-black";
                logoRight = "vhhp-vertical-gray";
                dividerClassName += "divider--gray";
            } else {
                logoLeft = "hhnyc-hz-white";
                logoRight = "vhhp-vertical-white";
                dividerClassName += "divider--white";
            }
            return (
                <div style={{ height: height }}>
                    {this.getLogoElement(logoLeft, "twin-logo-left" + this.props.className)}
                    <div className={dividerClassName}></div>
                    {this.getLogoElement(logoRight, "twin-logo-right" + this.props.className)}
                </div>
            );
        }
    }
}