import './WizardLayout.css';
import React, { Component } from 'react';
import WizardInfoPane from './WizardInfoPane';
import WizardContentPane from './WizardContentPane';
import VhhpButton from '../Elements/VhhpButton';
import * as rFlow from './RegistrationFlow';

interface WizardLayoutState {
    loading: boolean;
    registrationFlow: rFlow.RegistrationFlow | undefined;
}

interface WizardLayoutProps {
    WizardContentPaneChildren?: React.ReactNode;
    onNextClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<boolean>;
}

export default class WizardLayout extends Component<WizardLayoutProps, WizardLayoutState> {
    constructor(props: WizardLayoutProps) {
        super(props);

        this.state = { loading: true, registrationFlow: undefined };

        this.handleNextButtonClick = this.handleNextButtonClick.bind(this);

        this.populateState();
    }

    async populateState() {
        const rf = await rFlow.getRegistrationFlow();
        this.setState({ loading: false, registrationFlow: rf });
    }
    getNextButtonUrl() {
        if (window.location.pathname === "/terms") {
            // special case for viewing terms outside of registration flow
            return "/";
        }
        if (this.state.loading || !this.state.registrationFlow) {
            //shouldn't matter what gets returned
            return window.location.pathname;
        } else {
            const cs = this.state.registrationFlow.currentStep;
            const rs = this.state.registrationFlow.flow;
            if (cs === "termsConditions")
                return rs[0].url;
            else {
                if (cs < rs.length - 1) {
                    return rs[cs + 1].url;
                }
            }
            return "/profile";
        }
    }
    getBackButtonUrl() {
        if (window.location.pathname === "/terms") {
            // special case for viewing terms outside of registration flow
            // button should be disabled
            return "/";
        }
        if (this.state.loading || !this.state.registrationFlow) {
            //shouldn't matter what gets returned
            return window.location.pathname;
        } else {
            const cs = this.state.registrationFlow.currentStep;
            const rs = this.state.registrationFlow.flow;
            if (cs === "termsConditions") {
                //back button should be disabled in this case
                return "/";
            } else if (cs === 0) {
                return "/register/termsconditions";
            } else {
                return rs[cs-1].url;
            }
        }
    }
    async handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if(window.location.pathname === "/terms") {
            // special case for viewing terms outside of registration flow
            window.location.href = "/";
        }
        let proceed = await this.props.onNextClick(event);
        if (proceed)
            window.location.href = this.getNextButtonUrl();
    }
    getBackButton() {

    }
    render() {
        const isLoading = this.state.loading;
        const termsView = window.location.pathname === "/terms"; // special case for viewing terms outside of registration flow
        const back: JSX.Element = isLoading || termsView
            ? <></>
            : this.state.registrationFlow?.currentStep !== "termsConditions"
                ? <a href={this.getBackButtonUrl()} className="a gray-1 wizard-nav__back-button">Back</a>
                : <></>;
        return (
            <div className="wizard-container">
                <div className="wizard-body">
                    <div className="wizard-sidebar">
                        <WizardInfoPane registrationFlow={isLoading ? undefined : this.state.registrationFlow} />
                    </div>

                    <div className="wizard-content">
                        <div className="wizard-content-pane">
                            <WizardContentPane>
                                {this.props.WizardContentPaneChildren}
                            </WizardContentPane>
                        </div>
                        <div className="wizard-nav">
                            {back}
                            {/* {this.props.currentStep === 1? <a href={this.getNextButtonUrl()} className="a blue-3 wizard-nav__skip-button">Skip</a>:null} */}
                            <VhhpButton disabled={isLoading && !termsView} onclick={this.handleNextButtonClick} className="wizard-nav__next-button" text={termsView? "Home" : "Next"} styleOptions="standard" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}